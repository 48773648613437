import { createContext, useState, useEffect, useCallback } from "react";
import { Auth } from "aws-amplify";
import { getProfileData } from "../services/user-service";

export const UserContext = createContext({
  user: null,
  setUser: () => {},
});

export const UserContextProvider = ({ children }) => {
  const [user, setUser] = useState({});
  const [profile, setProfile] = useState({});
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isLoadingUser, setIsLoadingUser] = useState(true);

  const logInUser = useCallback(async (awsUser) => {
    setUser(awsUser);
    await queryProfile(awsUser?.username);
    setIsLoggedIn(true);
    setIsLoadingUser(false);
  }, []);

  useEffect(() => {
    async function getAuthStatus() {
      try {
        const awsUser = await Auth.currentAuthenticatedUser();
        logInUser(awsUser);
      } catch (e) {
        setIsLoggedIn(false);
        setIsLoadingUser(false);
      }
    }
    getAuthStatus();
  }, [logInUser]);

  async function logOutUser() {
    await Auth.signOut({ global: true });
    setUser({});
    setProfile({});
    setIsLoggedIn(false);
    setIsLoadingUser(false);
  }

  async function queryProfile(username) {
    try {
      const profileData = await getProfileData(username);
      setProfile(profileData);
    } catch (e) {
      console.log("error getting profile data", e);
    }
  }

  return (
    <UserContext.Provider
      value={{
        user,
        logInUser,
        logOutUser,
        isLoggedIn,
        isLoadingUser,
        profile,
        setProfile,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
