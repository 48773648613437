/* External Libraries */
import { useEffect, useState, useContext } from "react";
import { Drawer, Button, Card, Divider, Tag, Tooltip } from "antd";
import { ControlOutlined, CheckOutlined } from "@ant-design/icons";

/* Internal */
import { getProfilesData } from "../../services/user-service";
import "./SearchProviders.css";
import FilterList from "./FilterList";
import UserImage from "../Profile/UserImage";
// import { providers } from "./ribbon.providers.data";

import { getFiltersFromListAndFacets } from "./search.helpers";
import MessagingCardAction from "./MessagingCardAction";
import Preview from "../Profile/Preview/index.js";
import { culturalResponsiveness } from "../Profile/profile.data";
import { UserContext } from "../../contexts/UserContext";

const query = new URLSearchParams(window.location.search);
const isShowingTestAccounts = query.get("showTestAccounts") !== null;

const mobileBreakpointWidth = 768;

const FACETS = [
  "responsiveAreas",
  "specialties",
  "languagePreferences",
  "pronouns",
  "averageFee",
  "agePreferences",
  "faithOrientations",
  "treatmentOrientations",
  "modalityPreferences",
  "paymentMethods",
  "acceptedInsurance",
];

const FACET_NAME_MAP = {
  responsiveAreas: "Cultural Responsiveness",
  specialties: "Provider Type",
  pronouns: "Pronouns",
  agePreferences: "Age Specialties",
  languagePreferences: "Fluent Languages",
  ethnicitySpecialty: "Ethnicity Specialties",
  faithOrientations: "Faith Orientations",
  treatmentOrientations: "Treatment Orientations",
  modalityPreferences: "Modalities",
  paymentMethods: "Payment Methods",
  acceptedInsurance: "Insurance",
  averageFee: "Average Fee",
};

const facetInfo = {
  responsiveAreas: { display: "checkbox" },
  specialties: { display: "search" },
  pronouns: { display: "search" },
  agePreferences: { display: "search" },
  languagePreferences: { display: "search" },
  ethnicitySpecialty: { display: "search" },
  faithOrientations: { display: "search" },
  treatmentOrientations: { display: "search" },
  modalityPreferences: { display: "search" },
  paymentMethods: { display: "search" },
  acceptedInsurance: { display: "search" },
  averageFee: { display: "slider", min: 0, max: 1000 },
};

const PROVIDER_TYPE_IMAGE_MAP = {
  "Ob/Gyn": {
    image:
      "https://images.unsplash.com/flagged/photo-1551457457-699ff7c5d5f5?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=668&q=80",
  },
  Optometrist: {
    image:
      "https://images.unsplash.com/photo-1534844978-b859e5a09ad6?ixid=MXwxMjA3fDB8MHxzZWFyY2h8N3x8Z2xhc3Nlc3xlbnwwfHwwfA%3D%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=60",
  },
  "Mental Health Counselor": {
    image:
      "https://images.unsplash.com/photo-1471107340929-a87cd0f5b5f3?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1566&q=80",
  },
};

function inRange(value, range) {
  if (!Array.isArray(range)) {
    return false;
  }

  return value >= range[0] && value <= range[1];
}

//unique filter operations that aren't simple matching of values/array of values i.e. age range
const specialFilterOperator = {
  // agePreferences: inRange,
  averageFee: inRange,
};

/**
 *
 * The following is an api request to Ribbon Health via AWS Lambda Function connected to AWS Amplify's Backend
 *
 * import { API } from 'aws-amplify';
 * const items = API.get('ribbonHealthApi', '/providers').then((items) => console.log(items));
 *
 * Edit the file "amplify/backend/function/ribbonHealthLambda/src/app.js" in the Jubily repo
 *  to add more ribbon health endpoints: https://ribbon.readme.io/docs
 *
 */

//provider display field constants used when mapped to in filter facets
const PROVIDER_FILTERED_KEPT = "kept";
const PROVIDER_MISSING_INFO = "missingInformation";
const PROVIDER_FILTERED_OUT = "filteredOut";

function SearchProviders() {
  const { profile } = useContext(UserContext);
  const [messagingPrompt, setMessagingPrompt] = useState({
    isVisible: false,
    selected: [],
  });

  const [providers, setProviders] = useState([]); //remains the same

  const [providerList, setProviderList] = useState([]); //filtered list
  const [inputValues, setInputValues] = useState({});
  const [filterDrawer, setFilterDrawer] = useState({ isVisible: false });
  const [filterList, setFilterList] = useState([]);
  const [previewDrawer, setPreviewDrawer] = useState({
    isVisible: false,
    data: {},
  });

  // fetch initial providers
  useEffect(() => {
    async function initProvidersData() {
      const profilesData = await getProfilesData({ isShowingTestAccounts });
      setProviderList(profilesData);
      setProviders(profilesData);
      let filters = getFiltersFromListAndFacets(profilesData, FACETS);
      setFilterList(filters);
    }
    initProvidersData();
  }, []);

  function categorizeFilteredResults(provider, providerFacet, isNotFiltered) {
    if (!provider.display || provider.display === PROVIDER_FILTERED_KEPT) {
      if (!providerFacet) {
        return { ...provider, display: PROVIDER_MISSING_INFO };
      } else if (isNotFiltered) {
        return { ...provider, display: PROVIDER_FILTERED_KEPT };
      } else {
        return { ...provider, display: PROVIDER_FILTERED_OUT };
      }
    } else if (provider.display === PROVIDER_FILTERED_OUT) {
      return { ...provider, display: PROVIDER_FILTERED_OUT };
    } else if (provider.display === PROVIDER_MISSING_INFO) {
      if (!providerFacet) {
        return { ...provider, display: PROVIDER_MISSING_INFO };
      } else if (isNotFiltered) {
        return { ...provider, display: PROVIDER_MISSING_INFO };
      } else {
        return { ...provider, display: PROVIDER_FILTERED_OUT };
      }
    }
  }

  function filterMapResults(selectedValues) {
    let newProviderList = JSON.parse(JSON.stringify(providers));
    for (const facet in selectedValues) {
      const selections = selectedValues[facet];

      //empty arrays for facets can accidentally filter out providers with null/undefined values for that facet
      if (selections.length === 0) continue;

      //some facet filters require different filter operations
      if (facet in specialFilterOperator) {
        newProviderList = newProviderList.map((provider) => {
          let providerFacet = provider[facet] || null;
          let isNotFiltered = providerFacet
            ? specialFilterOperator[facet](providerFacet, selections)
            : false;
          return categorizeFilteredResults(
            provider,
            providerFacet,
            isNotFiltered
          );
        });
      } else {
        newProviderList = newProviderList.map((provider) => {
          let providerFacet = provider[facet] || null;
          //providers without the facet value will be filter out
          if (!providerFacet) {
            return categorizeFilteredResults(provider, providerFacet, false);
          }
          let isNotFiltered = Array.isArray(providerFacet)
            ? selections.every((selection) => providerFacet.includes(selection))
            : selections.every((selection) => providerFacet === selection);
          return categorizeFilteredResults(
            provider,
            providerFacet,
            isNotFiltered
          );
        });
      }
    }
    setProviderList(newProviderList);
  }

  function handleChange({ name, value }) {
    const newInputValues = {
      ...inputValues,
      [name]: value,
    };
    setInputValues(newInputValues);
    filterMapResults(newInputValues);
  }

  function translateLabel(label) {
    const competency = culturalResponsiveness.find(
      (item) => item.name === label
    );
    return competency?.label || label;
  }

  function getGenericProviderImage(providerType) {
    const providerTypeEntry = PROVIDER_TYPE_IMAGE_MAP[providerType];
    return (
      providerTypeEntry?.image ||
      "https://images.unsplash.com/photo-1546659934-038aab8f3f3b?ixid=MXwxMjA3fDB8MHxzZWFyY2h8ODV8fG1lZGljYWx8ZW58MHx8MHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=60"
    );
  }

  function handleClearFilters() {
    setInputValues({});
    setProviderList(providers);
  }

  function handleClickProviderCard(index) {
    setPreviewDrawer({ isVisible: true, data: providerList[index] });
  }

  function getCardActions(provider, providerListIndex) {
    const seeMore = (
      <div onClick={() => handleClickProviderCard(providerListIndex)}>
        See More
      </div>
    );
    const messageProvider = (
      <MessagingCardAction
        provider={provider}
        messagingPrompt={messagingPrompt}
        setMessagingPrompt={setMessagingPrompt}
        currentUserProfile={profile}
      />
    );

    /**
     * The ability to message a provider only surfaces if that provider has
     * completed the cultural competence questionnaire. If the current user
     * has not filled in the questionnaire, they see that they would have had
     * the ability to message if they did complete it.
     */
    if (
      provider.isScreenerCompleted &&
      profile &&
      profile.userID !== provider.userID
    ) {
      return [seeMore, messageProvider];
    } else {
      return [seeMore];
    }
  }

  return (
    <>
      <div className="search-providers">
        <ProviderPreview
          previewDrawer={previewDrawer}
          setPreviewDrawer={setPreviewDrawer}
        />

        {/* Start Mobile Filter Components */}
        <Button
          className="section-header mobile-filters-button"
          type="primary"
          size="large"
          onClick={() => {
            setFilterDrawer({ isVisible: true });
          }}
        >
          <ControlOutlined /> Filter
        </Button>

        <Drawer
          className="search-providers-filters-drawer"
          title="Filters"
          placement="left"
          closable={true}
          onClose={() => {
            setFilterDrawer({ isVisible: false });
          }}
          visible={filterDrawer.isVisible}
        >
          <div className="filters-section">
            <FilterList
              filterList={filterList}
              facetInfo={facetInfo}
              FACET_NAME_MAP={FACET_NAME_MAP}
              handleChange={handleChange}
              inputValues={inputValues}
              translateLabel={translateLabel}
            />
            <div className="clear-filters" onClick={handleClearFilters}>
              Clear
            </div>
          </div>
        </Drawer>

        {/* End Mobile Filter Components */}

        <div className="filters-section">
          <h1 className="section-header">
            {" "}
            <ControlOutlined /> Filter
          </h1>
          <FilterList
            filterList={filterList}
            facetInfo={facetInfo}
            FACET_NAME_MAP={FACET_NAME_MAP}
            handleChange={handleChange}
            inputValues={inputValues}
            translateLabel={translateLabel}
          />

          <div className="clear-filters" onClick={handleClearFilters}>
            Clear
          </div>
        </div>

        <div className="providers-section">
          <h1 className="section-header">Providers</h1>
          <ProvidersListDisplay
            providerList={providerList.filter(
              (provider) =>
                !provider.display || provider.display === PROVIDER_FILTERED_KEPT
            )}
            handleClickProviderCard={handleClickProviderCard}
            getCardActions={getCardActions}
            getGenericProviderImage={getGenericProviderImage}
            handleClearFilters={handleClearFilters}
            displayNoProviderMessage={true}
            profile={profile}
          />
          {providerList.filter(
            (provider) => provider.display === PROVIDER_MISSING_INFO
          ).length !== 0 && (
            <Divider>
              {" "}
              Below are providers with missing information for the filters
              you've selected.{" "}
            </Divider>
          )}
          <ProvidersListDisplay
            providerList={providerList.filter(
              (provider) => provider.display === PROVIDER_MISSING_INFO
            )}
            handleClickProviderCard={handleClickProviderCard}
            getGenericProviderImage={getGenericProviderImage}
            getCardActions={getCardActions}
            handleClearFilters={handleClearFilters}
            displayNoProviderMessage={false}
            profile={profile}
          />
        </div>
      </div>
    </>
  );
}

export default SearchProviders;

function ProvidersListDisplay({
  getCardActions,
  providerList,
  handleClickProviderCard,
  getGenericProviderImage,
  handleClearFilters,
  displayNoProviderMessage,
  profile,
}) {
  return (
    <div className="providers-list">
      {providerList.length !== 0
        ? providerList.map((provider, providerListIndex) => (
            <Card
              key={provider.userID}
              className="provider"
              hoverable
              cover={
                <UserImage
                  className="provider-image"
                  fileName={provider.image}
                  identityId={provider.user.identityId}
                  defaultImage={getGenericProviderImage(provider.specialties)}
                  onClick={() => handleClickProviderCard(providerListIndex)}
                />
              }
              actions={getCardActions(provider, providerListIndex)}
            >
              <JubilyVerifiedBadge provider={provider} />
              <div
                className="provider-details"
                onClick={() => handleClickProviderCard(providerListIndex)}
              >
                <p className="provider-name">
                  {/* Title only shown if post nominals are not given because this is seen as redundant */}
                  {!provider.postNominals && provider.title && (
                    <span>{provider.title}</span>
                  )}
                  {provider.firstName && <span>{provider.firstName}</span>}
                  {provider.middleName && <span>{provider.middleName}</span>}
                  {provider.lastName && (
                    <span>
                      {provider.lastName}
                      {provider.postNominals && <span>,</span>}
                    </span>
                  )}
                  {provider.postNominals && (
                    <span>{provider.postNominals}</span>
                  )}
                </p>
                <div className="provider-type">
                  {provider.specialties || null}
                </div>
                <div className="provider-location">
                  {provider.location || null}
                </div>
                {provider.responsiveAreas && (
                  <div className="provider-responsiveness">
                    <div className="provider-responsiveness-header">
                      Cultural Responsiveness
                    </div>
                    <div className="provider-responsiveness-body">
                      {provider.responsiveAreas.map((area, index) => {
                        const responsivenessItem = culturalResponsiveness.find(
                          (item) => item.label === area
                        );
                        return (
                          <Tag key={index} color={responsivenessItem.tagColor}>
                            {area}
                          </Tag>
                        );
                      })}
                    </div>
                  </div>
                )}
              </div>
            </Card>
          ))
        : displayNoProviderMessage && (
            <div>
              No providers fit the chosen search criteria. Please{" "}
              <span className="clear-filters" onClick={handleClearFilters}>
                deselect
              </span>{" "}
              the chosen options and try again.
            </div>
          )}
    </div>
  );
}

const ProviderPreview = ({ previewDrawer, setPreviewDrawer }) => {
  return (
    <Drawer
      title="About This Provider"
      className="profile-preview-drawer"
      placement="right"
      closable="true"
      visible={previewDrawer.isVisible}
      onClose={() => {
        setPreviewDrawer({ ...previewDrawer, isVisible: false });
      }}
      width={global.window.innerWidth > mobileBreakpointWidth ? "50%" : "100%"}
    >
      <Preview {...previewDrawer.data} />
    </Drawer>
  );
};

const JubilyVerifiedBadge = ({ provider }) => {
  return (
    <Tooltip
      placement="bottom"
      title="This provider has completed the cultural responsiveness questionnaire."
    >
      {provider.isScreenerCompleted && (
        <div className="provider-badge">
          <Tag color="#55A9A2">Jubily Verified {<CheckOutlined />}</Tag>
        </div>
      )}
    </Tooltip>
  );
};
