import { useEffect, useState, useContext } from "react";
import { Storage } from "aws-amplify";
import { UserContext } from "../../../contexts/UserContext";
import { Skeleton } from "antd";

export default function UserImage({
  fileName,
  level = "protected",
  identityId,
  isBackgroundImageUrl = true,
  alt = "Image of User",
  defaultImage,
  className,
  ...props
}) {
  const { profile } = useContext(UserContext);
  const [isLoaded, setIsLoaded] = useState(false);
  const [presignedUrl, setPreSignedUrl] = useState("");

  useEffect(() => {
    function getUrl() {
      Storage.get(fileName, {
        level,
        identityId:
          profile?.user?.identityId === identityId ? null : identityId,
        expires: 604800,
      }).then((url) => {
        setPreSignedUrl((urlState) => {
          if (!fileName) {
            return defaultImage;
          } else if (fileName?.startsWith("http")) {
            return fileName;
          } else if (urlState === "") {
            return url;
          }
          return urlState;
        });
        setIsLoaded(true);
      });
    }
    getUrl();
    return () => {
      setPreSignedUrl("");
    };
  }, [fileName, level, identityId, defaultImage, profile?.user?.identityId]);

  return (
    <>
      {isLoaded ? (
        <>
          {isBackgroundImageUrl ? (
            <div
              className={`user-image ${className}`}
              style={{
                backgroundImage: `url(${presignedUrl} )`,
              }}
              {...props}
            ></div>
          ) : (
            <img src={presignedUrl} alt={alt} />
          )}
        </>
      ) : (
        <Skeleton.Button
          className={`user-image ${className}`}
          active
          {...props}
        />
      )}
    </>
  );
}
