/* External Libraries */
import { useContext } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { Result, Button, Spin } from "antd";
import { Link } from "react-router-dom";
import Amplify from "aws-amplify";
import awsconfig from "./aws-exports";

/* Internal */
import { UserContext, UserContextProvider } from "./contexts/UserContext";
import ScrollToTop from "./components/ScrollToTop";
import Profile from "./components/Profile/Profile";
import Header from "./components/Header/Header";
import Home from "./components/Home/Home";
import Login from "./components/Login/Login";
import About from "./components/About/About";
import Team from "./components/Team/Team";
import TermsOfUse from "./components/Login/TermsOfUse";
import PrivacyPolicy from "./components/Login/PrivacyPolicy";
import SearchProviders from "./components/SearchProviders/SearchProviders";
import Messaging from "./components/Messaging/Messaging";
import Footer from "./components/Footer";
import "./App.css";

Amplify.configure(awsconfig);

function App() {
  return (
    <Router>
      <ScrollToTop />
      <UserContextProvider>
        <div className="App">
          <Header />
          <Switch>
            <LocationRoute
              exact
              path="/"
              component={(props) => (
                <Home {...props} key={window.location.pathname} />
              )}
            />
            <LocationRoute
              exact
              path="/login"
              component={(props) => (
                <Login
                  {...props}
                  isLogin={true}
                  key={window.location.pathname}
                />
              )}
            />
            <LocationRoute
              exact
              path="/signup"
              component={(props) => (
                <Login
                  {...props}
                  isLogin={false}
                  key={window.location.pathname}
                />
              )}
            />
            <PrivateRoute exact path="/profile/:paramId(0|1|2|3)?">
              <Profile />
            </PrivateRoute>
            <PrivateRoute exact path="/search">
              <SearchProviders />
            </PrivateRoute>
            <PrivateRoute exact path="/messages/:groupId?">
              <Messaging />
            </PrivateRoute>
            <LocationRoute exact path="/about">
              <About />
            </LocationRoute>
            <LocationRoute exact path="/team">
              <Team />
            </LocationRoute>
            <LocationRoute exact path="/terms-of-use">
              <TermsOfUse />
            </LocationRoute>
            <LocationRoute exact path="/privacy-policy">
              <PrivacyPolicy />
            </LocationRoute>
            <LocationRoute>
              <Result
                status="404"
                title="404"
                subTitle="Sorry, the page you visited does not exist."
                extra={
                  <Button type="primary">
                    <Link to="/">Back Home</Link>
                  </Button>
                }
              />
            </LocationRoute>
          </Switch>
        </div>
      </UserContextProvider>
    </Router>
  );
}

const hiddenFooterRoutes = ["/login", "/signup", "/profile", "/messages"];
const LocationRoute = ({ path, ...routeProps }) => (
  <>
    <Route path={path} {...routeProps} />
    {!hiddenFooterRoutes.find((route) => path?.startsWith(route)) && <Footer />}
  </>
);

const PrivateRoute = ({ children, ...rest }) => {
  const { isLoggedIn, isLoadingUser } = useContext(UserContext);

  if (isLoadingUser) {
    return <Result icon={<Spin size="large" delay={500} />} />;
  }

  return isLoggedIn ? (
    <LocationRoute {...rest}>{children}</LocationRoute>
  ) : (
    <Redirect
      to={{
        pathname: "/login",
        intendedPath: window.location.pathname,
      }}
    />
  );
};

export default App;
