import "./Header.css";
import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../contexts/UserContext";
import { Link } from "react-router-dom";
import { Menu, Dropdown, Space, message as antdMessage } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import UserAvatar from "../Profile/UserAvatar/UserAvatar";
import MessagesIcon from "./MessagesIcon";
import { useHistory } from "react-router-dom";
import UserImage from "../Profile/UserImage";
import { Button } from "antd";
import { BRAND_COLORS_ARRAY } from "../../constants";

function Header() {
  const { user, profile, isLoggedIn, logOutUser } = useContext(UserContext);
  const history = useHistory();
  const dropdownMenu = (
    <Menu className="header-dropdown-menu">
      <Menu.Item key="profile">
        {" "}
        <Link to="/profile"> Edit Profile </Link>{" "}
      </Menu.Item>
      <Menu.Item key="search">
        {" "}
        <Link to="/search"> Directory </Link>{" "}
      </Menu.Item>
      <Menu.Item key="sign-out">
        {" "}
        <div onClick={handleSignOut}>
          <Link to="/" className="sign-out-link">
            Sign out
          </Link>
        </div>{" "}
      </Menu.Item>
    </Menu>
  );

  async function handleSignOut() {
    try {
      await logOutUser();
      history.push(`/`);
    } catch ({ code, name, message }) {
      antdMessage.error(message, 0);
    }
  }

  return (
    <>
      <header className="Header">
        <div>
          <Link to="/">
            <AnimatedLogo />
          </Link>
        </div>
        <div className="Header-Right">
          {isLoggedIn ? (
            <Space align="start" size="middle">
              {user && profile.id && <MessagesIcon profile={profile} />}
              {profile.id && (
                <Link to="/search" className="menu-icon">
                  {" "}
                  <SearchOutlined />
                </Link>
              )}
              <>
                {profile.id && (
                  <Dropdown overlay={dropdownMenu} placement="bottom">
                    {profile?.image ? (
                      <div className="user-avatar">
                        <UserAvatar
                          imgUrl={
                            <UserImage
                              fileName={profile.image}
                              identityId={profile.identityId}
                            />
                          }
                        />
                      </div>
                    ) : (
                      <div className="user-avatar">
                        <UserAvatar />
                      </div>
                    )}
                  </Dropdown>
                )}
              </>
            </Space>
          ) : (
            <Space align="center" size="large">
              <div>
                <Link to="/about">About</Link>
              </div>
              <div>
                <Link to="/team">Team</Link>
              </div>
              <div>
                <Link to="/login">Log In</Link>
              </div>
              <Button type="primary" size="medium">
                <Link to="/signup">Join</Link>
              </Button>
            </Space>
          )}
        </div>
      </header>
    </>
  );
}

function AnimatedLogo() {
  return (
    <span className="animated-logo">
      Jubily <BlinkingLogoDot />{" "}
    </span>
  );
}

function BlinkingLogoDot() {
  const [index, setIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setIndex((indexState) => {
        const next = indexState + 1;
        if (next === BRAND_COLORS_ARRAY.length) {
          clearInterval(interval);
          return 0;
        }
        return next;
      });
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div
      className="blinking-logo-dot"
      style={{ backgroundColor: BRAND_COLORS_ARRAY[index] }}
    ></div>
  );
}

export default Header;
