import "./Login.css";
import LoginMessages from "./LoginMessages.js";
import React, { useState, useContext } from "react";
import { Link, useHistory } from "react-router-dom";
import FormElements from "../FormElements/FormElements";
import {
  Row,
  Col,
  Button,
  Checkbox,
  message as antdMessage,
  notification,
} from "antd";
import { UserContext } from "../../contexts/UserContext";
import { API, Auth, graphqlOperation } from "aws-amplify";
import { listUserTypes, listUsers } from "../../graphql/queries";
import {
  createUser,
  updateUser,
  createProviderProfile,
} from "../../graphql/mutations";
import { USER_TYPES } from "../../constants";

const VIEW_OPTIONS = {
  LOGIN: "login",
  SIGNUP: "signup",
  SIGNUP_CONFIRMATION: "signup-confirmation",
  PASSWORD_CONFIRMATION: "forgot-password-confirmation",
};

const loginItems = [
  {
    id: 0,
    element: "input",
    type: "text",
    name: "email",
    placeholder: "Email Address",
  },
  {
    id: 1,
    element: "input",
    type: "password",
    name: "password",
    placeholder: "Password",
  },
];

const signupItems = [
  ...loginItems,
  {
    id: loginItems.length,
    element: "input",
    type: "password",
    name: "confirmPassword",
    placeholder: "Confirm Password",
  },
];

const signUpConfirmationItems = [
  {
    id: 0,
    element: "input",
    type: "text",
    name: "code",
    placeholder: "Verification Code",
  },
];

const passwordConfirmationItems = [
  {
    id: 0,
    element: "input",
    type: "text",
    name: "code",
    placeholder: "Verification Code",
  },
  {
    id: 1,
    element: "input",
    type: "password",
    name: "password",
    placeholder: "New Password",
  },
];

function Login({ isLogin = true }) {
  const history = useHistory();
  const [view, setView] = useState(
    isLogin ? VIEW_OPTIONS.LOGIN : VIEW_OPTIONS.SIGNUP
  );
  const [isLoading, setIsLoading] = useState(false);
  const { logInUser } = useContext(UserContext);
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    termsOfUseCheckbox: false,
    privacyPolicyCheckbox: false,
  });
  const [passwordResetReason, setPasswordResetReason] = useState("");
  const [passwordErrors, setPasswordErrors] = useState([
    {
      id: 0,
      text: "Your password must be at least 8 characters",
      isVisible: false,
      hasError: (p) => p.length < 8,
      isApiResponsePlaceholder: false,
    },
    {
      id: 1,
      text: "Your password must contain at least one uppercase letter.",
      isVisible: false,
      hasError: (p) => p.search(/[A-Z]/) < 0,
      isApiResponsePlaceholder: false,
    },
    {
      id: 2,
      text: "Your password must contain at least one lowercase letter.",
      isVisible: false,
      hasError: (p) => p.search(/[a-z]/) < 0,
      isApiResponsePlaceholder: false,
    },
    {
      id: 3,
      text: "Your password must contain at least one digit.",
      isVisible: false,
      hasError: (p) => p.search(/[0-9]/) < 0,
      isApiResponsePlaceholder: false,
    },
    {
      id: 4,
      text: "Your confirmation password must match your password.",
      isVisible: false,
      hasError: (password, confirmation) =>
        view !== VIEW_OPTIONS.LOGIN && password !== confirmation,
      isApiResponsePlaceholder: false,
    },
    {
      id: 5,
      text: "API Error Placeholder Text",
      isVisible: false,
      hasError: () => false,
      isApiResponsePlaceholder: true,
    },
  ]);

  function handleInputChange(event) {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  }

  function onCheckboxChange(event) {
    setFormData({
      ...formData,
      [event.target.name]: event.target.checked,
    });
  }

  function validatePassword(password, confirmation) {
    const errors = [...passwordErrors];
    let isValidPassword = true;
    errors.forEach((item, index) => {
      let hasError = item.hasError(password, confirmation);
      if (hasError) {
        isValidPassword = false;
      }
      errors[index] = { ...item, isVisible: hasError };
    });
    setPasswordErrors(errors);
    return isValidPassword;
  }

  function showAPIResponseError({ message }) {
    const errors = [...passwordErrors];
    errors.forEach((item, index) => {
      if (item.isApiResponsePlaceholder) {
        item.text = message;
        errors[index] = { ...item, isVisible: true };
      }
    });
    setPasswordErrors(errors);
  }

  async function onLoginFormSubmit(event) {
    event?.preventDefault();
    let { email, password } = formData;
    if (validatePassword(password)) {
      setIsLoading(true);
      try {
        const user = await Auth.signIn(email, password);
        await logInUser(user);
        setIsLoading(false);
        history.push(history.location.intendedPath || `/search`);
      } catch (error) {
        setIsLoading(false);
        if (error?.code === "UserNotFoundException") {
          antdMessage.error(
            "User credentials were not found. Please create a Jubily account to continue.",
            10
          );
          setView(VIEW_OPTIONS.SIGNUP);
        } else if (error?.code === "UserNotConfirmedException") {
          resendVerficationCode();
          setView(VIEW_OPTIONS.SIGNUP_CONFIRMATION);
        } else {
          showAPIResponseError(error);
        }
      }
    }
  }

  async function onSignupFormSubmit(event) {
    event.preventDefault();
    let { email, password, confirmPassword } = formData;
    if (validatePassword(password, confirmPassword)) {
      setIsLoading(true);
      try {
        const signUpResult = await Auth.signUp({
          username: email,
          password,
          attributes: { email },
        });
        if (isAccountTransfer(email)) {
          //TODO: remove this 'if' block when all of these account transfers have occured
          const queryResult = await API.graphql({
            query: listUsers,
            variables: { filter: { email: { eq: email } } },
          });
          const id = queryResult.data.listUsers.items[0].id;
          const updatedUser = {
            id,
            awsCognitoUsername: signUpResult.userSub,
            isAccountConfirmed: true,
          };
          await API.graphql(
            graphqlOperation(updateUser, { input: updatedUser })
          );
        } else {
          const userTypeQueryResult = await API.graphql({
            query: listUserTypes,
            variables: { filter: { name: { eq: USER_TYPES.PROVIDER } } },
          });
          const providerTypeId =
            userTypeQueryResult.data.listUserTypes.items[0].id;
          const newUser = {
            awsCognitoUsername: signUpResult.userSub,
            userTypeID: providerTypeId,
            email,
            isAccountConfirmed: signUpResult.userConfirmed,
          };
          const createUserQueryResult = await API.graphql(
            graphqlOperation(createUser, { input: newUser })
          );
          const createdProviderProfileResponse = await API.graphql(
            graphqlOperation(createProviderProfile, {
              input: {
                userID: createUserQueryResult.data.createUser.id,
                email,
              },
            })
          );
          const providerProfileID =
            createdProviderProfileResponse.data.createProviderProfile.id;
          await API.graphql(
            graphqlOperation(updateUser, {
              input: {
                id: createUserQueryResult.data.createUser.id,
                providerProfileID,
              },
            })
          );
        }
        setView(VIEW_OPTIONS.SIGNUP_CONFIRMATION);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        if (error?.code === "UsernameExistsException") {
          resendVerficationCode();
          setView(VIEW_OPTIONS.SIGNUP_CONFIRMATION);
        } else {
          showAPIResponseError(error);
        }
      }
    }
  }

  async function onSignUpVerificationCodeSubmit(event) {
    event.preventDefault();
    let { email, password, code } = formData;
    try {
      setIsLoading(true);
      await Auth.confirmSignUp(email, code);
      const queryResult = await API.graphql({
        query: listUsers,
        variables: { filter: { email: { eq: email } } },
      });
      const id = queryResult.data.listUsers.items[0].id;
      const updatedUser = {
        id,
        isAccountConfirmed: true,
      };
      await API.graphql(graphqlOperation(updateUser, { input: updatedUser }));
      const user = await Auth.signIn(email, password);
      await logInUser(user);
      notification.success({
        message: "Account Creation Successful!",
        description:
          "Welcome to Jubily! Next, complete your profile to join the providers on this platform.",
        duration: null,
      });
      setIsLoading(false);
      history.push(`/profile`);
    } catch (error) {
      setIsLoading(false);
      if (error?.code === "NotAuthorizedException") {
        setPasswordResetReason(
          "Password does not match existing account. Please verify the new password."
        );
        sendPasswordResetEmail();
      } else {
        showAPIResponseError(error);
      }
    }
  }

  async function sendPasswordResetEmail() {
    if (formData.email) {
      antdMessage.loading("Sending email to the email address provided..");
      try {
        setIsLoading(true);
        await Auth.forgotPassword(formData.email);
        antdMessage.success(
          <>
            <span>Password reset confirmation code successfully sent!</span>
            <p>
              Please follow the steps sent to {formData.email} for further
              instructions.
            </p>
          </>,
          20
        );
        setView(VIEW_OPTIONS.PASSWORD_CONFIRMATION);
        setIsLoading(false);
      } catch (e) {
        setIsLoading(false);
        antdMessage.error(
          <>
            <span>
              Error found with email address:{" "}
              <span className="bold">{formData.email}</span>{" "}
            </span>
            <p className="Login__password-reset-error-message">{e.message}</p>
            <p>For assistance, email teamjubily@gmail.com.</p>
          </>,
          10
        );
      }
    } else {
      antdMessage.error(
        "Please input an email address into the form below",
        10
      );
    }
  }

  async function onPasswordChangeSubmit() {
    if (validatePassword(formData.password)) {
      try {
        setIsLoading(true);
        await Auth.forgotPasswordSubmit(
          formData.email,
          formData.code,
          formData.password
        );
        const user = await Auth.currentAuthenticatedUser();
        await logInUser(user);
        setIsLoading(false);
        history.push(`/search`);
      } catch (e) {
        setIsLoading(false);
        antdMessage.error(
          <>
            <p className="Login__password-reset-error-message">{e.message}</p>
          </>,
          10
        );
      }
    }
  }

  async function resendVerficationCode() {
    let { email } = formData;
    try {
      await Auth.resendSignUp(email);
      notification.success({
        message: "Email Confirmation Required.",
        description: `A new verfication code was sent to ${email}.`,
        duration: null,
      });
    } catch (err) {
      antdMessage.error(err.messsage, 0);
    }
  }

  return (
    <div>
      {view === VIEW_OPTIONS.LOGIN && (
        <form className="Login">
          <Row align="middle">
            <Col span={24} md={14}>
              <div className="loginImage"></div>
            </Col>
            <Col
              offset={1}
              span={22}
              md={8}
              className="loginForm-InputContainer"
            >
              <h1>Welcome Back.</h1>
              <p>
                We've missed you. Log in to find mental health providers,
                dentists, optometrists, and more.
              </p>
              <FormElements
                formItems={loginItems}
                handleChangeEvent={handleInputChange}
                inputValues={formData}
                onPressEnter={onLoginFormSubmit}
              />
              <Button
                type="primary"
                onClick={onLoginFormSubmit}
                block
                loading={isLoading}
              >
                Login
              </Button>
              <div className="redirectText">
                Forgot Password?{" "}
                <span className="link" onClick={sendPasswordResetEmail}>
                  Send password reset email.
                </span>
              </div>
              <LoginMessages messages={passwordErrors} />
            </Col>
          </Row>
        </form>
      )}

      {view === VIEW_OPTIONS.SIGNUP && (
        <form className="Signup">
          <Row align="middle">
            <Col span={24} md={14}>
              <div className="loginImage signupImage"></div>
            </Col>
            <Col
              offset={1}
              span={22}
              md={8}
              className="loginForm-InputContainer"
            >
              <h1>Sign Up</h1>
              <p>
                Providers are currently able to sign up for Jubily. To be
                notified when patient access becomes available,{" "}
                <a href="/#jubily-interest-form">join our mailing list</a>.
              </p>
              <FormElements
                formItems={signupItems}
                handleChangeEvent={handleInputChange}
                inputValues={formData}
                onPressEnter={onSignupFormSubmit}
              />
              <Checkbox
                name="termsOfUseCheckbox"
                onChange={onCheckboxChange}
                checked={formData.termsOfUseCheckbox}
              >
                I have read and agree to the{" "}
                <Link to="/terms-of-use" target="_blank">
                  Terms of Use
                </Link>
              </Checkbox>
              <Checkbox
                name="privacyPolicyCheckbox"
                onChange={onCheckboxChange}
                checked={formData.privacyPolicyCheckbox}
              >
                I have read and agree to the{" "}
                <Link to="/privacy-policy" target="_blank">
                  Privacy Policy
                </Link>
              </Checkbox>
              <Button
                type="primary"
                onClick={onSignupFormSubmit}
                disabled={
                  !formData.email ||
                  !formData.password ||
                  !formData.termsOfUseCheckbox ||
                  !formData.privacyPolicyCheckbox
                }
                block
                loading={isLoading}
              >
                Submit
              </Button>
              <div className="redirectText">
                Already have an account? <Link to="/login">Log In</Link>
              </div>
              <LoginMessages messages={passwordErrors} />
            </Col>
          </Row>
        </form>
      )}

      {view === VIEW_OPTIONS.SIGNUP_CONFIRMATION && (
        <form className="Login">
          <Row align="middle">
            <Col span={24} md={14}>
              <div className="loginImage signupImage"></div>
            </Col>
            <Col
              offset={1}
              span={22}
              md={8}
              className="loginForm-InputContainer"
            >
              <h1>Confirm Your Email Address</h1>
              <p>
                Copy the Verification Code sent to {formData.email}, paste it
                into the input below, and submit.
              </p>
              <FormElements
                formItems={signUpConfirmationItems}
                handleChangeEvent={handleInputChange}
                inputValues={formData}
                onPressEnter={onSignUpVerificationCodeSubmit}
              />
              <Button
                type="primary"
                onClick={onSignUpVerificationCodeSubmit}
                block
                loading={isLoading}
              >
                Submit to Confirm Account
              </Button>
              <LoginMessages messages={passwordErrors} />
            </Col>
          </Row>
        </form>
      )}

      {view === VIEW_OPTIONS.PASSWORD_CONFIRMATION && (
        <form className="Login">
          <Row align="middle">
            <Col span={24} md={14}>
              <div className="loginImage signupImage"></div>
            </Col>
            <Col
              offset={1}
              span={22}
              md={8}
              className="loginForm-InputContainer"
            >
              <h1>Change Password</h1>
              {passwordResetReason && <p>{passwordResetReason}</p>}
              <p>
                Copy the Verification Code sent to {formData.email}, paste it
                into the input below, and submit.
              </p>
              <FormElements
                formItems={passwordConfirmationItems}
                handleChangeEvent={handleInputChange}
                inputValues={formData}
                onPressEnter={onPasswordChangeSubmit}
              />
              <Button
                type="primary"
                onClick={onPasswordChangeSubmit}
                block
                loading={isLoading}
              >
                Submit to Change Password
              </Button>
              <LoginMessages messages={passwordErrors} />
            </Col>
          </Row>
        </form>
      )}
    </div>
  );
}

const isAccountTransfer = (email) =>
  [
    "drruparobbins@summitcenter.us",
    "cyrellroberson@summitcenter.us",
    "drmichellefreeman@summitcenter.us",
  ].includes(email.trim());

export default Login;
