import "./PrivacyPolicy.css";

function PrivacyPolicy() {
  return (
    <div className="page__container PrivacyPolicy">
      <div className="PrivacyPolicy__content">
        <h1>Privacy Policy</h1>

        <h2>Part One: Privacy Overview</h2>

        <p>
          Your privacy and the security of your information is extremely
          important to us. Jubily, Inc is committed to safeguarding your
          information and maintaining the highest level of security and
          confidentiality of your personal and health information. Except as
          permitted or required by law, Jubily, Inc. will never share your
          personal or health related information without your explicit consent
          and permission. Your personal information is stored securely and will
          only be accessed by Jubily, Inc. personnel or business associates when
          it is a necessary part of their job responsibilities.
        </p>

        <p>
          Any information that we collect from you (including, but not limited
          to, your name, contact information, information used for billing and
          payment purposes, other demographic data) will be used for the
          purposes of providing you services through Jubily, Inc. and will be
          kept secure and confidential. Any information or messages you exchange
          with your provider and other information that is considered protected
          health information will also be kept secure and confidential according
          to all applicable laws and regulations for the protection of protected
          health information unless there is a safety concern for yourself or
          someone else, in the case of an emergency, or when explicitly
          requested or approved by you.
        </p>

        {/*
          <p>
          The messaging via the app are secure, encrypted end to end, and adhere to HIPAA
    (Health Insurance Portability and Accountability Act) guidelines for secure video and
    electronic communication. We use [ENTER OUR ENCRYPTION HERE] encryption. We keep all
    communication between clients and  providers on the Jubily, Inc. app to ensure privacy and
    security, and they will be securely stored for a minimum of 7 years in a manner complying
    with all applicable HIPAA regulations and guidelines.
          </p>
          */}

        <p>
          Protecting your personal information is absolutely a priority for
          Jubily, Inc. and we will never disclose, release or sell your data
          without your explicit permission or request. We use industry best
          practices and adhere to HIPAA regulations and guidelines for
          safeguarding and protecting your information and data and preventing
          unauthorized access and use.
        </p>

        <h2>Part Two: Policy Summary</h2>

        <p>
          For your convenience, we have summarized the key takeaways from our
          Privacy Policy, below. You may access our full Privacy Policy in Part
          Three of this document.
        </p>

        <h3>Responsible Entity</h3>

        <p>
          Jubily, Inc. (“We”, “Us”, “the Company”) is the controller of your
          Personal Data and may process this data in accordance with the Privacy
          Policy. If we are processing Personal Data on behalf of a third party,
          the terms of this Privacy Policy do not apply—instead, the terms of
          that third party’s privacy policy will apply. You can contact Us with
          any questions about our Privacy Policy at{" "}
          <a href="mailto: teamjubily@gmail.com">teamjubily@gmail.com</a>.
        </p>

        <h3>What information do We collect and why?</h3>

        <p>
          We collect “PERSONAL DATA”, which includes any information that can be
          used on its own or with other information in combination to identify
          or contact one of our users. For a description of the types of
          Personal Data we collect, review this section in the full Privacy
          Policy. In some cases, this Personal Data may be or may include
          healthcare information or “protected health information”.
        </p>

        <p>
          We may use Personal Data to (1) provide you with the Services; (2)
          communicate with you about and manage your User Account; (3) store
          data; (4) comply with the law; (5) respond to requests from public and
          government authorities; (6) to enforce our terms and conditions; (7)
          manage and improve our operations and applications; (8) provide
          additional functionality; (9) protect our rights, privacy, safety or
          property, and/or that of you or others; and (10) allow us to pursue
          available remedies or limit the damages that we may sustain.
          Additional uses are described in this section of the full Privacy
          Policy.
        </p>

        <p>
          We only use or disclose your Personal Data when it is legally mandated
          or where it is necessary to fulfill the purposes described above (and
          in the full Privacy Policy). Where required by law, we will ask for
          your prior consent before doing so.
        </p>

        <p>
          ‍Failure to Provide Data. Providing your Personal Data is not
          statutorily or contractually mandated. However, if you choose not to
          provide this information, we cannot create a User Account, and you
          will be unable to use our Services.
        </p>

        <h3>Will We share your Personal Data with anyone else?</h3>

        <ol>
          <li>Yes, with the healthcare providers treating you.</li>
          <li>If you are a minor, then yes, with your parent or guardian</li>
          <li>
            Yes, with third parties that help us power our App and Platform
          </li>
          <li>
            Yes, with third parties and the government when legal or enforcement
            issues arise
          </li>
          <li>
            Yes, with third parties in the event of a reorganization, merger,
            sale, joint venture, assignment, transfer, or other disposition of
            all or any portion of Jubily’s corporate entity, assets, or stock
            (including in connection with any bankruptcy or similar proceedings)
          </li>
        </ol>

        <p>
          For more details regarding the above, review this section in the full
          Privacy Policy.
        </p>

        <h3>
          Where is your Personal Data stored, transmitted and/or maintained?
        </h3>

        <p>
          Personal Data Jubily collects through the App and Platform will be
          stored on secure servers in the United States. Some Personal Data may
          be transmitted to third parties, which parties may store or maintain
          the data on their secure servers. These third parties are not
          permitted to transfer your Personal Data outside of the United States.
        </p>

        <h3>How long will We maintain your Personal Data?</h3>

        <p>
          We store your Personal Data for as long as you maintain a User Account
          and up to seven (7) years after the account is closed. For more
          information on Personal Data retention, review this section of the
          full Privacy Policy.
        </p>

        <h3>How do We protect your Personal Data?</h3>

        <p>
          Jubily uses a combination of reasonable physical, technical, and
          administrative security controls to maintain the security and
          integrity of your Personal Data, to protect against any anticipated
          threats or hazards to the security or integrity of such information,
          and to protect against unauthorized access to or use of such
          information in our possession or control that could result in
          substantial harm or inconvenience to you. We hold agreements with
          various third parties powering our App and Platform to ensure that all
          Personal Data you exchange with your Jubily provider, and the medical
          record we may store on your behalf, meet the strict privacy rules set
          forth by the Health Insurance Portability and Accountability Act of
          1996. However, Internet data transmissions, whether wired or wireless,
          cannot be guaranteed to be 100% secure. As a result, we cannot ensure
          the security of information you transmit to us. By using the App or
          Platform, you are assuming this risk. For more information on the
          safeguards We have in place to protect your Personal Data, review this
          section of the full Privacy Policy.
        </p>

        <h3>Your rights</h3>

        <p>
          ‍You have certain rights relating to your Personal Data, subject to
          local data protection laws. These rights may include:
        </p>

        <ol>
          <li>to access your Personal Data held by us</li>
          <li>
            to erase/delete your Personal Data, to the extent permitted by
            applicable data protection laws
          </li>
          <li>
            to receive communications related to the processing of your personal
            data that are concise, transparent, intelligible and easily
            accessible;
          </li>
          <li>
            to restrict the processing of your Personal Data to the extent
            permitted by law (while we verify or investigate your concerns with
            this information, for example);
          </li>
          <li>
            to object to the further processing of your Personal Data, including
            the right to object to marketing;
          </li>
          <li>
            to request that your Personal Data be transferred to a third party,
            if possible;
          </li>
          <li>
            to receive your Personal Data in a structured, commonly used and
            machine-readable format
          </li>
          <li>to lodge a complaint with a supervisory authority</li>
          <li>
            to correct inaccurate Personal Data and, taking into account the
            purpose of processing the Personal Data, ensure it is complete; and
          </li>
          <li>
            to the extent we base the collection, processing and sharing of your
            Personal Data on your consent, to withdraw your consent at any time,
            without affecting the lawfulness of the processing based on such
            consent before its withdrawal.
          </li>
        </ol>

        <p>
          For more details on your rights and choices and how to exercise them,
          please review the full Privacy Policy.
        </p>

        <p>
          We may, from time to time, provide aggregated data and/or other
          information that does not contain any personal identiﬁers (i.e., if
          the information has been anonymized by stripping out identiﬁers such
          as name, address, phone number, etc.) for research or marketing
          purposes. However, Jubily will never sell your Personal Data.
        </p>

        <h3>How do you contact us with questions or concerns?</h3>

        <p>
          If you have any questions about this Privacy Policy, please contact us
          by email at{" "}
          <a href="mailto: teamjubily@gmail.com">teamjubily@gmail.com</a> or
          please write to: 1945 Contra Costa Blvd., PO Box #23331, Pleasant
          Hills, CA 94523. Please note that email communications are not always
          secure; so please do not include sensitive information in your emails
          to us.
        </p>
      </div>
    </div>
  );
}

export default PrivacyPolicy;
