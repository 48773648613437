import { useEffect, useState } from "react";
import { Badge, Avatar } from "antd";
import {
  onUpdateGroupMemberByGroupIdAndMemberUserId,
  removeOnUpdateGroupByGroupIdAndMemberUserIdSubscriptionListener,
} from "../../../services/messaging-service";
import UserAvatar from "../../Profile/UserAvatar/UserAvatar";
import UserImage from "../../Profile/UserImage";
import "./GroupList.css";
import { convertTimeStampToDateComponent } from "../Messages/Messages";

const GroupList = ({ userID, setSelectedGroup, selectedGroup, groups }) => {
  return (
    <div className="GroupList">
      {groups &&
        groups.map((group) => (
          <GroupOption
            key={group.id}
            group={group}
            userID={userID}
            selectedGroupId={selectedGroup.id}
            onClick={() => setSelectedGroup(group)}
          ></GroupOption>
        ))}
    </div>
  );
};

export default GroupList;

const GroupOption = ({ group, userID, onClick, selectedGroupId }) => {
  const [groupOption, setGroupOption] = useState(group);

  useEffect(() => {
    const memberList = group.members.items;
    onUpdateGroupMemberByGroupIdAndMemberUserId(
      group.id,
      userID,
      (groupMemberData) => {
        setGroupOption({
          ...groupMemberData.group,
          lastMessage: {
            ...groupMemberData.group.lastMessage,
            author: memberList.find(
              (member) =>
                member.id === groupMemberData.group.lastMessage?.authorID
            ),
          },
          unreadCount: groupMemberData.unreadCount,
        });
      }
    );
    return () => {
      removeOnUpdateGroupByGroupIdAndMemberUserIdSubscriptionListener();
    };
  }, [group.id, group.members.items, userID]);

  return (
    <Badge
      count={selectedGroupId === groupOption.id ? 0 : groupOption.unreadCount}
      offset={[-20, 30]}
      size="small"
    >
      <div
        className={`group ${
          selectedGroupId === groupOption.id ? "selected" : ""
        }`}
        onClick={onClick}
      >
        <div className="group__avatar-names-container">
          <Avatar.Group
            maxCount={2}
            maxStyle={{
              color: "#55A9A2",
              backgroundColor: "white",
            }}
          >
            {/* Using group instead of groupOptions here because the updateGroup subscription does not */}
            {group?.members?.items.map(
              (member) =>
                userID !== member.id && (
                  <UserAvatar
                    key={member.id}
                    imgUrl={
                      member.image ? (
                        <UserImage
                          fileName={member.image}
                          identityId={member.identityId}
                        />
                      ) : null
                    }
                    text={member.firstName ? member.firstName[0] : null}
                  />
                )
            )}
          </Avatar.Group>
          <div className="group__member-names">
            {group?.members?.items
              .filter((member) => userID !== member.id)
              .map((member, memberIndex, filteredMemberProfiles) => (
                <span key={member.id}>
                  <span>
                    {member.firstName} {member.lastName}
                  </span>
                  {memberIndex < filteredMemberProfiles.length - 1 && (
                    <span>, </span>
                  )}
                </span>
              ))}
          </div>
        </div>
        {groupOption.lastMessage && (
          <>
            <div className="group__last-message">
              {groupOption.lastMessage.author && (
                <span>
                  {groupOption.lastMessage.authorID === userID
                    ? "You"
                    : groupOption.lastMessage.author.firstName}
                  :
                </span>
              )}{" "}
              {groupOption.lastMessage.text}
            </div>
            {groupOption.lastMessage.createdAt && (
              <div>
                {convertTimeStampToDateComponent(
                  groupOption.lastMessage.createdAt
                )}
              </div>
            )}
          </>
        )}
      </div>
    </Badge>
  );
};
