import React, { useState, useEffect } from "react";
import { BRAND_COLORS_ARRAY } from "../../constants";

function ValuePropsBlock() {
  return (
    <section className="homepage__section block-text">
      <div className="homepage__section-block-text">
        <h2 className="large-title-text">
          Jubily connects <CommunityNamesSlideShow /> to culturally responsive
          <br /> clinicians and online communities of support.
        </h2>

        <div className="flex block-text__sections">
          <div className="block-text__section">
            <h3>For Providers</h3>
            <ul>
              <li>
                Training you in culturally responsiveness for continuing
                education credit and connecting you to patients who seek your
                expertise.
              </li>
              <li>
                Bringing you into a community of other culturally responsive
                clinicians.
              </li>
            </ul>
          </div>

          <div className="block-text__section">
            <div className="coming-soon-badge">Coming Soon</div>
            <h3>For Patients</h3>
            <ul>
              <li>
                Connecting you to culturally responsive care who truly
                understand your care needs.
              </li>
              <li>Bringing you into a community of health seekers.</li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
}

const names = [
  "Black people",
  "Latinx people",
  "Indigenous people",
  "Asian Americans",
  "people of color",
  "LGBTQIA+",
  "straight people",
];
function CommunityNamesSlideShow() {
  const [communityIndex, setCommunityIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCommunityIndex((communityIndexState) => {
        const next = communityIndexState + 1;
        if (names.length === next) {
          return 0;
        }
        return next;
      });
    }, 2500);
    return () => clearInterval(interval);
  }, []);

  return (
    <span style={{ color: BRAND_COLORS_ARRAY[communityIndex] }}>
      {names[communityIndex]}
    </span>
  );
}

export default ValuePropsBlock;
