import "../Home/Home.css";
import "./About.css";

function About() {
  return (
    <div className="page__container about__container">
      <div className="about__content">
        <h1>About Us</h1>
        <p>
          The Jubily mobile application and website increases equitable access
          to healthcare and improves healthcare outcomes by connecting people to
          culturally competent clinicians and online communities of support.
        </p>

        <p>
          Word of mouth referrals are outdated. Jubily democratizes the referral
          process by leveraging technology using our research-based algorithm to
          connect patients and clinicians based on important search criteria
          that have been linked to effective and efficacious treatment in
          medical and mental health research. We also know that social support
          systems are crucial to effective treatment. Jubily also provides a
          place where people who struggle with different illnesses (e.g. cancer,
          anxiety, depression etc.) can go to connect with an online community
          of support with other people who are or have gone through similar
          experiences to help them cope. We also provide an online community of
          healthcare professionals where clinicians can easily connect with
          other clinicians across specialties for advice and for referrals as
          this is also currently missing in the marketplace.
        </p>

        <p>
          Jubily fills a gap in the marketplace by providing one comprehensive,
          centralized healthcare hub where medical and mental health care
          professionals are included in our search engine.
        </p>
      </div>
    </div>
  );
}

export default About;
