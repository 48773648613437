import { API } from "aws-amplify";
import { listScreenerQuestions } from "../graphql/queries";

export const getScreenerData = async () => {
  const screenerQuestionsQueryResult = await API.graphql({
    query: listScreenerQuestions,
    variables: { filter: { isHidden: { ne: true } } },
  });
  const screenerQuestions =
    screenerQuestionsQueryResult.data.listScreenerQuestions.items;
  screenerQuestions.sort((a, b) => a.index - b.index);
  screenerQuestions.forEach((question) => (question.name = question.id));
  return screenerQuestions;
};
