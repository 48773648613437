export const USER_TYPES = {
  PROVIDER: "Provider",
  TEST: "Test",
};

export const BRAND_COLORS_ARRAY = [
  "#57d8a7",
  "#8b3f06",
  "#ffa6da",
  "#fd4c4c",
  "#ffbf04",
  "#4284f4",
  "#7431ff",
];
