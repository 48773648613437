import { Button, Checkbox, notification } from "antd";
import {
  ExclamationCircleTwoTone,
  DoubleRightOutlined,
} from "@ant-design/icons";
import { useHistory } from "react-router-dom";

import {
  getGroupIdWithUsers,
  createGroupWithUsers,
} from "../../services/messaging-service";

const notificationKey = "onCheckProviderCardNotification";

const errorNotificationKey = "onClickStartWithoutScreenerNotification";

function MessagingCardAction({
  provider,
  messagingPrompt,
  setMessagingPrompt,
  currentUserProfile,
}) {
  let history = useHistory();

  function onCheckProviderCard(event) {
    event.stopPropagation();
    //Select provider as option to message but if they are already selected, deselect them
    const selected = event.target.checked
      ? [...messagingPrompt.selected, provider]
      : messagingPrompt.selected.filter(
          (member) => member.userID !== provider.userID
        );

    setMessagingPrompt({
      selected,
      isVisible: selected.length > 0,
    });

    if (messagingPrompt.isVisible) {
      notification.close(notificationKey);
    }

    if (selected.length > 0) {
      notification.open({
        key: notificationKey,
        message: (
          <>
            Message{" "}
            {selected.map((member, index) => (
              <span key={member.userID}>
                {member.firstName} {member.lastName}
                {index < selected.length - 1 && <span>, </span>}
              </span>
            ))}
            ?
          </>
        ),
        description: (
          <>
            <Button
              type="primary"
              size="large"
              onClick={async () => {
                const groupMembersList = [
                  ...selected.map((member) => member.userID),
                  currentUserProfile.userID,
                ];
                const groupIdWithMembers = await getGroupIdWithUsers(
                  provider.userID,
                  groupMembersList
                );
                if (groupIdWithMembers) {
                  notification.close(notificationKey);
                  history.push(`/messages/${groupIdWithMembers}`);
                } else {
                  const newGroupId = await createGroupWithUsers({
                    creatorID: currentUserProfile.userID,
                    memberIDs: selected.map((member) => member.userID),
                  });
                  notification.close(notificationKey);
                  history.push(`/messages/${newGroupId}`);
                }
              }}
            >
              Message
            </Button>
          </>
        ),
        placement: "bottomRight",
        duration: 0,
        onClose: () => {
          setMessagingPrompt({ ...messagingPrompt, isVisible: false });
        },
      });
    }
  }

  function showErrorText() {
    notification.error({
      message: <ErrorMessageContent history={history} />,
      duration: 7,
      icon: <ExclamationCircleTwoTone />,
      key: errorNotificationKey,
    });
  }

  return (
    <>
      {provider.isScreenerCompleted ? (
        <div className="message-card-action">
          <>
            {!currentUserProfile?.isScreenerCompleted && (
              <div className="error-trigger" onClick={showErrorText}></div>
            )}
            <Checkbox
              disabled={!currentUserProfile.isScreenerCompleted}
              onChange={onCheckProviderCard}
            >
              Message
            </Checkbox>
          </>
        </div>
      ) : null}
    </>
  );
}

export default MessagingCardAction;

const ErrorMessageContent = ({ history }) => {
  return (
    <>
      <p>
        Complete the cultural responsiveness questionnaire in order to message
        other providers.
      </p>
      <Button
        type="primary"
        size="middle"
        icon={<DoubleRightOutlined />}
        onClick={() => {
          notification.close(errorNotificationKey);
          history.push(`/profile/3`);
        }}
      >
        Navigate to questionnaire
      </Button>
    </>
  );
};
