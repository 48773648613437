/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onUpdateGroupMemberByGroupIdAndMemberUserId = /* GraphQL */ `
  subscription OnUpdateGroupMemberByGroupIdAndMemberUserId(
    $groupID: ID
    $memberUserID: ID
  ) {
    onUpdateGroupMemberByGroupIdAndMemberUserId(
      groupID: $groupID
      memberUserID: $memberUserID
    ) {
      id
      groupID
      group {
        id
        messages {
          nextToken
        }
        creator {
          id
          awsCognitoUsername
          isAccountConfirmed
          isTestAccount
          userTypeID
          email
          providerProfileID
          title
          firstName
          lastName
          postNominals
          image
          identityId
          createdAt
          updatedAt
        }
        creatorID
        members {
          nextToken
        }
        lastMessageID
        lastMessage {
          id
          text
          groupID
          authorID
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      memberUserID
      member {
        id
        awsCognitoUsername
        isAccountConfirmed
        isTestAccount
        userTypeID
        userType {
          id
          name
          createdAt
          updatedAt
        }
        email
        providerProfileID
        providerProfile {
          id
          userID
          title
          firstName
          lastName
          postNominals
          birthDate
          email
          phoneNumber
          image
          identityId
          location
          pronouns
          responsiveAreas
          description
          languagePreferences
          website
          education
          degree
          acceptedInsurances
          isScreenerCompleted
          screenerAnswers
          specialties
          yearsPracticed
          averageFee
          isFeeFlexible
          faithOrientations
          alliedCommunities
          treatmentOrientations
          agePreferences
          paymentMethods
          modalityPreferences
          npi
          licenseNumber
          supervisorLicenseNumber
          licenseExpirationDate
          supervisorLicenseExpirationDate
          createdAt
          updatedAt
        }
        groups {
          nextToken
        }
        title
        firstName
        lastName
        postNominals
        image
        identityId
        createdAt
        updatedAt
      }
      unreadCount
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateGroupMemberByMemberUserId = /* GraphQL */ `
  subscription OnUpdateGroupMemberByMemberUserId($memberUserID: ID) {
    onUpdateGroupMemberByMemberUserId(memberUserID: $memberUserID) {
      id
      groupID
      group {
        id
        messages {
          nextToken
        }
        creator {
          id
          awsCognitoUsername
          isAccountConfirmed
          isTestAccount
          userTypeID
          email
          providerProfileID
          title
          firstName
          lastName
          postNominals
          image
          identityId
          createdAt
          updatedAt
        }
        creatorID
        members {
          nextToken
        }
        lastMessageID
        lastMessage {
          id
          text
          groupID
          authorID
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      memberUserID
      member {
        id
        awsCognitoUsername
        isAccountConfirmed
        isTestAccount
        userTypeID
        userType {
          id
          name
          createdAt
          updatedAt
        }
        email
        providerProfileID
        providerProfile {
          id
          userID
          title
          firstName
          lastName
          postNominals
          birthDate
          email
          phoneNumber
          image
          identityId
          location
          pronouns
          responsiveAreas
          description
          languagePreferences
          website
          education
          degree
          acceptedInsurances
          isScreenerCompleted
          screenerAnswers
          specialties
          yearsPracticed
          averageFee
          isFeeFlexible
          faithOrientations
          alliedCommunities
          treatmentOrientations
          agePreferences
          paymentMethods
          modalityPreferences
          npi
          licenseNumber
          supervisorLicenseNumber
          licenseExpirationDate
          supervisorLicenseExpirationDate
          createdAt
          updatedAt
        }
        groups {
          nextToken
        }
        title
        firstName
        lastName
        postNominals
        image
        identityId
        createdAt
        updatedAt
      }
      unreadCount
      createdAt
      updatedAt
    }
  }
`;
export const onCreateMessageByGroupId = /* GraphQL */ `
  subscription OnCreateMessageByGroupId($groupID: ID!) {
    onCreateMessageByGroupId(groupID: $groupID) {
      id
      text
      groupID
      authorID
      author {
        id
        awsCognitoUsername
        isAccountConfirmed
        isTestAccount
        userTypeID
        userType {
          id
          name
          createdAt
          updatedAt
        }
        email
        providerProfileID
        providerProfile {
          id
          userID
          title
          firstName
          lastName
          postNominals
          birthDate
          email
          phoneNumber
          image
          identityId
          location
          pronouns
          responsiveAreas
          description
          languagePreferences
          website
          education
          degree
          acceptedInsurances
          isScreenerCompleted
          screenerAnswers
          specialties
          yearsPracticed
          averageFee
          isFeeFlexible
          faithOrientations
          alliedCommunities
          treatmentOrientations
          agePreferences
          paymentMethods
          modalityPreferences
          npi
          licenseNumber
          supervisorLicenseNumber
          licenseExpirationDate
          supervisorLicenseExpirationDate
          createdAt
          updatedAt
        }
        groups {
          nextToken
        }
        title
        firstName
        lastName
        postNominals
        image
        identityId
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateScreenerQuestion = /* GraphQL */ `
  subscription OnCreateScreenerQuestion {
    onCreateScreenerQuestion {
      id
      element
      label
      items
      index
      isHidden
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateScreenerQuestion = /* GraphQL */ `
  subscription OnUpdateScreenerQuestion {
    onUpdateScreenerQuestion {
      id
      element
      label
      items
      index
      isHidden
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteScreenerQuestion = /* GraphQL */ `
  subscription OnDeleteScreenerQuestion {
    onDeleteScreenerQuestion {
      id
      element
      label
      items
      index
      isHidden
      createdAt
      updatedAt
    }
  }
`;
export const onCreateGroup = /* GraphQL */ `
  subscription OnCreateGroup {
    onCreateGroup {
      id
      messages {
        items {
          id
          text
          groupID
          authorID
          createdAt
          updatedAt
        }
        nextToken
      }
      creator {
        id
        awsCognitoUsername
        isAccountConfirmed
        isTestAccount
        userTypeID
        userType {
          id
          name
          createdAt
          updatedAt
        }
        email
        providerProfileID
        providerProfile {
          id
          userID
          title
          firstName
          lastName
          postNominals
          birthDate
          email
          phoneNumber
          image
          identityId
          location
          pronouns
          responsiveAreas
          description
          languagePreferences
          website
          education
          degree
          acceptedInsurances
          isScreenerCompleted
          screenerAnswers
          specialties
          yearsPracticed
          averageFee
          isFeeFlexible
          faithOrientations
          alliedCommunities
          treatmentOrientations
          agePreferences
          paymentMethods
          modalityPreferences
          npi
          licenseNumber
          supervisorLicenseNumber
          licenseExpirationDate
          supervisorLicenseExpirationDate
          createdAt
          updatedAt
        }
        groups {
          nextToken
        }
        title
        firstName
        lastName
        postNominals
        image
        identityId
        createdAt
        updatedAt
      }
      creatorID
      members {
        items {
          id
          groupID
          memberUserID
          unreadCount
          createdAt
          updatedAt
        }
        nextToken
      }
      lastMessageID
      lastMessage {
        id
        text
        groupID
        authorID
        author {
          id
          awsCognitoUsername
          isAccountConfirmed
          isTestAccount
          userTypeID
          email
          providerProfileID
          title
          firstName
          lastName
          postNominals
          image
          identityId
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateGroup = /* GraphQL */ `
  subscription OnUpdateGroup {
    onUpdateGroup {
      id
      messages {
        items {
          id
          text
          groupID
          authorID
          createdAt
          updatedAt
        }
        nextToken
      }
      creator {
        id
        awsCognitoUsername
        isAccountConfirmed
        isTestAccount
        userTypeID
        userType {
          id
          name
          createdAt
          updatedAt
        }
        email
        providerProfileID
        providerProfile {
          id
          userID
          title
          firstName
          lastName
          postNominals
          birthDate
          email
          phoneNumber
          image
          identityId
          location
          pronouns
          responsiveAreas
          description
          languagePreferences
          website
          education
          degree
          acceptedInsurances
          isScreenerCompleted
          screenerAnswers
          specialties
          yearsPracticed
          averageFee
          isFeeFlexible
          faithOrientations
          alliedCommunities
          treatmentOrientations
          agePreferences
          paymentMethods
          modalityPreferences
          npi
          licenseNumber
          supervisorLicenseNumber
          licenseExpirationDate
          supervisorLicenseExpirationDate
          createdAt
          updatedAt
        }
        groups {
          nextToken
        }
        title
        firstName
        lastName
        postNominals
        image
        identityId
        createdAt
        updatedAt
      }
      creatorID
      members {
        items {
          id
          groupID
          memberUserID
          unreadCount
          createdAt
          updatedAt
        }
        nextToken
      }
      lastMessageID
      lastMessage {
        id
        text
        groupID
        authorID
        author {
          id
          awsCognitoUsername
          isAccountConfirmed
          isTestAccount
          userTypeID
          email
          providerProfileID
          title
          firstName
          lastName
          postNominals
          image
          identityId
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteGroup = /* GraphQL */ `
  subscription OnDeleteGroup {
    onDeleteGroup {
      id
      messages {
        items {
          id
          text
          groupID
          authorID
          createdAt
          updatedAt
        }
        nextToken
      }
      creator {
        id
        awsCognitoUsername
        isAccountConfirmed
        isTestAccount
        userTypeID
        userType {
          id
          name
          createdAt
          updatedAt
        }
        email
        providerProfileID
        providerProfile {
          id
          userID
          title
          firstName
          lastName
          postNominals
          birthDate
          email
          phoneNumber
          image
          identityId
          location
          pronouns
          responsiveAreas
          description
          languagePreferences
          website
          education
          degree
          acceptedInsurances
          isScreenerCompleted
          screenerAnswers
          specialties
          yearsPracticed
          averageFee
          isFeeFlexible
          faithOrientations
          alliedCommunities
          treatmentOrientations
          agePreferences
          paymentMethods
          modalityPreferences
          npi
          licenseNumber
          supervisorLicenseNumber
          licenseExpirationDate
          supervisorLicenseExpirationDate
          createdAt
          updatedAt
        }
        groups {
          nextToken
        }
        title
        firstName
        lastName
        postNominals
        image
        identityId
        createdAt
        updatedAt
      }
      creatorID
      members {
        items {
          id
          groupID
          memberUserID
          unreadCount
          createdAt
          updatedAt
        }
        nextToken
      }
      lastMessageID
      lastMessage {
        id
        text
        groupID
        authorID
        author {
          id
          awsCognitoUsername
          isAccountConfirmed
          isTestAccount
          userTypeID
          email
          providerProfileID
          title
          firstName
          lastName
          postNominals
          image
          identityId
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateMessage = /* GraphQL */ `
  subscription OnCreateMessage {
    onCreateMessage {
      id
      text
      groupID
      authorID
      author {
        id
        awsCognitoUsername
        isAccountConfirmed
        isTestAccount
        userTypeID
        userType {
          id
          name
          createdAt
          updatedAt
        }
        email
        providerProfileID
        providerProfile {
          id
          userID
          title
          firstName
          lastName
          postNominals
          birthDate
          email
          phoneNumber
          image
          identityId
          location
          pronouns
          responsiveAreas
          description
          languagePreferences
          website
          education
          degree
          acceptedInsurances
          isScreenerCompleted
          screenerAnswers
          specialties
          yearsPracticed
          averageFee
          isFeeFlexible
          faithOrientations
          alliedCommunities
          treatmentOrientations
          agePreferences
          paymentMethods
          modalityPreferences
          npi
          licenseNumber
          supervisorLicenseNumber
          licenseExpirationDate
          supervisorLicenseExpirationDate
          createdAt
          updatedAt
        }
        groups {
          nextToken
        }
        title
        firstName
        lastName
        postNominals
        image
        identityId
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateMessage = /* GraphQL */ `
  subscription OnUpdateMessage {
    onUpdateMessage {
      id
      text
      groupID
      authorID
      author {
        id
        awsCognitoUsername
        isAccountConfirmed
        isTestAccount
        userTypeID
        userType {
          id
          name
          createdAt
          updatedAt
        }
        email
        providerProfileID
        providerProfile {
          id
          userID
          title
          firstName
          lastName
          postNominals
          birthDate
          email
          phoneNumber
          image
          identityId
          location
          pronouns
          responsiveAreas
          description
          languagePreferences
          website
          education
          degree
          acceptedInsurances
          isScreenerCompleted
          screenerAnswers
          specialties
          yearsPracticed
          averageFee
          isFeeFlexible
          faithOrientations
          alliedCommunities
          treatmentOrientations
          agePreferences
          paymentMethods
          modalityPreferences
          npi
          licenseNumber
          supervisorLicenseNumber
          licenseExpirationDate
          supervisorLicenseExpirationDate
          createdAt
          updatedAt
        }
        groups {
          nextToken
        }
        title
        firstName
        lastName
        postNominals
        image
        identityId
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteMessage = /* GraphQL */ `
  subscription OnDeleteMessage {
    onDeleteMessage {
      id
      text
      groupID
      authorID
      author {
        id
        awsCognitoUsername
        isAccountConfirmed
        isTestAccount
        userTypeID
        userType {
          id
          name
          createdAt
          updatedAt
        }
        email
        providerProfileID
        providerProfile {
          id
          userID
          title
          firstName
          lastName
          postNominals
          birthDate
          email
          phoneNumber
          image
          identityId
          location
          pronouns
          responsiveAreas
          description
          languagePreferences
          website
          education
          degree
          acceptedInsurances
          isScreenerCompleted
          screenerAnswers
          specialties
          yearsPracticed
          averageFee
          isFeeFlexible
          faithOrientations
          alliedCommunities
          treatmentOrientations
          agePreferences
          paymentMethods
          modalityPreferences
          npi
          licenseNumber
          supervisorLicenseNumber
          licenseExpirationDate
          supervisorLicenseExpirationDate
          createdAt
          updatedAt
        }
        groups {
          nextToken
        }
        title
        firstName
        lastName
        postNominals
        image
        identityId
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateGroupMember = /* GraphQL */ `
  subscription OnCreateGroupMember {
    onCreateGroupMember {
      id
      groupID
      group {
        id
        messages {
          nextToken
        }
        creator {
          id
          awsCognitoUsername
          isAccountConfirmed
          isTestAccount
          userTypeID
          email
          providerProfileID
          title
          firstName
          lastName
          postNominals
          image
          identityId
          createdAt
          updatedAt
        }
        creatorID
        members {
          nextToken
        }
        lastMessageID
        lastMessage {
          id
          text
          groupID
          authorID
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      memberUserID
      member {
        id
        awsCognitoUsername
        isAccountConfirmed
        isTestAccount
        userTypeID
        userType {
          id
          name
          createdAt
          updatedAt
        }
        email
        providerProfileID
        providerProfile {
          id
          userID
          title
          firstName
          lastName
          postNominals
          birthDate
          email
          phoneNumber
          image
          identityId
          location
          pronouns
          responsiveAreas
          description
          languagePreferences
          website
          education
          degree
          acceptedInsurances
          isScreenerCompleted
          screenerAnswers
          specialties
          yearsPracticed
          averageFee
          isFeeFlexible
          faithOrientations
          alliedCommunities
          treatmentOrientations
          agePreferences
          paymentMethods
          modalityPreferences
          npi
          licenseNumber
          supervisorLicenseNumber
          licenseExpirationDate
          supervisorLicenseExpirationDate
          createdAt
          updatedAt
        }
        groups {
          nextToken
        }
        title
        firstName
        lastName
        postNominals
        image
        identityId
        createdAt
        updatedAt
      }
      unreadCount
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateGroupMember = /* GraphQL */ `
  subscription OnUpdateGroupMember {
    onUpdateGroupMember {
      id
      groupID
      group {
        id
        messages {
          nextToken
        }
        creator {
          id
          awsCognitoUsername
          isAccountConfirmed
          isTestAccount
          userTypeID
          email
          providerProfileID
          title
          firstName
          lastName
          postNominals
          image
          identityId
          createdAt
          updatedAt
        }
        creatorID
        members {
          nextToken
        }
        lastMessageID
        lastMessage {
          id
          text
          groupID
          authorID
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      memberUserID
      member {
        id
        awsCognitoUsername
        isAccountConfirmed
        isTestAccount
        userTypeID
        userType {
          id
          name
          createdAt
          updatedAt
        }
        email
        providerProfileID
        providerProfile {
          id
          userID
          title
          firstName
          lastName
          postNominals
          birthDate
          email
          phoneNumber
          image
          identityId
          location
          pronouns
          responsiveAreas
          description
          languagePreferences
          website
          education
          degree
          acceptedInsurances
          isScreenerCompleted
          screenerAnswers
          specialties
          yearsPracticed
          averageFee
          isFeeFlexible
          faithOrientations
          alliedCommunities
          treatmentOrientations
          agePreferences
          paymentMethods
          modalityPreferences
          npi
          licenseNumber
          supervisorLicenseNumber
          licenseExpirationDate
          supervisorLicenseExpirationDate
          createdAt
          updatedAt
        }
        groups {
          nextToken
        }
        title
        firstName
        lastName
        postNominals
        image
        identityId
        createdAt
        updatedAt
      }
      unreadCount
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteGroupMember = /* GraphQL */ `
  subscription OnDeleteGroupMember {
    onDeleteGroupMember {
      id
      groupID
      group {
        id
        messages {
          nextToken
        }
        creator {
          id
          awsCognitoUsername
          isAccountConfirmed
          isTestAccount
          userTypeID
          email
          providerProfileID
          title
          firstName
          lastName
          postNominals
          image
          identityId
          createdAt
          updatedAt
        }
        creatorID
        members {
          nextToken
        }
        lastMessageID
        lastMessage {
          id
          text
          groupID
          authorID
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      memberUserID
      member {
        id
        awsCognitoUsername
        isAccountConfirmed
        isTestAccount
        userTypeID
        userType {
          id
          name
          createdAt
          updatedAt
        }
        email
        providerProfileID
        providerProfile {
          id
          userID
          title
          firstName
          lastName
          postNominals
          birthDate
          email
          phoneNumber
          image
          identityId
          location
          pronouns
          responsiveAreas
          description
          languagePreferences
          website
          education
          degree
          acceptedInsurances
          isScreenerCompleted
          screenerAnswers
          specialties
          yearsPracticed
          averageFee
          isFeeFlexible
          faithOrientations
          alliedCommunities
          treatmentOrientations
          agePreferences
          paymentMethods
          modalityPreferences
          npi
          licenseNumber
          supervisorLicenseNumber
          licenseExpirationDate
          supervisorLicenseExpirationDate
          createdAt
          updatedAt
        }
        groups {
          nextToken
        }
        title
        firstName
        lastName
        postNominals
        image
        identityId
        createdAt
        updatedAt
      }
      unreadCount
      createdAt
      updatedAt
    }
  }
`;
export const onCreateUserType = /* GraphQL */ `
  subscription OnCreateUserType {
    onCreateUserType {
      id
      name
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateUserType = /* GraphQL */ `
  subscription OnUpdateUserType {
    onUpdateUserType {
      id
      name
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteUserType = /* GraphQL */ `
  subscription OnDeleteUserType {
    onDeleteUserType {
      id
      name
      createdAt
      updatedAt
    }
  }
`;
export const onCreateInsuranceType = /* GraphQL */ `
  subscription OnCreateInsuranceType {
    onCreateInsuranceType {
      id
      name
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateInsuranceType = /* GraphQL */ `
  subscription OnUpdateInsuranceType {
    onUpdateInsuranceType {
      id
      name
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteInsuranceType = /* GraphQL */ `
  subscription OnDeleteInsuranceType {
    onDeleteInsuranceType {
      id
      name
      createdAt
      updatedAt
    }
  }
`;
export const onCreateResponsiveType = /* GraphQL */ `
  subscription OnCreateResponsiveType {
    onCreateResponsiveType {
      id
      name
      tagColor
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateResponsiveType = /* GraphQL */ `
  subscription OnUpdateResponsiveType {
    onUpdateResponsiveType {
      id
      name
      tagColor
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteResponsiveType = /* GraphQL */ `
  subscription OnDeleteResponsiveType {
    onDeleteResponsiveType {
      id
      name
      tagColor
      createdAt
      updatedAt
    }
  }
`;
export const onCreateLanguageType = /* GraphQL */ `
  subscription OnCreateLanguageType {
    onCreateLanguageType {
      id
      name
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateLanguageType = /* GraphQL */ `
  subscription OnUpdateLanguageType {
    onUpdateLanguageType {
      id
      name
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteLanguageType = /* GraphQL */ `
  subscription OnDeleteLanguageType {
    onDeleteLanguageType {
      id
      name
      createdAt
      updatedAt
    }
  }
`;
export const onCreatePronounType = /* GraphQL */ `
  subscription OnCreatePronounType {
    onCreatePronounType {
      id
      name
      createdAt
      updatedAt
    }
  }
`;
export const onUpdatePronounType = /* GraphQL */ `
  subscription OnUpdatePronounType {
    onUpdatePronounType {
      id
      name
      createdAt
      updatedAt
    }
  }
`;
export const onDeletePronounType = /* GraphQL */ `
  subscription OnDeletePronounType {
    onDeletePronounType {
      id
      name
      createdAt
      updatedAt
    }
  }
`;
export const onCreateSpecialtyType = /* GraphQL */ `
  subscription OnCreateSpecialtyType {
    onCreateSpecialtyType {
      id
      name
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateSpecialtyType = /* GraphQL */ `
  subscription OnUpdateSpecialtyType {
    onUpdateSpecialtyType {
      id
      name
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteSpecialtyType = /* GraphQL */ `
  subscription OnDeleteSpecialtyType {
    onDeleteSpecialtyType {
      id
      name
      createdAt
      updatedAt
    }
  }
`;
export const onCreateFaithOrientationType = /* GraphQL */ `
  subscription OnCreateFaithOrientationType {
    onCreateFaithOrientationType {
      id
      name
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateFaithOrientationType = /* GraphQL */ `
  subscription OnUpdateFaithOrientationType {
    onUpdateFaithOrientationType {
      id
      name
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteFaithOrientationType = /* GraphQL */ `
  subscription OnDeleteFaithOrientationType {
    onDeleteFaithOrientationType {
      id
      name
      createdAt
      updatedAt
    }
  }
`;
export const onCreateCommunityType = /* GraphQL */ `
  subscription OnCreateCommunityType {
    onCreateCommunityType {
      id
      name
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateCommunityType = /* GraphQL */ `
  subscription OnUpdateCommunityType {
    onUpdateCommunityType {
      id
      name
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteCommunityType = /* GraphQL */ `
  subscription OnDeleteCommunityType {
    onDeleteCommunityType {
      id
      name
      createdAt
      updatedAt
    }
  }
`;
export const onCreateTreatmentType = /* GraphQL */ `
  subscription OnCreateTreatmentType {
    onCreateTreatmentType {
      id
      name
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateTreatmentType = /* GraphQL */ `
  subscription OnUpdateTreatmentType {
    onUpdateTreatmentType {
      id
      name
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteTreatmentType = /* GraphQL */ `
  subscription OnDeleteTreatmentType {
    onDeleteTreatmentType {
      id
      name
      createdAt
      updatedAt
    }
  }
`;
export const onCreateAgeType = /* GraphQL */ `
  subscription OnCreateAgeType {
    onCreateAgeType {
      id
      name
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateAgeType = /* GraphQL */ `
  subscription OnUpdateAgeType {
    onUpdateAgeType {
      id
      name
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteAgeType = /* GraphQL */ `
  subscription OnDeleteAgeType {
    onDeleteAgeType {
      id
      name
      createdAt
      updatedAt
    }
  }
`;
export const onCreatePaymentMethodType = /* GraphQL */ `
  subscription OnCreatePaymentMethodType {
    onCreatePaymentMethodType {
      id
      name
      createdAt
      updatedAt
    }
  }
`;
export const onUpdatePaymentMethodType = /* GraphQL */ `
  subscription OnUpdatePaymentMethodType {
    onUpdatePaymentMethodType {
      id
      name
      createdAt
      updatedAt
    }
  }
`;
export const onDeletePaymentMethodType = /* GraphQL */ `
  subscription OnDeletePaymentMethodType {
    onDeletePaymentMethodType {
      id
      name
      createdAt
      updatedAt
    }
  }
`;
export const onCreateModalityType = /* GraphQL */ `
  subscription OnCreateModalityType {
    onCreateModalityType {
      id
      name
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateModalityType = /* GraphQL */ `
  subscription OnUpdateModalityType {
    onUpdateModalityType {
      id
      name
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteModalityType = /* GraphQL */ `
  subscription OnDeleteModalityType {
    onDeleteModalityType {
      id
      name
      createdAt
      updatedAt
    }
  }
`;
export const onCreateUser = /* GraphQL */ `
  subscription OnCreateUser {
    onCreateUser {
      id
      awsCognitoUsername
      isAccountConfirmed
      isTestAccount
      userTypeID
      userType {
        id
        name
        createdAt
        updatedAt
      }
      email
      providerProfileID
      providerProfile {
        id
        userID
        user {
          id
          awsCognitoUsername
          isAccountConfirmed
          isTestAccount
          userTypeID
          email
          providerProfileID
          title
          firstName
          lastName
          postNominals
          image
          identityId
          createdAt
          updatedAt
        }
        title
        firstName
        lastName
        postNominals
        birthDate
        email
        phoneNumber
        image
        identityId
        location
        pronouns
        responsiveAreas
        description
        languagePreferences
        website
        education
        degree
        acceptedInsurances
        isScreenerCompleted
        screenerAnswers
        specialties
        yearsPracticed
        averageFee
        isFeeFlexible
        faithOrientations
        alliedCommunities
        treatmentOrientations
        agePreferences
        paymentMethods
        modalityPreferences
        npi
        licenseNumber
        supervisorLicenseNumber
        licenseExpirationDate
        supervisorLicenseExpirationDate
        createdAt
        updatedAt
      }
      groups {
        items {
          id
          groupID
          memberUserID
          unreadCount
          createdAt
          updatedAt
        }
        nextToken
      }
      title
      firstName
      lastName
      postNominals
      image
      identityId
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateUser = /* GraphQL */ `
  subscription OnUpdateUser {
    onUpdateUser {
      id
      awsCognitoUsername
      isAccountConfirmed
      isTestAccount
      userTypeID
      userType {
        id
        name
        createdAt
        updatedAt
      }
      email
      providerProfileID
      providerProfile {
        id
        userID
        user {
          id
          awsCognitoUsername
          isAccountConfirmed
          isTestAccount
          userTypeID
          email
          providerProfileID
          title
          firstName
          lastName
          postNominals
          image
          identityId
          createdAt
          updatedAt
        }
        title
        firstName
        lastName
        postNominals
        birthDate
        email
        phoneNumber
        image
        identityId
        location
        pronouns
        responsiveAreas
        description
        languagePreferences
        website
        education
        degree
        acceptedInsurances
        isScreenerCompleted
        screenerAnswers
        specialties
        yearsPracticed
        averageFee
        isFeeFlexible
        faithOrientations
        alliedCommunities
        treatmentOrientations
        agePreferences
        paymentMethods
        modalityPreferences
        npi
        licenseNumber
        supervisorLicenseNumber
        licenseExpirationDate
        supervisorLicenseExpirationDate
        createdAt
        updatedAt
      }
      groups {
        items {
          id
          groupID
          memberUserID
          unreadCount
          createdAt
          updatedAt
        }
        nextToken
      }
      title
      firstName
      lastName
      postNominals
      image
      identityId
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteUser = /* GraphQL */ `
  subscription OnDeleteUser {
    onDeleteUser {
      id
      awsCognitoUsername
      isAccountConfirmed
      isTestAccount
      userTypeID
      userType {
        id
        name
        createdAt
        updatedAt
      }
      email
      providerProfileID
      providerProfile {
        id
        userID
        user {
          id
          awsCognitoUsername
          isAccountConfirmed
          isTestAccount
          userTypeID
          email
          providerProfileID
          title
          firstName
          lastName
          postNominals
          image
          identityId
          createdAt
          updatedAt
        }
        title
        firstName
        lastName
        postNominals
        birthDate
        email
        phoneNumber
        image
        identityId
        location
        pronouns
        responsiveAreas
        description
        languagePreferences
        website
        education
        degree
        acceptedInsurances
        isScreenerCompleted
        screenerAnswers
        specialties
        yearsPracticed
        averageFee
        isFeeFlexible
        faithOrientations
        alliedCommunities
        treatmentOrientations
        agePreferences
        paymentMethods
        modalityPreferences
        npi
        licenseNumber
        supervisorLicenseNumber
        licenseExpirationDate
        supervisorLicenseExpirationDate
        createdAt
        updatedAt
      }
      groups {
        items {
          id
          groupID
          memberUserID
          unreadCount
          createdAt
          updatedAt
        }
        nextToken
      }
      title
      firstName
      lastName
      postNominals
      image
      identityId
      createdAt
      updatedAt
    }
  }
`;
export const onCreateProviderProfile = /* GraphQL */ `
  subscription OnCreateProviderProfile {
    onCreateProviderProfile {
      id
      userID
      user {
        id
        awsCognitoUsername
        isAccountConfirmed
        isTestAccount
        userTypeID
        userType {
          id
          name
          createdAt
          updatedAt
        }
        email
        providerProfileID
        providerProfile {
          id
          userID
          title
          firstName
          lastName
          postNominals
          birthDate
          email
          phoneNumber
          image
          identityId
          location
          pronouns
          responsiveAreas
          description
          languagePreferences
          website
          education
          degree
          acceptedInsurances
          isScreenerCompleted
          screenerAnswers
          specialties
          yearsPracticed
          averageFee
          isFeeFlexible
          faithOrientations
          alliedCommunities
          treatmentOrientations
          agePreferences
          paymentMethods
          modalityPreferences
          npi
          licenseNumber
          supervisorLicenseNumber
          licenseExpirationDate
          supervisorLicenseExpirationDate
          createdAt
          updatedAt
        }
        groups {
          nextToken
        }
        title
        firstName
        lastName
        postNominals
        image
        identityId
        createdAt
        updatedAt
      }
      title
      firstName
      lastName
      postNominals
      birthDate
      email
      phoneNumber
      image
      identityId
      location
      pronouns
      responsiveAreas
      description
      languagePreferences
      website
      education
      degree
      acceptedInsurances
      isScreenerCompleted
      screenerAnswers
      specialties
      yearsPracticed
      averageFee
      isFeeFlexible
      faithOrientations
      alliedCommunities
      treatmentOrientations
      agePreferences
      paymentMethods
      modalityPreferences
      npi
      licenseNumber
      supervisorLicenseNumber
      licenseExpirationDate
      supervisorLicenseExpirationDate
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateProviderProfile = /* GraphQL */ `
  subscription OnUpdateProviderProfile {
    onUpdateProviderProfile {
      id
      userID
      user {
        id
        awsCognitoUsername
        isAccountConfirmed
        isTestAccount
        userTypeID
        userType {
          id
          name
          createdAt
          updatedAt
        }
        email
        providerProfileID
        providerProfile {
          id
          userID
          title
          firstName
          lastName
          postNominals
          birthDate
          email
          phoneNumber
          image
          identityId
          location
          pronouns
          responsiveAreas
          description
          languagePreferences
          website
          education
          degree
          acceptedInsurances
          isScreenerCompleted
          screenerAnswers
          specialties
          yearsPracticed
          averageFee
          isFeeFlexible
          faithOrientations
          alliedCommunities
          treatmentOrientations
          agePreferences
          paymentMethods
          modalityPreferences
          npi
          licenseNumber
          supervisorLicenseNumber
          licenseExpirationDate
          supervisorLicenseExpirationDate
          createdAt
          updatedAt
        }
        groups {
          nextToken
        }
        title
        firstName
        lastName
        postNominals
        image
        identityId
        createdAt
        updatedAt
      }
      title
      firstName
      lastName
      postNominals
      birthDate
      email
      phoneNumber
      image
      identityId
      location
      pronouns
      responsiveAreas
      description
      languagePreferences
      website
      education
      degree
      acceptedInsurances
      isScreenerCompleted
      screenerAnswers
      specialties
      yearsPracticed
      averageFee
      isFeeFlexible
      faithOrientations
      alliedCommunities
      treatmentOrientations
      agePreferences
      paymentMethods
      modalityPreferences
      npi
      licenseNumber
      supervisorLicenseNumber
      licenseExpirationDate
      supervisorLicenseExpirationDate
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteProviderProfile = /* GraphQL */ `
  subscription OnDeleteProviderProfile {
    onDeleteProviderProfile {
      id
      userID
      user {
        id
        awsCognitoUsername
        isAccountConfirmed
        isTestAccount
        userTypeID
        userType {
          id
          name
          createdAt
          updatedAt
        }
        email
        providerProfileID
        providerProfile {
          id
          userID
          title
          firstName
          lastName
          postNominals
          birthDate
          email
          phoneNumber
          image
          identityId
          location
          pronouns
          responsiveAreas
          description
          languagePreferences
          website
          education
          degree
          acceptedInsurances
          isScreenerCompleted
          screenerAnswers
          specialties
          yearsPracticed
          averageFee
          isFeeFlexible
          faithOrientations
          alliedCommunities
          treatmentOrientations
          agePreferences
          paymentMethods
          modalityPreferences
          npi
          licenseNumber
          supervisorLicenseNumber
          licenseExpirationDate
          supervisorLicenseExpirationDate
          createdAt
          updatedAt
        }
        groups {
          nextToken
        }
        title
        firstName
        lastName
        postNominals
        image
        identityId
        createdAt
        updatedAt
      }
      title
      firstName
      lastName
      postNominals
      birthDate
      email
      phoneNumber
      image
      identityId
      location
      pronouns
      responsiveAreas
      description
      languagePreferences
      website
      education
      degree
      acceptedInsurances
      isScreenerCompleted
      screenerAnswers
      specialties
      yearsPracticed
      averageFee
      isFeeFlexible
      faithOrientations
      alliedCommunities
      treatmentOrientations
      agePreferences
      paymentMethods
      modalityPreferences
      npi
      licenseNumber
      supervisorLicenseNumber
      licenseExpirationDate
      supervisorLicenseExpirationDate
      createdAt
      updatedAt
    }
  }
`;
