import { Row, Col, Collapse, Descriptions, Tag } from "antd";
import { PlusOutlined, MinusOutlined } from "@ant-design/icons";
import UserAvatar from "../UserAvatar/UserAvatar";
import UserImage from "../UserImage";
import { culturalResponsiveness } from "../profile.data";
import "./Preview.css";

function Preview({
  title,
  firstName,
  lastName,
  postNominals,
  pronouns,
  birthDate,
  description,
  yearsPracticed,
  email,
  education,
  degree,
  averageFee,
  responsiveAreas,
  image,
  identityId,
  location,
  specialties,
  languagePreferences,
  phoneNumber,
  faithOrientations,
  alliedCommunities,
  treatmentOrientations,
  agePreferences,
  paymentMethods,
  acceptedInsurances,
  modalityPreferences,
  website,
  isFeeFlexible,
}) {
  return (
    <Row className="Preview">
      {image && (
        <Col flex="auto" className="image-container" span={10}>
          <UserAvatar
            imgUrl={<UserImage fileName={image} identityId={identityId} />}
          />
        </Col>
      )}

      <Col
        flex="auto"
        className={`profile-heading ${image ? "with-image" : ""}`}
      >
        {/* Name title is only shown if post nominals are not given because this is seen as redundant */}
        <h2 className="profile-name">
          {!postNominals && <span>{title}</span>} {firstName} {lastName}
          {postNominals && ","} {postNominals}
        </h2>
        {specialties && <p>{specialties}</p>}
        {pronouns &&
          pronouns.map((pronoun, index) => (
            <span key={index} className="pronoun">
              {pronoun}
            </span>
          ))}
      </Col>

      <Collapse
        defaultActiveKey={["1", "2", "3"]}
        expandIconPosition="right"
        expandIcon={expandCollapseIcon}
        ghost
      >
        {(location ||
          phoneNumber ||
          email ||
          averageFee ||
          yearsPracticed ||
          paymentMethods?.length > 0 ||
          acceptedInsurances?.length > 0 ||
          education ||
          description ||
          website) && (
          <Collapse.Panel
            header={
              <span className="collapse-panel-header">Provider Details</span>
            }
            key="1"
          >
            <Descriptions column={1} bordered>
              {location && (
                <Descriptions.Item label="Location">
                  {location}
                </Descriptions.Item>
              )}
              {phoneNumber && (
                <Descriptions.Item label="Phone Number">
                  {phoneNumber}
                </Descriptions.Item>
              )}
              {email && (
                <Descriptions.Item label="Email">{email}</Descriptions.Item>
              )}
              {averageFee && (
                <Descriptions.Item label="Average Fee">
                  ${averageFee}
                  {isFeeFlexible && (
                    <div className="fee-text">Fee is Flexible</div>
                  )}
                </Descriptions.Item>
              )}
              {yearsPracticed && (
                <Descriptions.Item label="Years In Practice">
                  {yearsPracticed}
                </Descriptions.Item>
              )}
              {paymentMethods?.length > 0 && (
                <Descriptions.Item label="Accepted Payment Methods">
                  <Row>
                    {paymentMethods.map((method, index) => (
                      <Col span={12} key={index}>
                        {method}
                      </Col>
                    ))}
                  </Row>
                </Descriptions.Item>
              )}
              {acceptedInsurances?.length > 0 && (
                <Descriptions.Item label="Accepted Insurance">
                  <Row>
                    {acceptedInsurances.map((insurance, index) => (
                      <Col span={12} key={index}>
                        {insurance}
                      </Col>
                    ))}
                  </Row>
                </Descriptions.Item>
              )}
              {education && (
                <Descriptions.Item label="Education">
                  {education}
                  {degree && <>,</>} {degree}
                </Descriptions.Item>
              )}
              {website && (
                <Descriptions.Item label="Website">
                  <a href={website} target="_blank" rel="noopener noreferrer">
                    {website}
                  </a>
                </Descriptions.Item>
              )}
              {description && (
                <Descriptions.Item label="Personal Bio">
                  {description}
                </Descriptions.Item>
              )}
            </Descriptions>
          </Collapse.Panel>
        )}

        {(responsiveAreas?.length > 0 ||
          languagePreferences?.length > 0 ||
          faithOrientations?.length > 0 ||
          agePreferences?.length > 0 ||
          alliedCommunities?.length > 0) && (
          <Collapse.Panel
            header={
              <span className="collapse-panel-header">Community Insights</span>
            }
            key="2"
          >
            <Descriptions column={1} bordered>
              {responsiveAreas?.length > 0 && (
                <Descriptions.Item label="Cultural Responsive Areas">
                  {responsiveAreas.map((area, index) => {
                    const responsivenessItem = culturalResponsiveness.find(
                      (item) => item.label === area
                    );
                    return (
                      <Tag key={index} color={responsivenessItem.tagColor}>
                        {area}
                      </Tag>
                    );
                  })}
                </Descriptions.Item>
              )}
              {languagePreferences?.length > 0 && (
                <Descriptions.Item label="Language">
                  {languagePreferences.map((language, index) => (
                    <div key={index}>{language}</div>
                  ))}
                </Descriptions.Item>
              )}
              {faithOrientations?.length > 0 && (
                <Descriptions.Item label="Insights for Specific Faith Orientation">
                  {faithOrientations.map((faith, index) => (
                    <div key={index}>{faith}</div>
                  ))}
                </Descriptions.Item>
              )}
              {agePreferences?.length > 0 && (
                <Descriptions.Item label="Insights for Particular Age Groups">
                  {agePreferences.map((age, index) => (
                    <div key={index}>{age}</div>
                  ))}
                </Descriptions.Item>
              )}
              {alliedCommunities?.length > 0 && (
                <Descriptions.Item label="Communities Allied With">
                  <Row>
                    {alliedCommunities.map((ally, index) => (
                      <Col span={12} key={index}>
                        {ally}
                      </Col>
                    ))}
                  </Row>
                </Descriptions.Item>
              )}
            </Descriptions>
          </Collapse.Panel>
        )}

        {(treatmentOrientations?.length > 0 ||
          modalityPreferences?.length > 0) && (
          <Collapse.Panel
            header={
              <span className="collapse-panel-header">
                Treatment Method and Modality
              </span>
            }
            key="3"
          >
            <Descriptions column={1} bordered>
              {treatmentOrientations?.length > 0 && (
                <Descriptions.Item label="Treatment Methods">
                  <Row>
                    {treatmentOrientations.map((treatment, index) => (
                      <Col span={12} key={index}>
                        {treatment}
                      </Col>
                    ))}
                  </Row>
                </Descriptions.Item>
              )}
              {modalityPreferences?.length > 0 && (
                <Descriptions.Item label="Preferred Treatment Modality">
                  {modalityPreferences.map((modality, index) => (
                    <div key={index}>{modality}</div>
                  ))}
                </Descriptions.Item>
              )}
            </Descriptions>
          </Collapse.Panel>
        )}
      </Collapse>
    </Row>
  );
}

export default Preview;

const expandCollapseIcon = ({ isActive }) => {
  if (isActive) {
    return <MinusOutlined />;
  }
  return <PlusOutlined />;
};
