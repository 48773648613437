const getFiltersFromListAndFacets = function (list, facets) {
  const filterList = facets.map((facet) => ({
    facet,
    options: [],
  }));

  list.forEach((listItem) => {
    facets.forEach((facet) => {
      const currentFilterSet = filterList.find(
        (filter) => filter.facet === facet
      );
      const listItemFacetOption = listItem[facet] || null;
      if (listItemFacetOption) {
        if (Array.isArray(listItemFacetOption)) {
          listItemFacetOption.forEach((facetOption) =>
            addFacetOption(facetOption, currentFilterSet)
          );
        } else {
          addFacetOption(listItemFacetOption, currentFilterSet);
        }
      }
    });
  });

  function addFacetOption(listItemFacetOption, currentFilterSet) {
    const currentFacetOption = currentFilterSet.options.find(
      (option) => option.label === listItemFacetOption
    );
    if (currentFacetOption) {
      currentFacetOption.count++;
    } else {
      currentFilterSet.options.push({
        label: listItemFacetOption,
        count: 1,
      });
    }
  }
  return filterList;
};

export { getFiltersFromListAndFacets };

/** The function getFiltersFromListAndFacets returns the following data structure
 [
  {
    facet:  'providerType',
    options: [
      {
        label: 'optometrist',
        count: 1,
      },
      {
        label: 'ob/gyn',
        count: 1,
      }
    ]
  },
  {
    facet:  'competency',
    options: [
      {
        label: 'africanAmericanCulture',
        count: 1,
      },
      {
        label: 'nativeAmericanCulture',
        count: 2,
      },
      {
        label: 'latinxCulture',
        count: 1,
      }
    ]
  },
  {
    facet:  'age',
    options: [

    ]
  }
]
*/
