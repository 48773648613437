import { Alert } from "antd";

function LoginMessages({ messages, hasPassedValidation, hasSignedUp }) {
  const visibleMessages = messages.filter((message) => message.isVisible);
  if (visibleMessages.length === 0) {
    return null;
  }

  return (
    <div className="LoginMessages">
      {visibleMessages.map((message) => (
        <Alert
          className="login-error"
          key={message.id}
          message={message.text}
          type="error"
        />
      ))}
    </div>
  );
}

export default LoginMessages;
