import { useState } from "react";
import { Row, Col, Space, Checkbox } from "antd";

import "./InterestForm.css";
import FormElements from "../../../FormElements/FormElements";

const textInputs = [
  {
    id: 0,
    element: "inputRow",
    items: [
      {
        id: "mce-FNAME",
        element: "input",
        type: "text",
        name: "FNAME",
        placeholder: "First Name",
      },
      {
        id: "mce-LNAME",
        element: "input",
        type: "text",
        name: "LNAME",
        placeholder: "Last Name",
      },
    ],
  },
  {
    id: "mce-EMAIL",
    element: "input",
    type: "text",
    name: "EMAIL",
    placeholder: "Email Address",
    required: true,
  },
];

const checkBoxInputs = [
  {
    id: "mce-group[83224]-83224-0",
    element: "input",
    type: "checkbox",
    name: "group[83224][16]",
    label: "Sign Up",
    value: "16",
  },
  {
    id: "mce-group[83224]-83224-1",
    element: "input",
    type: "checkbox",
    name: "group[83224][1]",
    label: "I'm a Patient",
    value: "1",
  },
  {
    id: "mce-group[83224]-83224-2",
    element: "input",
    type: "checkbox",
    name: "group[83224][2]",
    label: "I'm a Provider",
    value: "2",
  },
  {
    id: "mce-group[83224]-83224-3",
    element: "input",
    type: "checkbox",
    name: "group[83224][4]",
    label: "General Interest",
    value: "4",
  },
  {
    id: "mce-group[83224]-83224-4",
    element: "input",
    type: "checkbox",
    name: "group[83224][8]",
    label: "I'm a Commercial Partner",
    value: "8",
  },
];

function InterestForm(props) {
  const [inputValues, setInputValues] = useState({});

  function onChangeEvent(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    setInputValues({
      ...inputValues,
      [name]: value,
    });
  }

  return (
    <div id="mc_embed_signup" className="interest-form">
      <form
        action="https://jubily.us2.list-manage.com/subscribe/post?u=06ebcfec9e940bc22d31847da&amp;id=d16faf7410"
        method="post"
        id="mc-embedded-subscribe-form"
        name="mc-embedded-subscribe-form"
        target="_blank"
        className="validate"
        rel="noreferrer"
        noValidate
      >
        <div id="mc_embed_signup_scroll">
          <FormElements
            formItems={textInputs}
            handleChangeEvent={onChangeEvent}
            inputValues={inputValues}
          />
          <div className="mc-field-group input-group">
            <strong>Interest </strong>
            <Row>
              {checkBoxInputs.map((item) => (
                <Col key={item.id} span={24} md={12}>
                  <Space>
                    <Checkbox
                      value={item.value}
                      name={item.name}
                      id={item.id}
                      onChange={onChangeEvent}
                      checked={inputValues[item.name]}
                    />
                    <label htmlFor={item.id}>{item.label}</label>
                  </Space>
                </Col>
              ))}
            </Row>
          </div>
          <div id="mce-responses" className="clear">
            <div
              className="response"
              id="mce-error-response"
              style={{ display: "none" }}
            ></div>
            <div
              className="response"
              id="mce-success-response"
              style={{ display: "none" }}
            ></div>
          </div>
          {/*real people should not fill this in and expect good things - do not remove this or risk form bot signups*/}
          <div
            style={{ position: "absolute", left: "-5000px" }}
            aria-hidden="true"
          >
            <input
              type="text"
              name="b_06ebcfec9e940bc22d31847da_d16faf7410"
              tabIndex="-1"
              value=""
              onChange={onChangeEvent}
            />
          </div>
          <div className="clear">
            <input
              type="submit"
              value="Join the Waitlist"
              name="subscribe"
              id="mc-embedded-subscribe"
              className="button"
            />
          </div>
        </div>
      </form>
    </div>
  );
}

export default InterestForm;
