import "../Home/Home.css";
import "./Team.css";
import {
  cyrell,
  eric,
  rodney,
  gabriela,
  paterson,
  osana,
  daniel,
  yves,
  noData,
} from "../../assets/team";

const managementTeam = [
  {
    image: cyrell,
    name: "Cyrell Roberson, PhD Candidate",
    description1: "Founder/CEO",
    description2: `Cyrell is currently a doctoral candidate at UC Berkeley in Educational Psychology.
      He currently works as a psychologist at a Bay Area Pre-k to 8th grade school, as well as at a mental health clinic.
      He has experience working with diverse populations in different settings.
      Cyrell earned a BS degree in Psychology with a Minor in Sales and Marketing.`,
    className: "align-self__center",
  },
  {
    image: eric,
    name: "Eric Holt, MBA, CBO",
    description1: "Marketing, Operations & Business Development Lead",
    description2: `Eric has experience working at his family’s wellness startup.
      He earned a BS in Economics, as well as an MBA.
      He is passionate about using creative ways to leverage technology to better serve underserved populations.`,
    className: "align-self__center",
  },
  {
    image: noData,
    name: "CTO",
    description2: `Open Position for someone from our Tech Team or an external candidate. This will be filled in the near future.`,
  },
];

const advisers = [
  {
    image: rodney,
    name: "Rodney Witcher",
    description1: "Tech Startup Executive",
    description2: "SVP - WOLK",
  },
  {
    image: gabriela,
    name: "Dr. Gabriela Bronson-Castain",
    description1: "Licensed Psychologist - Summit Center",
    description2: "Formal Clinical Director - Children’s Hospital",
  },
  {
    image: paterson,
    name: "Paterson Rene, PA-C",
    description1: "Physician Assistant at a Community Clinic",
  },
  {
    image: noData,
    name: "MD/DO",
    description1: "Open - Physician",
  },
];

const techTeam = [
  {
    image: osana,
    name: "Osana Osaretin",
    description: "Tech Lead",
  },
  {
    image: daniel,
    name: "Daniel Gonzalez",
    description: "iOS App Developer",
  },
  {
    image: noData,
    name: "Open",
    description: "UI/UX Designer",
  },
  {
    image: noData,
    name: "Open",
    description: "Full Stack Developer",
  },
];

const researchTeam = [
  {
    image: yves,
    name: "Yves Young, MPH",
    description: "Research Lead",
  },
  {
    image: noData,
    name: "Open",
    description: "Data Scientist",
  },
];

function Team(props) {
  return (
    <div className="page__container team__container">
      <div className="team__content">
        <h1>Meet the Team</h1>

        <div className="team__content-row">
          <h2>Management Team</h2>
          <div className="flex flex__wrap justify-items__space-evenly flex-grid-3">
            {managementTeam.map(
              (
                {
                  image,
                  name,
                  description,
                  description1,
                  description2,
                  ...otherProps
                },
                index
              ) => (
                <div key={index} className="member flex--column">
                  <img src={image} alt={name} {...otherProps} />
                  <h3>{name}</h3>
                  {description1 && <p>{description1}</p>}
                  {description2 && <p>{description2}</p>}
                </div>
              )
            )}
          </div>
        </div>

        <div className="team__content-row">
          <h2>Advisers</h2>
          <div className="flex flex__wrap justify-items__space-evenly">
            {advisers.map(
              ({ image, name, description1, description2 }, index) => (
                <div key={index} className="member">
                  <img alt={name} src={image} />
                  <h3>{name}</h3>
                  <p>{description1}</p>
                  <p>{description2}</p>
                </div>
              )
            )}
          </div>
        </div>

        <div className="team__content-row">
          <h2>Tech Team</h2>
          <div className="flex flex__wrap justify-items__space-evenly">
            {techTeam.map(({ image, name, description }, index) => (
              <div key={index} className="member">
                <img src={image} alt={name} />
                <h3>{name}</h3>
                <p>{description}</p>
              </div>
            ))}
          </div>
        </div>

        <div className="team__content-row">
          <h2>Research & Development Team</h2>
          <div className="flex justify-items__space-around flex-grid-2">
            {researchTeam.map(({ image, name, description }, index) => (
              <div key={index} className="member">
                <img src={image} alt={name} />
                <h3>{name}</h3>
                <p>{description}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Team;
