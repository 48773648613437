import { Link } from "react-router-dom";
import "./Footer.css";

function Footer() {
  return (
    <footer className="Footer">
      <div className="flex justify-items__space-between footer__content">
        <div className="footer__section">
          <Link to="/about">About Jubily</Link>
          <span className="footer__divider">|</span>
          <Link to="/team">Team</Link>
          <span className="footer__divider">|</span>
          <Link to="/terms-of-use">Terms of Use</Link>
          <span className="footer__divider">|</span>
          <Link to="/privacy-policy">Privacy Policy</Link>
        </div>

        <div className="footer__section">
          <a href="mailto: teamjubily@gmail.com">Contact us</a>
          <span className="footer__divider">|</span>
          <a
            href="https://www.linkedin.com/company/jubily-co/"
            target="_blank"
            rel="noreferrer"
          >
            Follow us on LinkedIn
          </a>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
