import "./FormElements.css";
import {
  Input,
  DatePicker,
  Row,
  Col,
  Select,
  Checkbox,
  Divider,
  Typography,
  Radio,
  Switch,
  InputNumber,
} from "antd";
import {
  EyeInvisibleOutlined,
  EyeTwoTone,
  InboxOutlined,
  FileImageOutlined,
  DeleteOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import UserImage from "../Profile/UserImage";
import { useState, useRef } from "react";
import moment from "moment";

function FormElements({
  formItems,
  handleChangeEvent,
  handleChangeData,
  handleFileUpload,
  handleFileDelete,
  inputValues,
  inputStatus = {},
  onPressEnter = () => {},
  isShowingFormItemIndex,
}) {
  return (
    <div className="FormElements">
      {formItems.map((formItem, formItemIndex) => (
        <div
          key={formItem.id}
          className={`FormElements-element-${formItem.element}`}
        >
          <FormElement
            {...formItem}
            formItemIndex={formItemIndex}
            handleChangeEvent={handleChangeEvent}
            handleChangeData={handleChangeData}
            handleFileUpload={handleFileUpload}
            handleFileDelete={handleFileDelete}
            inputValues={inputValues}
            inputStatus={inputStatus}
            onPressEnter={onPressEnter}
            isShowingFormItemIndex={isShowingFormItemIndex}
          />
        </div>
      ))}
    </div>
  );
}

function FormElement({ element, ...elementData }) {
  switch (element) {
    case "input":
      return <InputElement {...elementData} />;
    case "textarea":
      return <TextAreaElement {...elementData} />;
    case "checkboxGroup":
      return <CheckboxGroupElement {...elementData} />;
    case "inputRow":
      return <InputRowElement {...elementData} />;
    case "datePicker":
      return <DatePickerElement {...elementData} />;
    case "uploadDragger":
      return <UploadDraggerElement {...elementData} />;
    case "selectTags":
      return <SelectTagsElement {...elementData} />;
    case "radio":
      return <RadioElement {...elementData} />;
    case "switch":
      return <SwitchElement {...elementData} />;
    default:
      return null;
  }
}

function InputElement({
  name,
  placeholder,
  inputValues,
  required,
  inputStatus,
  ...inputProps
}) {
  return (
    <FloatingLabel
      name={name}
      placeholder={placeholder}
      inputValue={inputValues[name]}
      required={required}
    >
      <InputField
        {...inputProps}
        name={name}
        placeholder={placeholder}
        inputValue={inputValues[name]}
        inputStatus={inputStatus[name]?.type}
      />
    </FloatingLabel>
  );
}

function TextAreaElement({
  id,
  type,
  name,
  placeholder,
  inputValues,
  handleChangeEvent,
}) {
  return (
    <FloatingLabel
      name={name}
      placeholder={placeholder}
      inputValue={inputValues[name]}
      containerClassNames={`FormElements-element ${type}`}
    >
      <Input.TextArea
        id={id}
        name={name}
        onChange={handleChangeEvent}
        value={inputValues[name]}
      ></Input.TextArea>
    </FloatingLabel>
  );
}

function CheckboxGroupElement({
  label,
  name,
  items,
  handleChangeData,
  inputValues,
}) {
  return (
    <>
      {label && (
        <Divider orientation="left" className="FormElements-section-label">
          {label}
        </Divider>
      )}
      <Checkbox.Group
        onChange={(value) => handleChangeData({ name, value })}
        value={inputValues[name]}
      >
        <Row>
          {items.map((item) => (
            <Col key={item.id} span={24} md={12}>
              <Checkbox name={item.name} value={item.value}>
                {item.label}
              </Checkbox>
            </Col>
          ))}
        </Row>
      </Checkbox.Group>
    </>
  );
}

function InputRowElement({ items, ...elementData }) {
  return (
    <Row align="middle" gutter={16}>
      {items.map((item, index) => (
        <Col
          key={item.id}
          span={24 / items.length}
          className={`FormElements-element ${item.type}`}
        >
          <FormElement {...elementData} {...item} />
        </Col>
      ))}
    </Row>
  );
}

function DatePickerElement({
  id,
  type,
  name,
  placeholder,
  inputValues,
  handleChangeData,
  inputStatus,
}) {
  const [isElementFocused, setIsElementFocused] = useState({});
  return (
    <FloatingLabel
      name={name}
      placeholder={placeholder}
      inputValue={inputValues[name]}
      containerClassNames={`FormElements-element ${type} FormElements-elements-container`}
    >
      <DatePicker
        size="large"
        id={id}
        name={name}
        onChange={(momentDate, dateString) => {
          setIsElementFocused({ [id]: false });
          return handleChangeData({ name, value: dateString });
        }}
        format={`MM/DD/YYYY`}
        onFocus={() => setIsElementFocused({ [id]: true })}
        onBlur={() => setIsElementFocused({ [id]: false })}
        open={isElementFocused[id]}
        showToday={false}
        value={
          inputValues[name] ? moment(inputValues[name], `MM-DD-YYYY`) : null
        }
        status={inputStatus[name]?.type}
        placeholder=""
      ></DatePicker>
    </FloatingLabel>
  );
}

function UploadDraggerElement({
  id,
  name,
  accept,
  text,
  hint,
  subHint,
  handleFileDelete,
  inputValues,
  inputStatus,
  handleFileUpload,
}) {
  const uploadDraggerElement = useRef(null);
  const elementId = `uploader-${id}`;

  function cancelEventDefaults(e) {
    e.preventDefault();
    e.stopPropagation();
  }

  function showImage() {
    if (inputValues[name]) {
      return (
        <div className="upload-image-final">
          <div
            className="upload-image-final__icon"
            onClick={(e) => handleFileDelete(e, name)}
          >
            <DeleteOutlined />
          </div>
          <UserImage
            className="upload-image-final__image"
            fileName={inputValues[name]}
          />
        </div>
      );
    } else if (
      inputStatus[name]?.type === "loading" ||
      inputStatus[name]?.type === "progress"
    ) {
      return (
        <div className="upload-dragger-image-placeholder">
          <LoadingOutlined className="upload-dragger-image-icon--loading" />
          <div>Uploading...</div>
        </div>
      );
    } else {
      return (
        <div className="upload-dragger-image-placeholder">
          <FileImageOutlined className="upload-dragger-image-icon--placeholder" />
          <div>Image Preview</div>
        </div>
      );
    }
  }

  return (
    <div className="FormElements-elements-container">
      <input
        type="file"
        accept={accept}
        id={elementId}
        className="upload-dragger-input-element"
        ref={uploadDraggerElement}
        onChange={(e) => handleFileUpload(e, name, accept)}
      />
      <div className="upload-dragger-container">
        <div
          onDrop={(e) => {
            cancelEventDefaults(e);
            handleFileUpload(e, name, accept);
          }}
          onDragOver={(e) => {
            cancelEventDefaults(e);
          }}
          onDragEnter={(e) => {
            cancelEventDefaults(e);
          }}
          onDragLeave={(e) => {
            cancelEventDefaults(e);
          }}
          className="upload-dragger-container"
        >
          <label
            tabIndex={0}
            className="upload-dragger-label"
            htmlFor={elementId}
            onKeyDown={(e) => {
              //'Enter' key also triggers upload dialog
              if (e.code === 13 || e.which === 13) {
                uploadDraggerElement.current.click();
              }
            }}
          >
            <span className="upload-dragger-icon">
              <InboxOutlined />
            </span>
            <span className="upload-dragger-title">{text}</span>
            <span className="upload-dragger-subtitle">{hint}</span>
            <span className="upload-dragger-subtitle">{subHint}</span>
          </label>
        </div>
        <div className="upload-dragger-image">{showImage()}</div>
      </div>
      {inputStatus[name]?.type === "error" ? (
        <Typography.Text type="danger" className="upload-dragger-error-message">
          {" "}
          {inputStatus[name].message}{" "}
        </Typography.Text>
      ) : null}
    </div>
  );
}

function SelectTagsElement({
  id,
  type,
  name,
  placeholder,
  tags,
  mode = "tags",
  inputValues,
  handleChangeData,
  inputStatus,
}) {
  const [isElementFocused, setIsElementFocused] = useState({});
  return (
    <FloatingLabel
      name={name}
      placeholder={placeholder}
      inputValue={inputValues[name]}
      containerClassNames={`FormElements-element ${type}`}
    >
      <Select
        mode={mode}
        size="large"
        className="FormElements-elements-container"
        onChange={(value) => handleChangeData({ name, value })}
        onFocus={() => setIsElementFocused({ [id]: true })}
        onBlur={() => setIsElementFocused({ [id]: false })}
        open={isElementFocused[id]}
        tokenSeparators={[","]}
        value={inputValues[name] ?? []}
        status={inputStatus[name]?.type}
      >
        {tags.map((tag) => (
          <Select.Option key={tag.id} value={tag.text}>
            {tag.text}
          </Select.Option>
        ))}
      </Select>
    </FloatingLabel>
  );
}

function RadioElement({
  name,
  label,
  items,
  formItemIndex,
  isShowingFormItemIndex,
  handleChangeEvent,
  inputValues,
}) {
  return (
    <>
      <label className="FormElements-radio-label">
        {isShowingFormItemIndex && `${formItemIndex + 1})`} {label}
      </label>
      <Radio.Group
        name={name}
        onChange={handleChangeEvent}
        value={inputValues[name]}
      >
        {items.map((item, index) => (
          <Radio key={index} value={item}>
            {item}
          </Radio>
        ))}
      </Radio.Group>
    </>
  );
}

function SwitchElement({ label, name, inputValues, handleChangeData }) {
  return (
    <>
      <label className="FormElements-switch-label">{label}</label>
      <Switch
        checkedChildren="Yes"
        unCheckedChildren="No"
        onChange={(value) => handleChangeData({ name, value })}
        checked={inputValues[name]}
      />
    </>
  );
}

function FloatingLabel({
  name,
  placeholder,
  inputValue,
  containerClassNames,
  required,
  children,
}) {
  const [focus, setFocus] = useState(false);
  const isInputOccupied = focus || (inputValue && inputValue?.length !== 0);
  return (
    <div className={`FormElements-elements-container ${containerClassNames}`}>
      <div
        className="float-label"
        onBlur={() => setFocus(false)}
        onFocus={() => setFocus(true)}
      >
        {children}
        <label
          htmlFor={name}
          className={`label ${isInputOccupied ? "above" : "inline"}`}
        >
          {placeholder} {required && <span className="required">*</span>}
        </label>
      </div>
    </div>
  );
}

function InputField({
  id,
  type,
  name,
  prefix,
  handleChangeEvent,
  onPressEnter,
  handleChangeData,
  inputValue,
  inputStatus,
}) {
  switch (type) {
    case "password":
      return (
        <Input.Password
          size="large"
          type={type}
          id={id}
          name={name}
          onChange={handleChangeEvent}
          iconRender={(visible) =>
            visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
          }
          onPressEnter={onPressEnter}
        />
      );
    case "number":
      return (
        <InputNumber
          size="large"
          prefix={prefix}
          controls={false}
          type={type}
          id={id}
          name={name}
          onChange={(value) => handleChangeData({ name, value })}
          value={inputValue}
          onPressEnter={onPressEnter}
        />
      );
    default:
      return (
        <Input
          size="large"
          type={type}
          id={id}
          name={name}
          onChange={handleChangeEvent}
          value={inputValue}
          onPressEnter={onPressEnter}
          status={inputStatus}
        />
      );
  }
}
export default FormElements;
