import image1 from "../../assets/woman-sitting.jpg";
import image2 from "../../assets/group-therapy.jpg";
import image3 from "../../assets/couple-and-provider.jpg";
import ImageTextBlock from "./components/ImageTextBlock";

function MissionBlocks() {
  return (
    <>
      <ImageTextBlock
        left
        positionRight
        gradientDownToLight
        title="Your home for all your culturally responsive healthcare needs."
        content="We provide interactive culturally responsive healthcare training modules for health systems, university training programs, hospitals, clinics, and private practices. Providers receive access to job posts and an online community of other providers for advice and referrals."
        image={image1}
        imageAltTest="Team of providers"
      />
      <ImageTextBlock
        gradientToLeftToLight
        title="Social support systems are crucial to effective treatment."
        content="Jubily provides a place where people who struggle with different illnesses (e.g. cancer, anxiety, depression etc.) can go to connect with an online community of support with other people who are or have gone through similar experiences to help them cope."
        image={image2}
        imageAltTest="Team of providers"
      />
      <ImageTextBlock
        left
        gradientUpToLight
        positionRight
        title="We democratize the referral process."
        content="Jubily democratizes the referral process by leveraging technology using our research-based algorithm to connect patients and clinicians based on important search criteria that have been linked to effective and efficacious treatment in medical and mental health research."
        image={image3}
        imageAltTest="Team of providers"
      />
    </>
  );
}

export default MissionBlocks;
