import heroImage from "../../assets/couple-and-provider-2.jpg";
import phoneImage from "../../assets/phone-screenshot.png";
import ImageTextBlock from "./components/ImageTextBlock";
import { Link } from "react-router-dom";

function Hero() {
  return (
    <>
      <ImageTextBlock
        titleSize="large"
        title="Finally, healthcare providers who understand you."
        content="We simplify the process of training and finding culturally responsive healthcare providers in medicine and mental health."
        Button={
          <Link to="/signup">
            <button>Join Now</button>
          </Link>
        }
        image={heroImage}
        imageAltTest="Team of providers"
        positionRelative
      >
        <img
          src={phoneImage}
          className="phone-image"
          alt="Screenshot of Jubily web app on phone"
        />
      </ImageTextBlock>
    </>
  );
}

export default Hero;
