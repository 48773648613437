import { Row, Col, Checkbox, Slider, Select } from "antd";

function FilterList({
  filterList,
  facetInfo,
  FACET_NAME_MAP,
  handleChange,
  inputValues,
  translateLabel,
}) {
  return filterList.map((filter, index) => (
    <div key={index}>
      <SelectFilterDisplay
        filter={filter}
        facetInfo={facetInfo}
        handleChange={handleChange}
        FACET_NAME_MAP={FACET_NAME_MAP}
        inputValues={inputValues}
        translateLabel={translateLabel}
      />
    </div>
  ));
}

function SelectFilterDisplay({
  filter,
  facetInfo,
  handleChange,
  inputValues,
  FACET_NAME_MAP,
  translateLabel,
}) {
  if (facetInfo[filter.facet].display === "checkbox") {
    return (
      <CheckBoxFilter
        FACET_NAME_MAP={FACET_NAME_MAP}
        filter={filter}
        handleChange={handleChange}
        inputValues={inputValues}
        translateLabel={translateLabel}
      />
    );
  } else if (facetInfo[filter.facet].display === "slider") {
    return (
      <SliderFilter
        filter={filter}
        FACET_NAME_MAP={FACET_NAME_MAP}
        handleChange={handleChange}
        inputValues={inputValues}
        translateLabel={translateLabel}
        max={facetInfo[filter.facet].max}
        min={facetInfo[filter.facet].min}
      />
    );
  } else if (facetInfo[filter.facet].display === "search") {
    return (
      <SearchFilter
        filter={filter}
        FACET_NAME_MAP={FACET_NAME_MAP}
        handleChange={handleChange}
        inputValues={inputValues}
        translateLabel={translateLabel}
      />
    );
  }
}

function SliderFilter({
  filter,
  handleChange,
  inputValues,
  FACET_NAME_MAP,
  max,
  min,
}) {
  // const [disabled, setDisabled] = useState(false);
  max = Math.max(filter.options.max ? filter.options.max : -Infinity, max);
  min = Math.min(filter.options.min ? filter.options.min : Infinity, min);
  const marks = {};
  marks[max] = `${max}`;
  marks[min] = `${min}`;
  return (
    <div>
      <div className="facet-header-slider">
        {" "}
        {FACET_NAME_MAP[filter.facet]}{" "}
      </div>
      {/*<Checkbox className="disable-slider" size="small" defaultChecked={true}*/}

      {/*          onClick={() => {*/}
      {/*              handleChange({name: filter.facet, value: []});*/}
      {/*              setDisabled(!disabled);*/}
      {/*          }}>*/}
      {/*    Include providers without this info*/}
      {/*</Checkbox>*/}
      <Slider
        onChange={(value) => handleChange({ name: filter.facet, value })}
        range={true}
        value={inputValues[filter.facet]}
        max={max}
        min={min}
        defaultValue={[min, max]}
        marks={marks}
        draggableTrack={true}
      ></Slider>
    </div>
  );
}

function CheckBoxFilter({
  filter,
  handleChange,
  inputValues,
  translateLabel,
  FACET_NAME_MAP,
}) {
  return (
    <div>
      <div className="facet-header"> {FACET_NAME_MAP[filter.facet]} </div>

      <Checkbox.Group
        onChange={(value) => handleChange({ name: filter.facet, value })}
        value={inputValues[filter.facet]}
      >
        <Row>
          {filter.options.map((option, index) => (
            <Col key={index} span={24}>
              <Checkbox
                className="filters-checkbox"
                name={option.label}
                value={option.label}
              >
                {translateLabel(option.label)}
              </Checkbox>
            </Col>
          ))}
        </Row>
      </Checkbox.Group>
    </div>
  );
}

function SearchFilter({ filter, handleChange, FACET_NAME_MAP }) {
  return (
    <div>
      <div className="facet-header"> {FACET_NAME_MAP[filter.facet]} </div>

      <Select
        mode="multiple"
        allowClear
        style={{ width: "100%", marginBottom: "20px" }}
        placeholder="Please select"
        onChange={(value) => handleChange({ name: filter.facet, value })}
      >
        {filter.options.map((option, index) => (
          <Select.Option
            key={option.label}
            name={option.label}
            value={option.label}
          >
            {option.label}
          </Select.Option>
        ))}
      </Select>
    </div>
  );
}

export default FilterList;
