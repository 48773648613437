function ImageTextBlock({
  left = false,
  gradientDownToLight = false,
  gradientUpToLight = false,
  gradientToLeftToLight = false,
  positionRight = false,
  positionRelative = false,
  titleSize = "medium",
  title = "",
  content = "",
  Button = <></>,
  image = null,
  imageAltTest = "",
  children,
}) {
  return (
    <>
      {
        <section
          className={`homepage__section section--image-text-block flex ${
            left ? "flex--row-reverse image-left" : "image-right"
          } ${gradientDownToLight ? "gradient-down-light" : ""} ${
            gradientUpToLight ? "gradient-up-light" : ""
          } ${
            gradientToLeftToLight ? "gradient-left-light" : ""
          } align-items__center  ${
            positionRelative ? "position-relative" : ""
          } `}
        >
          <div className="homepage__section-text">
            {titleSize === "large" ? (
              <h1 className="large-title-text">{title}</h1>
            ) : (
              <h2 className="large-title-text">{title}</h2>
            )}
            <p>{content}</p>
            {Button}
          </div>
          <img
            src={image}
            className={`homepage__section-image ${
              positionRight ? "position-right" : ""
            }`}
            alt={imageAltTest}
            loading="lazy"
          />
          {children}
        </section>
      }
    </>
  );
}

export default ImageTextBlock;
