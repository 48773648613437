import { API, graphqlOperation } from "aws-amplify";
import * as queries from "../graphql/queries";
import * as mutations from "../graphql/mutations";
import * as subscriptions from "../graphql/subscriptions";

let onUpdateGroupByGroupIdAndMemberUserIdSubscription = {};
let onUpdateGroupByMemberUserIdSubscription = {};
let onCreateMessageByGroupIdSubscription = {};

export const getUserMessagingGroups = async (userID) => {
  const userQueryResult = await API.graphql({
    query: queries.getUser,
    variables: { id: userID },
  });
  const currentUserGroupMemberships =
    userQueryResult.data.getUser.groups?.items || [];
  const memberedGroupIDs =
    currentUserGroupMemberships.map(({ groupID }) => groupID) || [];
  const listGroupMembersResult = await API.graphql({
    query: queries.listGroupMembers,
    variables: { or: memberedGroupIDs },
  });
  const allGroupsMembers = listGroupMembersResult.data.listGroupMembers.items;
  const groupsWithMembers = currentUserGroupMemberships.map(
    (groupMembership) => {
      const members = allGroupsMembers.filter(
        (groupsMember) => groupsMember.groupID === groupMembership.groupID
      );
      const groupData = {
        ...members[0].group,
        unreadCount:
          members.find((member) => member.memberUserID)?.unreadCount || 0,
        members: {
          items: members.map(({ member, id }) => ({
            ...member,
            groupMemberID: id,
          })),
        },
      };
      return groupData;
    }
  );

  await Promise.all(
    groupsWithMembers.map(async (group) => {
      if (group.lastMessageID) {
        const lastMessage = await API.graphql({
          query: queries.getMessage,
          variables: { id: group.lastMessageID },
        });
        group.lastMessage = lastMessage.data.getMessage;
      }
    })
  );

  return groupsWithMembers || [];
};

export const getTotalMessagingUnreadCount = async (userID) => {
  const listGroupMembersResult = await API.graphql({
    query: queries.listGroupMembers,
    variables: { filter: { memberUserID: { eq: userID } } },
  });
  const totalUnreadCount =
    listGroupMembersResult.data.listGroupMembers.items.reduce(
      ({ unreadCount: prev = 0 }, { unreadCount: next = 0 }) => prev + next,
      0
    );
  return totalUnreadCount;
};

export const listMessages = async (groupID) => {
  const listMessagesResponse = await API.graphql(
    graphqlOperation(queries.messagesByGroupID, {
      groupID,
      sortDirection: "ASC",
    })
  );
  return listMessagesResponse.data.messagesByGroupID.items;
};

export const createGroupWithUsers = async ({ creatorID, memberIDs }) => {
  const createGroupResponse = await API.graphql(
    graphqlOperation(mutations.createGroup, { input: { creatorID } })
  );
  const groupID = createGroupResponse.data.createGroup.id;
  const createMembersPromises = [creatorID, ...memberIDs].map((memberUserID) =>
    API.graphql(
      graphqlOperation(mutations.createGroupMember, {
        input: { groupID, memberUserID },
      })
    )
  );
  await Promise.all(createMembersPromises);
  return groupID;
};

export const addMessage = async (message) => {
  try {
    const createMessageResponse = await API.graphql(
      graphqlOperation(mutations.createMessage, { input: message })
    );
    const createdMessage = createMessageResponse.data.createMessage;
    API.graphql(
      graphqlOperation(mutations.updateGroup, {
        input: { id: message.groupID, lastMessageID: createdMessage.id },
      })
    );
    return createdMessage;
  } catch (e) {
    throw e;
  }
};

export const updateGroupMemberUnreadCounts = async (
  messagingGroup,
  currentGroupMemberId
) => {
  const { members } = messagingGroup;
  members.items
    .filter(({ groupMemberID }) => groupMemberID !== currentGroupMemberId)
    .forEach(async ({ groupMemberID }) => {
      const getGroupMemberResponse = await API.graphql(
        graphqlOperation(queries.getGroupMember, { id: groupMemberID })
      );
      const currentGroupMember = getGroupMemberResponse.data.getGroupMember;
      const unreadCount = Number.isInteger(currentGroupMember.unreadCount)
        ? ++currentGroupMember.unreadCount
        : 1;
      API.graphql(
        graphqlOperation(mutations.updateGroupMember, {
          input: {
            id: groupMemberID,
            unreadCount,
          },
        })
      );
    });
};

export const clearUnreadCount = (groupMemberID) => {
  try {
    API.graphql(
      graphqlOperation(mutations.updateGroupMember, {
        input: {
          id: groupMemberID,
          unreadCount: 0,
        },
      })
    );
  } catch (e) {
    console.log(e);
  }
};

export const onUpdateGroupMemberByGroupIdAndMemberUserId = (
  groupID,
  memberUserID,
  callbackFn
) => {
  onUpdateGroupByGroupIdAndMemberUserIdSubscription = API.graphql(
    graphqlOperation(
      subscriptions.onUpdateGroupMemberByGroupIdAndMemberUserId,
      { groupID, memberUserID }
    )
  ).subscribe({
    next: ({ value }) =>
      callbackFn(value.data.onUpdateGroupMemberByGroupIdAndMemberUserId),
    error: (error) => console.warn(error),
  });
};

export const onUpdateGroupMemberByMemberUserId = (memberUserID, callbackFn) => {
  onUpdateGroupByMemberUserIdSubscription = API.graphql(
    graphqlOperation(subscriptions.onUpdateGroupMemberByMemberUserId, {
      memberUserID,
    })
  ).subscribe({
    next: ({ value }) =>
      callbackFn(value.data.onUpdateGroupMemberByGroupIdAndMemberUserId),
    error: (error) => console.warn(error),
  });
};

export const onCreateMessageByGroupId = (groupID, callbackFn) => {
  onCreateMessageByGroupIdSubscription = API.graphql(
    graphqlOperation(subscriptions.onCreateMessageByGroupId, { groupID })
  ).subscribe({
    next: ({ value }) => callbackFn(value.data.onCreateMessageByGroupId),
    error: (error) => console.warn(error),
  });
};

export const getGroupIdWithUsers = async (
  currentUserID,
  memberUserIDs = []
) => {
  let foundGroupId = null;
  const groups = await getUserMessagingGroups(currentUserID);
  const filteredGroups = groups.filter(
    (group) => group.members.items.length === memberUserIDs.length
  );
  foundGroupId = filteredGroups.find((group) => {
    const groupMemberUserIDs = group.members.items.map((item) => item.id);
    return memberUserIDs.every((userID) => groupMemberUserIDs.includes(userID));
  })?.id;

  return foundGroupId;
};

export const removeOnUpdateGroupByGroupIdAndMemberUserIdSubscriptionListener =
  () => {
    onUpdateGroupByGroupIdAndMemberUserIdSubscription.unsubscribe();
  };

export const removeOnUpdateGroupByMemberUserIdSubscriptionListener = () => {
  onUpdateGroupByMemberUserIdSubscription.unsubscribe();
};

export const removeOnCreateMessageByGroupIdSubscriptionListener = () => {
  onCreateMessageByGroupIdSubscription.unsubscribe();
};
