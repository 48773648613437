import "./Home.css";
import Hero from "./Hero";
import ValuePropsBlock from "./ValuePropsBlock";
import MissionBlocks from "./MissionBlocks";
import HomepageFooter from "./HomepageFooter";

function Home() {
  return (
    <div className="page__container homepage__container">
      <Hero />
      <ValuePropsBlock />
      <MissionBlocks />
      <HomepageFooter />
    </div>
  );
}
export default Home;
