import { useEffect, useState, useContext, useCallback } from "react";
import { useParams } from "react-router-dom";
import { getUserMessagingGroups } from "../../services/messaging-service";
import { Messages } from "./Messages/Messages";
import { Link } from "react-router-dom";
import { Result, Button } from "antd";
import { FolderOpenTwoTone } from "@ant-design/icons";
import GroupList from "./GroupList/GroupList";
import { UserContext } from "../../contexts/UserContext";
import "./Messaging.css";

const Messaging = () => {
  const { profile } = useContext(UserContext);
  const { groupId: pathParamGroupId } = useParams();
  const [groups, setGroups] = useState();
  const [selectedGroup, setSelectedGroup] = useState();
  const [isShowingMessagesOnMobile, setIsShowingMessagesOnMobile] =
    useState(false);
  const [isViewLoaded, setIsViewLoaded] = useState(false);

  const getInitialGroup = useCallback(
    (groupsList) =>
      groupsList?.find(({ id }) => id === pathParamGroupId) || groupsList[0],
    [pathParamGroupId]
  );

  useEffect(() => {
    async function init() {
      const groupsList = await getUserMessagingGroups(profile.userID);
      setGroups(groupsList);
      //Navigate to that group if a valid group id was provided
      const initialGroup = getInitialGroup(groupsList);
      setSelectedGroup(initialGroup);
      setIsViewLoaded(true);
    }
    init();
  }, [profile.userID, getInitialGroup]);

  function onClickGroup(groupId) {
    setSelectedGroup(groupId);
    setIsShowingMessagesOnMobile(true);
  }

  function onClickBack() {
    setIsShowingMessagesOnMobile(false);
  }

  return (
    <>
      <div className="Messaging">
        {isViewLoaded && selectedGroup && (
          <>
            <div
              className={`GroupList-container ${
                isShowingMessagesOnMobile ? "" : "is-visible"
              }`}
            >
              <GroupList
                userID={profile.userID}
                setSelectedGroup={onClickGroup}
                selectedGroup={selectedGroup}
                groups={groups}
              />
            </div>
            <div
              className={`Messages-container ${
                isShowingMessagesOnMobile ? "is-visible" : ""
              }`}
            >
              <Messages
                userID={profile.userID}
                selectedGroup={selectedGroup}
                onClickBack={onClickBack}
              />
            </div>
          </>
        )}
        {isViewLoaded && !selectedGroup && (
          <div className="no-messages">
            <Result
              icon={<FolderOpenTwoTone />}
              title="No messages yet."
              subTitle="When you have messages with providers, they will show up here."
              extra={
                <Button type="primary">
                  <Link to="/search">Message providers.</Link>
                </Button>
              }
            />
          </div>
        )}
      </div>
    </>
  );
};

export default Messaging;
