import { API, graphqlOperation } from "aws-amplify";
import * as queries from "../graphql/queries";
import * as mutations from "../graphql/mutations";

export const getProfileData = async (username) => {
  const userQueryResult = await API.graphql({
    query: queries.userByAWSCognitoUsername,
    variables: { awsCognitoUsername: username },
  });
  const userData = userQueryResult.data.userByAWSCognitoUsername.items[0];
  return userData.providerProfile;
};

export const updateProfileData = async (fullProfileData) => {
  /**
   * Removing Dynamo DB variables "createdAt", "updatedAt" etc which are expected when getting the object but not when updating it.
   * Without removing these variables, an error is thrown by AppSync when using the updateProviderProfile function.
   */
  const { createdAt, updatedAt, user, userID, ...profileData } =
    fullProfileData;
  const updatedUserResponse = await API.graphql(
    graphqlOperation(mutations.updateProviderProfile, { input: profileData })
  );

  /**
   * Adding name and image data to user so it can be used independently of profile
   */
  const { title, firstName, lastName, postNominals, image, identityId } =
    profileData;
  await API.graphql(
    graphqlOperation(mutations.updateUser, {
      input: {
        id: userID,
        title,
        firstName,
        lastName,
        postNominals,
        image,
        identityId,
      },
    })
  );

  return updatedUserResponse.data.updateProviderProfile;
};

export const getProfilesData = async ({ isShowingTestAccounts = false }) => {
  const profileQueryResult = await API.graphql({
    query: queries.listProviderProfiles,
  });
  if (isShowingTestAccounts) {
    return profileQueryResult.data.listProviderProfiles.items;
  }
  return profileQueryResult.data.listProviderProfiles.items.filter(
    (item) =>
      !item.user.isTestAccount &&
      item.firstName &&
      item.lastName &&
      item.specialties?.length > 0
  );
};
