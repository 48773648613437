import { useState, useEffect } from "react";
import { Badge } from "antd";
import { MailOutlined } from "@ant-design/icons";
import { useHistory, useLocation } from "react-router-dom";
import {
  getTotalMessagingUnreadCount,
  onUpdateGroupMemberByMemberUserId,
  removeOnUpdateGroupByMemberUserIdSubscriptionListener,
} from "../../services/messaging-service";

function MessagesIcon({ profile }) {
  const history = useHistory();
  const location = useLocation();

  const Icon = () => (
    <div className="menu-icon">
      <MailOutlined />
    </div>
  );

  return (
    <div
      onClick={() => {
        history.push(`/messages`);
      }}
    >
      {location.pathname.includes(`/messages`) ? (
        <Icon />
      ) : (
        <TotalUnreadBadge profile={profile}>
          <Icon />
        </TotalUnreadBadge>
      )}
    </div>
  );
}

const TotalUnreadBadge = ({ profile, children }) => {
  const [totalUnreadCount, setTotalUnreadCount] = useState();

  useEffect(() => {
    async function init() {
      let count = await getTotalMessagingUnreadCount(profile.userID);
      setTotalUnreadCount(count);

      onUpdateGroupMemberByMemberUserId(
        profile.userID,
        async (groupMemberData) => {
          count = await getTotalMessagingUnreadCount(profile.userID);
          setTotalUnreadCount(count);
        }
      );
    }
    init();
    return () => {
      removeOnUpdateGroupByMemberUserIdSubscriptionListener();
    };
  }, [profile.userID]);

  return (
    <Badge count={totalUnreadCount} size="small">
      {children}
    </Badge>
  );
};

export default MessagesIcon;
