import InterestForm from "./components/InterestForm/InterestForm";
import nsfLogo from "../../assets/supporter-logos/nsf-logo.png";
import skydeckLogo from "../../assets/supporter-logos/berkeley-skydeck-logo.png";
import blackAmbition from "../../assets/supporter-logos/black-ambition-logo.jpg";
import blackstoneLaunchpadLogo from "../../assets/supporter-logos/blackstone-launchpad-logo.png";
import startupAtBerkeleyLawLogo from "../../assets/supporter-logos/startup-at-berkeley-law-logo.png";

function HomepageFooter() {
  return (
    <div>
      <section className="homepage__section flex align-items__center next-step">
        <div className="logos-container">
          <h2 className="large-title-text">Our Supporters</h2>
          <div>
            <div className="flex align-items__center">
              <img src={nsfLogo} className="nsf" alt="NSF Logo" />
              <img
                src={blackstoneLaunchpadLogo}
                className="blackstone"
                alt="Blackstone Launchpad Logo"
              />
            </div>
            <div className="flex align-items__center">
              <img
                src={blackAmbition}
                className="black-ambition"
                alt="Black Ambition Logo"
              />
              <img
                src={startupAtBerkeleyLawLogo}
                className="berkeley-law"
                alt="Startup @ Berkeley Law Logo"
              />
            </div>
            <div className="flex">
              <img
                src={skydeckLogo}
                className="skydeck"
                alt="Berkeley Skydeck Logo"
              />
            </div>
          </div>
        </div>
        <div className="homepage__section-text" id="jubily-interest-form">
          <div className="form-container">
            <h2 className="large-title-text">
              Revolutionize your <br /> healthcare experience.
            </h2>
            <InterestForm />
          </div>
        </div>
      </section>
    </div>
  );
}

export default HomepageFooter;
