/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-2",
    "aws_cloud_logic_custom": [
        {
            "name": "ribbonHealthApi",
            "endpoint": "https://ieu10xucn7.execute-api.us-east-2.amazonaws.com/production",
            "region": "us-east-2"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://jc23nqcmjzabld64i3difqyv5u.appsync-api.us-east-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-2",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-w5c5b7laovfqpnmcaveb72hu24",
    "aws_cognito_identity_pool_id": "us-east-2:fed7a46e-0827-400e-911f-239121596141",
    "aws_cognito_region": "us-east-2",
    "aws_user_pools_id": "us-east-2_qP8HMly8p",
    "aws_user_pools_web_client_id": "219cratsvt9j40l8ctnkk8j240",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "storagebucket195047-production",
    "aws_user_files_s3_bucket_region": "us-east-2"
};


export default awsmobile;
