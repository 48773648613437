import { nanoid } from 'nanoid';

const websiteInputKey = 'website';

export const urlProfileDataKeys = [websiteInputKey];

export const internalInputs = [
    {id: nanoid(), element: 'inputRow', items: [
        {id: nanoid(), element: 'input', type: 'text', name: 'title', placeholder: 'Title (e.g., Dr., Mr., Ms.)'},
        {id: nanoid(), element: 'input', type: 'text', name: 'firstName', placeholder: 'First Name', required: true},
        {id: nanoid(), element: 'input', type: 'text', name: 'lastName', placeholder: 'Last Name', required: true},
        {id: nanoid(), element: 'input', type: 'text', name: 'postNominals', placeholder: 'Post Nominals (e.g., RN)'}
    ]},
    {id: nanoid(), element: 'selectTags', type: 'text', name: 'pronouns', placeholder: 'Pronouns', tags: [
        {id: nanoid(), text: 'she/her/hers'},
        {id: nanoid(), text: 'he/him/his'},
        {id: nanoid(), text: 'they/them/theirs'}
    ]},
    {id: nanoid(), element: 'input', type: 'text', name: 'location', placeholder: 'City and State (e.g. \'New Orleans, LA\')'},
    {id: nanoid(), element: 'datePicker', type: 'datePicker', name: 'birthDate', placeholder: 'Birthday'},
    {id: nanoid(), element: 'uploadDragger', type: 'dragger', name: 'image', multiple: false, accept: 'image/png,image/gif,image/jpeg', placeholder: 'Photo of Yourself', text: 'Upload a profile image', hint: 'Click or drag file to this area to upload.', subHint: ' Allowed file types are .png, .gif, and .jpeg.', listType: 'picture-card', label: 'Photo of Yourself'},
    {id: nanoid(), element: 'input', type: 'text', name: websiteInputKey, placeholder: 'Website (e.g. https://www.google.com)'},
    {id: nanoid(), element: 'textarea', type: 'textarea', name: 'description', placeholder: 'Personal Bio (e.g. "I have been in private practice for 10 years and in my free time like to play tennis and sing karaoke")'},
];

export const culturalResponsiveness = [
    {name: 'africanAmericanCulture', label: 'African American Culture', value: 'African American Culture', tagColor: '#86a745'},
    {name: 'latinxCulture', label: 'Latinx Culture', value: 'Latinx Culture', tagColor: '#c7c5a9'},
    {name: 'nativeAmericanCulture', label: 'Native American Culture', value: 'Native American Culture', tagColor: '#8094a7'},
    {name: 'asianAmericanCulture', label: 'Asian American Culture', value: 'Asian American Culture', tagColor: '#8aa88e'},
    {name: 'hinduCulture', label: 'Hindu Culture', value: 'Hindu Culture', tagColor: 'geekblue'},
    {name: 'christianCulture', label: 'Christian Culture', value: 'Christian Culture',  tagColor: '#999999'},
    {name: 'muslimCulture', label: 'Muslim Culture', value: 'Muslim Culture',  tagColor: '#209496'},
    {name: 'lgtbqiaCulture', label: 'LGBTQIA+ Culture', value: 'LGBTQIA+ Culture', tagColor: '#273f74'},
    {name: 'noneSpecifiedCulture', label: 'Prefer Not to Say', value: 'Prefer Not to Say', tagColor: '#c4bf8c'},
    {name: 'otherCulture', label: 'Other', value: 'Other', tagColor: '#5c353e'},
    {name: 'noCulture', label: 'No Particular Cultures', value: 'No Particular Cultures', tagColor: '#93b584'},
]

const languages = [
    {name: 'aSLLanguage', label: 'ASL', value: 'ASL'},
    {name: 'arabicLanguage', label: 'Arabic', value: 'Arabic'},
    {name: 'armenianLanguage', label: 'Armenian', value: 'Armenian'},
    {name: 'bosnianLanguage', label: 'Bosnian', value: 'Bosnian'},
    {name: 'cantoneseLanguage', label: 'Cantonese', value: 'Cantonese'},
    {name: 'creoleLanguage', label: 'Creole', value: 'Creole'},
    {name: 'croatianLanguage', label: 'Croatian', value: 'Croatian'},
    {name: 'dutchLanguage', label: 'Dutch', value: 'Dutch'},
    {name: 'englishLanguage', label: 'English', value: 'English'},
    {name: 'farsiLanguage', label: 'Farsi', value: 'Farsi'},
    {name: 'filipinoLanguage', label: 'Filipino', value: 'Filipino'},
    {name: 'frenchLanguage', label: 'French', value: 'French'},
    {name: 'germanLanguage', label: 'German', value: 'German'},
    {name: 'greekLanguage', label: 'Greek', value: 'Greek'},
    {name: 'gujaratiLanguage', label: 'Gujarati', value: 'Gujarati'},
    {name: 'hebrewLanguage', label: 'Hebrew', value: 'Hebrew'},
    {name: 'hindiLanguage', label: 'Hindi', value: 'Hindi'},
    {name: 'hungarianLanguage', label: 'Hungarian', value: 'Hungarian'},
    {name: 'italianLanguage', label: 'Italian', value: 'Italian'},
    {name: 'japaneseLanguage', label: 'Japanese', value: 'Japanese'},
    {name: 'koreanLanguage', label: 'Korean', value: 'Korean'},
    {name: 'mandarinLanguage', label: 'Mandarin', value: 'Mandarin'},
    {name: 'polishLanguage', label: 'Polish', value: 'Polish'},
    {name: 'portugueseLanguage', label: 'Portuguese', value: 'Portuguese'},
    {name: 'punjabiLanguage', label: 'Punjabi', value: 'Punjabi'},
    {name: 'romanianLanguage', label: 'Romanian', value: 'Romanian'},
    {name: 'russianLanguage', label: 'Russian', value: 'Russian'},
    {name: 'serbianLanguage', label: 'Serbian', value: 'Serbian'},
    {name: 'sinhaleseLanguage', label: 'Sinhalese', value: 'Sinhalese'},
    {name: 'spanishLanguage', label: 'Spanish', value: 'Spanish'},
    {name: 'swedishLanguage', label: 'Swedish', value: 'Swedish'},
    {name: 'turkishLanguage', label: 'Turkish', value: 'Turkish'},
    {name: 'ukrainianLanguage', label: 'Ukrainian', value: 'Ukrainian'},
    {name: 'urduLanguage', label: 'Urdu', value: 'Urdu'},
    {name: 'vietnameseLanguage', label: 'Vietnamese', value: 'Vietnamese'},
    {name: 'yiddishLanguage', label: 'Yiddish', value: 'Yiddish'},
    {name: 'otherLanguage', label: 'Other', value: 'Other'},
]

const ageGroups = [
    {name: 'childAgeGroup', label: 'Child (0-9 years old)', value: 'Child (0-9 years old)'},
    {name: 'teenAgeGroup', label: 'Teen (10-19 years old)', value: 'Teen (10-19 years old)'},
    {name: 'youngAgeGroup', label: 'Young (20-35 years old)', value: 'Young (20-35 years old)'},
    {name: 'adultAgeGroup', label: 'Adult (35-65 years old)', value: 'Adult (35-65 years old)'},
    {name: 'olderAgeGroup', label: 'Older Adult (>65 years old)', value: 'Older Adult (>65 years old)'},
    {name: 'noAgeGroup', label: 'No Particular Age Ranges', value: 'No Particular Age Ranges'},
]

const modalities = [
    {name: 'individualModality', label: 'Individual', value: 'Individual'},
    {name: 'coupleModality', label: 'Couple', value: 'Couple'},
    {name: 'familyModality', label: 'Family', value: 'Family'},
    {name: 'groupModality', label: 'Group', value: 'Group'},
]

const alliedCommunities = [
    {name: 'bisexualAllied', label: 'Bisexual', value: 'Bisexual'},
    {name: 'bodyPositivityAllied', label: 'Body Positivity', value: 'Body Positivity'},
    {name: 'cancerAllied', label: 'Cancer', value: 'Cancer'},
    {name: 'gayAllied', label: 'Gay ', value: 'Gay'},
    {name: 'hivAidsAllied', label: 'HIV / AIDS', value: 'HIV / AIDS'},
    {name: 'intersexAllied', label: 'Intersex', value: 'Intersex'},
    {name: 'lesbianAllied', label: 'Lesbian', value: 'Lesbian'},
    {name: 'nonBinaryAllied', label: 'Non-Binary', value: 'Non-Binary'},
    {name: 'openRelationshipNonMonogamyAllied', label: 'Open Relationships / Non-Monogamy', value: 'Open Relationships / Non-Monogamy'},
    {name: 'professionalAthletesAllied', label: 'Professional Athletes', value: 'Professional Athletes'},
    {name: 'queerAllied', label: 'Queer', value: 'Queer'},
    {name: 'racialJusticeAllied', label: 'Racial Justice', value: 'Racial Justice'},
    {name: 'sexPositiveKinkAllied', label: 'Sex-Positive / Kink', value: 'Sex-Positive / Kink'},
    {name: 'sexWorkerAllied', label: 'Sex Worker', value: 'Sex Worker'},
    {name: 'transgenderAllied', label: 'Transgender', value: 'Transgender'},
    {name: 'noAllied', label: 'No Particular Communities', value: 'No Particular Communities'},
]


const treatmentOrientation = [
    {name: 'acceptanceCommitmentTherapyTreatment', label: 'Acceptance and Commitment Therapy (ACT)', value: 'Acceptance and Commitment Therapy (ACT)'},
    {name: 'adlerianTreatment', label: 'Adlerian', value: 'Adlerian'},
    {name: 'appliedBehavioralAnalysisTreatment', label: 'Applied Behavioral Analysis', value: 'Applied Behavioral Analysis'},
    {name: 'artTherapyTreatment', label: 'Art Therapy', value: 'Art Therapy'},
    {name: 'attachmentBasedTreatment', label: 'Attachment-Based', value: 'Attachment-Based'},
    {name: 'biofeedbackTreatment', label: 'Biofeedback', value: 'Biofeedback'},
    {name: 'christianCounselingTreatment', label: 'Christian Counseling', value: 'Christian Counseling'},
    {name: 'coachingTreatment', label: 'Coaching', value: 'Coaching'},
    {name: 'cognitiveBehavioralTreatment', label: 'Cognitive Behavioral (CBT)', value: 'Cognitive Behavioral (CBT)'},
    {name: 'compassionFocusedTreatment', label: 'Compassion Focused', value: 'Compassion Focused'},
    {name: 'culturallySensitiveTreatment', label: 'Culturally Sensitive', value: 'Culturally Sensitive'},
    {name: 'danceMovementTherapyTreatment', label: 'Dance/Movement Therapy', value: 'Dance/Movement Therapy'},
    {name: 'dialecticalTreatment', label: 'Dialectical (DBT)', value: 'Dialectical (DBT)'},
    {name: 'dyadicTherapyChildParentTreatment', label: 'Dyadic Therapy or Child Parent', value: 'Dyadic Therapy or Child Parent'},
    {name: 'eclecticTreatment', label: 'Eclectic', value: 'Eclectic'},
    {name: 'edmrTreatment', label: 'EMDR', value: 'EMDR'},
    {name: 'emotionallyFocusedTreatment', label: 'Emotionally Focused', value: 'Emotionally Focused'},
    {name: 'existentialTreatment', label: 'Existential', value: 'Existential'},
    {name: 'experientialTherapyTreatment', label: 'Experiential Therapy', value: 'Experiential Therapy'},
    {name: 'expressiveArtsTreatment', label: 'Expressive Arts', value: 'Expressive Arts'},
    {name: 'familyMaritalTreatment', label: 'Family / Marital', value: 'Family / Marital'},
    {name: 'familySystemsTreatment', label: 'Family Systems', value: 'Family Systems'},
    {name: 'forensicPsychologyTreatment', label: 'Forensic Psychology', value: 'Forensic Psychology'},
    {name: 'gestaltTreatment', label: 'Gestalt', value: 'Gestalt'},
    {name: 'gottmanTreatment Method', label: 'Gottman Method', value: 'Gottman Method'},
    {name: 'humanisticTreatment', label: 'Humanistic', value: 'Humanistic'},
    {name: 'hypnotherapyTreatment', label: 'Hypnotherapy', value: 'Hypnotherapy'},
    {name: 'imagoTreatment', label: 'Imago', value: 'Imago'},
    {name: 'integrativeTreatment', label: 'Integrative', value: 'Integrative'},
    {name: 'internalFamilySystemsTreatment', label: 'Internal Family Systems (IFS)', value: 'Internal Family Systems (IFS)'},
    {name: 'interpersonalTreatment', label: 'Interpersonal', value: 'Interpersonal'},
    {name: 'interventionTreatment', label: 'Intervention', value: 'Intervention'},
    {name: 'jungianTreatment', label: 'Jungian', value: 'Jungian'},
    {name: 'marriageFamilyTherapistTreatment', label: 'Marriage and Family Therapist', value: 'Marriage and Family Therapist'},
    {name: 'mindfulnessBasedTreatment', label: 'Mindfulness-Based (MBCT)', value: 'Mindfulness-Based (MBCT)'},
    {name: 'motivationalInterviewingTreatment', label: 'Motivational Interviewing', value: 'Motivational Interviewing'},
    {name: 'multiculturalTreatment', label: 'Multicultural', value: 'Multicultural'},
    {name: 'narrativeTreatment', label: 'Narrative', value: 'Narrative'},
    {name: 'neuroLinguisticTreatment', label: 'Neuro-Linguistic', value: 'Neuro-Linguistic'},
    {name: 'neurofeedbackTreatment', label: 'Neurofeedback', value: 'Neurofeedback'},
    {name: 'parentChildInteractionTherapyTreatment', label: 'Parent-Child Interaction Therapy (PCIT)', value: 'Parent-Child Interaction Therapy (PCIT)'},
    {name: 'personCenteredTreatment', label: 'Person-Centered', value: 'Person-Centered'},
    {name: 'playTherapyTreatment', label: 'Play Therapy', value: 'Play Therapy'},
    {name: 'positivePsychologyTreatment', label: 'Positive Psychology', value: 'Positive Psychology'},
    {name: 'prolongedExposureTreatment', label: 'Prolonged Exposure', value: 'Prolonged Exposure'},
    {name: 'psychoanalyticTreatment', label: 'Psychoanalytic', value: 'Psychoanalytic'},
    {name: 'psychodynamicTreatment', label: 'Psychodynamic', value: 'Psychodynamic'},
    {name: 'psychologicalTestingEvaluationTreatment', label: 'Psychological Testing and Evaluation', value: 'Psychological Testing and Evaluation'},
    {name: 'psychotherapyTreatment', label: 'Psychotherapy', value: 'Psychotherapy'},
    {name: 'rationalEmotiveBehaviorTherapyTreatment', label: 'Rational Emotive Behavior Therapy (REBT)', value: 'Rational Emotive Behavior Therapy (REBT)'},
    {name: 'realityTreatment Therapy', label: 'Reality Therapy', value: 'Reality Therapy'},
    {name: 'relationalTreatment Therapy', label: 'Relational Therapy', value: 'Relational Therapy'},
    {name: 'solutionFocusedBriefTreatment', label: 'Solution Focused Brief (SFBT)', value: 'Solution Focused Brief (SFBT)'},
    {name: 'somaticTreatment', label: 'Somatic', value: 'Somatic'},
    {name: 'strengthBasedTreatment', label: 'Strength-Based', value: 'Strength-Based'},
    {name: 'structuralFamilyTherapyTreatment', label: 'Structural Family Therapy', value: 'Structural Family Therapy'},
    {name: 'supervisionServicesTreatment', label: 'Supervision Services', value: 'Supervision Services'},
    {name: 'transpersonalTraumaFocusedTherapyTreatment', label: 'Transpersonal Trauma-Focused Therapy', value: 'Transpersonal Trauma-Focused Therapy'},
    {name: 'noTreatment', label: 'No Particular Treatment Orientation', value: 'No Particular Treatment Orientation'},
]


const faithOrientation = [
    {name: 'agnosticFaith', label: 'Agnostic', value: 'Agnostic'},
    {name: 'atheistFaith', label: 'Atheist', value: 'Atheist'},
    {name: 'budhistFaith', label: 'Budhist', value: 'Budhist'},
    {name: 'catholicFaith', label: 'Catholic', value: 'Catholic'},
    {name: 'christianFaith', label: 'Christian', value: 'Christian'},
    {name: 'hinduFaith', label: 'Hindu', value: 'Hindu'},
    {name: 'jainFaith', label: 'Jain', value: 'Jain'},
    {name: 'jewishFaith', label: 'Jewish', value: 'Jewish'},
    {name: 'mormonFaith', label: 'Mormon', value: 'Mormon'},
    {name: 'sikhFaith', label: 'Sikh', value: 'Sikh'},
    {name: 'zoroastrianFaith', label: 'Zoroastrian', value: 'Zoroastrian'},
    {name: 'spiritualFaith', label: 'Spiritual', value: 'Spiritual'},
    {name: 'otherFaith', label: 'Other', value: 'Other'},
    {name: 'noFaith', label: 'No Particular Faiths', value: 'No Particular Faiths'},
]

export const professionalSpecialtiesName = 'specialties';

export const mentalHealthSpecialityLabel = 'Therapy / Counseling';

export const schoolPsyhcologistSpecialtyLabel = 'School Psychologist';

export const psyhcologistSpecialtyLabel = 'Psychologist';

export const neuropsychologistSpecialtyLabel = 'Neuropsychologist';

const professionalSpecialties = [
  {name: 'allergyAndImmunologySpeciality', label: 'Allergy and Immunology', value: 'Allergy and Immunology'},
  {name: 'anesthesiologySpeciality', label: 'Anesthesiology', value: 'Anesthesiology'},
  {name: 'dermatologySpeciality', label: 'Dermatology', value: 'Dermatology'},
  {name: 'dentistSpeciality', label: 'Dentist', value: 'Dentist'},
  {name: 'diagnosticRadiologySpeciality', label: 'Diagnostic Radiology', value: 'Diagnostic Radiology'},
  {name: 'emergencyMedicineSpeciality', label: 'Emergency Medicine', value: 'Emergency Medicine'},
  {name: 'familyMedicineSpeciality', label: 'Family Medicine', value: 'Family Medicine'},
  {name: 'internalMedicineSpeciality', label: 'Internal Medicine', value: 'Internal Medicine'},
  {name: 'mentalHealthSpeciality', label: mentalHealthSpecialityLabel, value: mentalHealthSpecialityLabel},
  {name: 'medicalGeneticsSpeciality', label: 'Medical Genetics', value: 'Medical Genetics'},
  {name: 'neurologySpeciality', label: 'Neurology', value: 'Neurology'},
  {name: 'neuropsychologistSpeciality', label: neuropsychologistSpecialtyLabel, value: neuropsychologistSpecialtyLabel},
  {name: 'nuclearMedicineSpeciality', label: 'Nuclear Medicine', value: 'Nuclear Medicine'},
  {name: 'obstetricsAndGynecologySpeciality', label: 'Obstetrics and Gynecology (OB/GYN)', value: 'Obstetrics and Gynecology (OB/GYN)'},
  {name: 'ophthalmologySpeciality', label: 'Ophthalmology', value: 'Ophthalmology'},
  {name: 'optometristSpeciality', label: 'Optometrist', value: 'Optometrist'},
  {name: 'primaryCareProviderSpeciality', label: 'Primary Care Provider', value: 'Primary Care Provider'},
  {name: 'pathologySpeciality', label: 'Pathology', value: 'Pathology'},
  {name: 'pediatricsSpeciality', label: 'Pediatrics', value: 'Pediatrics'},
  {name: 'physicalMedicineAndRehabilitationSpeciality', label: 'Physical Medicine and Rehabilitation', value: 'Physical Medicine and Rehabilitation'},
  {name: 'preventativeMedicineSpeciality', label: 'Preventive Medicine', value: 'Preventive Medicine'},
  {name: 'psychiatrySpeciality', label: 'Psychiatry', value: 'Psychiatry'},
  {name: 'psychiatrySpeciality', label: psyhcologistSpecialtyLabel, value: psyhcologistSpecialtyLabel},
  {name: 'radiationOncologySpeciality', label: 'Radiation Oncology', value: 'Radiation Oncology'},
  {name: 'surgerySpeciality', label: 'Surgery', value: 'Surgery'},
  {name: 'schoolPsychologistSpeciality', label: schoolPsyhcologistSpecialtyLabel, value: schoolPsyhcologistSpecialtyLabel},
  {name: 'urologySpeciality', label: 'Urology', value: 'Urology'},
];

export const modalityFormInput = {
    id: nanoid(),
    element: 'checkboxGroup',
    name: 'modalityPreferences',
    label: 'Select Preferred Modality',
    items: modalities.map(modality => {
        return {id: nanoid(), element: 'input', type: 'checkbox', name: modality.name, label: modality.label, value: modality.value};
    })
}

export const externalMandatoryInputs = [
    {id: nanoid(), element: 'selectTags', type: 'text', name: professionalSpecialtiesName,
        placeholder: 'Specialties / Provider Type (e.g. \'Internal Medicine\')', mode: 'multiple',
        tags: professionalSpecialties.map(speciality => ({id: nanoid(), text: speciality.label })),
        required: true,
    },
    {id: nanoid(), element: 'selectTags', type: 'text', name: 'responsiveAreas', placeholder: 'Cuturally Responsive Area(s)',
        tags: culturalResponsiveness.map(culturalResponsiveness => {
            return {id: nanoid(), text: culturalResponsiveness.label};
        })
    },
    {id: nanoid(), element: 'selectTags', type: 'text', name: 'languagePreferences', placeholder: 'Preferred Language(s)',
        tags: languages.map(language => {
            return {id: nanoid(), text: language.label};
        })
    },
    {id: nanoid(), element: 'selectTags', type: 'text', name: 'alliedCommunities', placeholder: 'Groups That You Have Insight / Experience With',
        tags: alliedCommunities.map(community => {
            return {id: nanoid(),text: community.label};
        })
    },
    {id: nanoid(), element: 'selectTags', type: 'text', name: 'treatmentOrientations', placeholder: 'Special Treatment Orientation',
        tags: treatmentOrientation.map(treatment => {
            return {id: nanoid(),text: treatment.label};
        })
    },
    {id: nanoid(), element: 'selectTags', type: 'text', name: 'faithOrientations', placeholder: 'Faiths You Offer Special Insights For',
        tags: faithOrientation.map(faith => {
            return {id: nanoid(),text: faith.label};
        })
    },
    {id: nanoid(), element: 'checkboxGroup', name: 'agePreferences', label: 'Age Group Specialties', items: ageGroups.map(ageGroup => {
            return {id: nanoid(), element: 'input', type: 'checkbox', name: ageGroup.name, label: ageGroup.label, value: ageGroup.value};
        })
    },
];

const paymentMethods = [
    {name: 'achBankTransfer', label: 'ACH Bank Transfer', value: 'ACH Bank Transfer'},
    {name: 'americanExpress', label: 'American Express', value: 'American Express'},
    {name: 'cash', label: 'Cash', value: 'Cash'},
    {name: 'check', label: 'Check', value: 'Check'},
    {name: 'discover', label: 'Discover', value: 'Discover'},
    {name: 'healthSavingsAccount', label: 'Health Savings Account', value: 'Health Savings Account'},
    {name: 'mastercard', label: 'Mastercard', value: 'Mastercard'},
    {name: 'paypal', label: 'Paypal', value: 'Paypal'},
    {name: 'square', label: 'Square', value: 'Square'},
    {name: 'venmo', label: 'Venmo', value: 'Venmo'},
    {name: 'visa', label: 'Visa', value: 'Visa'},
    {name: 'wire', label: 'Wire', value: 'Wire'},
]

const acceptedInsurances = [
    {name: 'ACI Specialty Benefits',                        label: 'ACI Specialty Benefits',                    value: 'ACI Specialty Benefits'},
    {name: 'Aetna',                                         label: 'Aetna',                                     value: 'Aetna'},
    {name: 'Allegiance',                                    label: 'Allegiance',                                value: 'Allegiance'},
    {name: 'Alliance',                                      label: 'Alliance',                                  value: 'Alliance'},
    {name: 'American Behavioral',                           label: 'American Behavioral',                       value: 'American Behavioral'},
    {name: 'AMERIGROUP',                                    label: 'AMERIGROUP',                                value: 'AMERIGROUP'},
    {name: 'AmeriHealth',                                   label: 'AmeriHealth',                               value: 'AmeriHealth'},
    {name: 'Any/Out-of-network Insurance',                  label: 'Any/Out-of-network Insurance',              value: 'Any/Out-of-network Insurance'},
    {name: 'APS Healthcare',                                label: 'APS Healthcare',                            value: 'APS Healthcare'},
    {name: 'Behavioral Health Systems',                     label: 'Behavioral Health Systems',                 value: 'Behavioral Health Systems'},
    {name: 'Blue Care Network',                             label: 'Blue Care Network',                         value: 'Blue Care Network'},
    {name: 'Blue Cross',                                    label: 'Blue Cross',                                value: 'Blue Cross'},
    {name: 'Blue Shield',                                   label: 'Blue Shield',                               value: 'Blue Shield'},
    {name: 'BlueCross and BlueShield',                      label: 'BlueCross and BlueShield',                  value: 'BlueCross and BlueShield'},
    {name: 'CareConnect Health Plan',                       label: 'CareConnect Health Plan',                   value: 'CareConnect Health Plan'},
    {name: 'CareFirst',                                     label: 'CareFirst',                                 value: 'CareFirst'},
    {name: 'Cascade Health',                                label: 'Cascade Health',                            value: 'Cascade Health'},
    {name: 'Ceridian',                                      label: 'Ceridian',                                  value: 'Ceridian'},
    {name: 'CHIP | Children\'s Program',                    label: 'CHIP | Children\'s Program',                value: 'CHIP | Children\'s Program'},
    {name: 'Cigna',                                         label: 'Cigna',                                     value: 'Cigna'},
    {name: 'ComPsych',                                      label: 'ComPsych',                                  value: 'ComPsych'},
    {name: 'E4Health',                                      label: 'E4Health',                                  value: 'E4Health'},
    {name: 'Empire BlueCross BlueShield',                   label: 'Empire BlueCross BlueShield',               value: 'Empire BlueCross BlueShield'},
    {name: 'First Choice Health',                           label: 'First Choice Health',                       value: 'First Choice Health'},
    {name: 'First Health',                                  label: 'First Health',                              value: 'First Health'},
    {name: 'Great-West Life',                               label: 'Great-West Life',                           value: 'Great-West Life'},
    {name: 'Green Shield Canada',                           label: 'Green Shield Canada',                       value: 'Green Shield Canada'},
    {name: 'Guardian',                                      label: 'Guardian',                                  value: 'Guardian'},
    {name: 'HAP | Health Alliance Plan of Michigan',        label: 'HAP | Health Alliance Plan of Michigan',    value: 'HAP | Health Alliance Plan of Michigan'},
    {name: 'Health Choice',                                 label: 'Health Choice',                             value: 'Health Choice'},
    {name: 'Health Net',                                    label: 'Health Net',                                value: 'Health Net'},
    {name: 'HealthFirst',                                   label: 'HealthFirst',                               value: 'HealthFirst'},
    {name: 'HealthLink',                                    label: 'HealthLink',                                value: 'HealthLink'},
    {name: 'HealthPartners',                                label: 'HealthPartners',                            value: 'HealthPartners'},
    {name: 'Holman Group',                                  label: 'Holman Group',                              value: 'Holman Group'},
    {name: 'Homewood Health',                               label: 'Homewood Health',                           value: 'Homewood Health'},
    {name: 'Humana',                                        label: 'Humana',                                    value: 'Humana'},
    {name: 'Insurance Corporation of British Columbia',     label: 'Insurance Corporation of British Columbia', value: 'Insurance Corporation of British Columbia'},
    {name: 'Johns Hopkins',                                 label: 'Johns Hopkins',                             value: 'Johns Hopkins'},
    {name: 'Kaiser',                                        label: 'Kaiser',                                    value: 'Kaiser'},
    {name: 'LifeWise',                                      label: 'LifeWise',                                  value: 'LifeWise'},
    {name: 'MagnaCare',                                     label: 'MagnaCare',                                 value: 'MagnaCare'},
    {name: 'Manulife',                                      label: 'Manulife',                                  value: 'Manulife'},
    {name: 'Medavie Blue Cross',                            label: 'Medavie Blue Cross',                        value: 'Medavie Blue Cross'},
    {name: 'MedCost',                                       label: 'MedCost',                                   value: 'MedCost'},
    {name: 'Medica',                                        label: 'Medica',                                    value: 'Medica'},
    {name: 'Medicaid',                                      label: 'Medicaid',                                  value: 'Medicaid'},
    {name: 'Medical Mutual',                                label: 'Medical Mutual',                            value: 'Medical Mutual'},
    {name: 'Medicare',                                      label: 'Medicare',                                  value: 'Medicare'},
    {name: 'Meridian',                                      label: 'Meridian',                                  value: 'Meridian'},
    {name: 'MHN',                                           label: 'MHN',                                       value: 'MHN'},
    {name: 'MHNet Behavioral Health',                       label: 'MHNet Behavioral Health',                   value: 'MHNet Behavioral Health'},
    {name: 'Military OneSource',                            label: 'Military OneSource',                        value: 'Military OneSource'},
    {name: 'Morneau Shepell',                               label: 'Morneau Shepell',                           value: 'Morneau Shepell'},
    {name: 'MultiPlan',                                     label: 'MultiPlan',                                 value: 'MultiPlan'},
    {name: 'Network Health',                                label: 'Network Health',                            value: 'Network Health'},
    {name: 'New Directions',                                label: 'New Directions',                            value: 'New Directions'},
    {name: 'OHIP',                                          label: 'OHIP',                                      value: 'OHIP'},
    {name: 'Optima',                                        label: 'Optima',                                    value: 'Optima'},
    {name: 'Optum',                                         label: 'Optum',                                     value: 'Optum'},
    {name: 'Oxford',                                        label: 'Oxford',                                    value: 'Oxford'},
    {name: 'Pacicic Blue Cross',                            label: 'Pacicic Blue Cross',                        value: 'Pacicic Blue Cross'},
    {name: 'PHCS',                                          label: 'PHCS',                                      value: 'PHCS'},
    {name: 'Premera',                                       label: 'Premera',                                   value: 'Premera'},
    {name: 'Providence',                                    label: 'Providence',                                value: 'Providence'},
    {name: 'PsychCare',                                     label: 'PsychCare',                                 value: 'PsychCare'},
    {name: 'Sierra Health',                                 label: 'Sierra Health',                             value: 'Sierra Health'},
    {name: 'SunLife',                                       label: 'SunLife',                                   value: 'SunLife'},
    {name: 'TRICARE',                                       label: 'TRICARE',                                   value: 'TRICARE'},
    {name: 'TriWest',                                       label: 'TriWest',                                   value: 'TriWest'},
    {name: 'UniCare',                                       label: 'UniCare',                                   value: 'UniCare'},
    {name: 'Uniform',                                       label: 'Uniform',                                   value: 'Uniform'},
    {name: 'United Healthcare',                             label: 'United Healthcare',                         value: 'United Healthcare'},
    {name: 'No Insurance',                                  label: 'No Insurance',                              value: 'No Insurance'},
]


export const externalAdditionalInputs = [
    {id: nanoid(), element: 'inputRow', items: [
        {id: nanoid(), element: 'input', type: 'text', name: 'email', placeholder: 'Contact Email'},
        {id: nanoid(), element: 'input', type: 'text', name: 'phoneNumber', placeholder: 'Contact Number'},
    ]},
    {id: nanoid(), element: 'inputRow', items: [
        {id: nanoid(), element: 'input', type: 'text', name: 'education', placeholder: 'School Attended'},
        {id: nanoid(), element: 'input', type: 'text', name: 'degree', placeholder: 'Degree Obtained'},
        {id: nanoid(), element: 'input', type: 'text', name: 'yearsPracticed', placeholder: 'Years In Practice'}
    ]},
    {id: nanoid(), element: 'selectTags', type: 'text', name: 'acceptedInsurances', placeholder: 'Select Accepted Insurance(s)',
        tags: acceptedInsurances.map(insurance => {
            return {id: nanoid(), text: insurance.label};
        })
    },
    {id: nanoid(), element: 'input', type: 'number', name: 'averageFee', placeholder: 'Fees (average cost per session)', prefix: '$'},
    {id: nanoid(), element: 'switch', type: 'switch', name: 'isFeeFlexible', label: 'Is Fee Flexible?'},
    {id: nanoid(), element: 'input', type: 'text', name: 'npi', placeholder: 'National Provider Identifier (NPI)'},
    {id: nanoid(), element: 'inputRow', items: [
        {id: nanoid(), element: 'input', type: 'text', name: 'licenseNumber', placeholder: 'Your License Number', required: true},
        {id: nanoid(), element: 'datePicker', type: 'datePicker', name: 'licenseExpirationDate', placeholder: 'License Expiration Date', required: true},
    ]},
    {id: nanoid(), element: 'inputRow', items: [
        {id: nanoid(), element: 'input', type: 'text', name: 'supervisorLicenseNumber', placeholder: 'Supervisor License Number (if applicable)'},
        {id: nanoid(), element: 'datePicker', type: 'datePicker', name: 'supervisorLicenseExpirationDate', placeholder: 'Supervisor License Expiration Date'},
    ]},
    {id: nanoid(), element: 'checkboxGroup', name: 'paymentMethods', label: 'Select Accepted Payment Methods', items: paymentMethods.map(method => {
            return {id: nanoid(), element: 'input', type: 'checkbox', name: method.name, label: method.label, value: method.value};
        })
    }
];
