/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createScreenerQuestion = /* GraphQL */ `
  mutation CreateScreenerQuestion(
    $input: CreateScreenerQuestionInput!
    $condition: ModelScreenerQuestionConditionInput
  ) {
    createScreenerQuestion(input: $input, condition: $condition) {
      id
      element
      label
      items
      index
      isHidden
      createdAt
      updatedAt
    }
  }
`;
export const updateScreenerQuestion = /* GraphQL */ `
  mutation UpdateScreenerQuestion(
    $input: UpdateScreenerQuestionInput!
    $condition: ModelScreenerQuestionConditionInput
  ) {
    updateScreenerQuestion(input: $input, condition: $condition) {
      id
      element
      label
      items
      index
      isHidden
      createdAt
      updatedAt
    }
  }
`;
export const deleteScreenerQuestion = /* GraphQL */ `
  mutation DeleteScreenerQuestion(
    $input: DeleteScreenerQuestionInput!
    $condition: ModelScreenerQuestionConditionInput
  ) {
    deleteScreenerQuestion(input: $input, condition: $condition) {
      id
      element
      label
      items
      index
      isHidden
      createdAt
      updatedAt
    }
  }
`;
export const createGroup = /* GraphQL */ `
  mutation CreateGroup(
    $input: CreateGroupInput!
    $condition: ModelGroupConditionInput
  ) {
    createGroup(input: $input, condition: $condition) {
      id
      messages {
        items {
          id
          text
          groupID
          authorID
          createdAt
          updatedAt
        }
        nextToken
      }
      creator {
        id
        awsCognitoUsername
        isAccountConfirmed
        isTestAccount
        userTypeID
        userType {
          id
          name
          createdAt
          updatedAt
        }
        email
        providerProfileID
        providerProfile {
          id
          userID
          title
          firstName
          lastName
          postNominals
          birthDate
          email
          phoneNumber
          image
          identityId
          location
          pronouns
          responsiveAreas
          description
          languagePreferences
          website
          education
          degree
          acceptedInsurances
          isScreenerCompleted
          screenerAnswers
          specialties
          yearsPracticed
          averageFee
          isFeeFlexible
          faithOrientations
          alliedCommunities
          treatmentOrientations
          agePreferences
          paymentMethods
          modalityPreferences
          npi
          licenseNumber
          supervisorLicenseNumber
          licenseExpirationDate
          supervisorLicenseExpirationDate
          createdAt
          updatedAt
        }
        groups {
          nextToken
        }
        title
        firstName
        lastName
        postNominals
        image
        identityId
        createdAt
        updatedAt
      }
      creatorID
      members {
        items {
          id
          groupID
          memberUserID
          unreadCount
          createdAt
          updatedAt
        }
        nextToken
      }
      lastMessageID
      lastMessage {
        id
        text
        groupID
        authorID
        author {
          id
          awsCognitoUsername
          isAccountConfirmed
          isTestAccount
          userTypeID
          email
          providerProfileID
          title
          firstName
          lastName
          postNominals
          image
          identityId
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateGroup = /* GraphQL */ `
  mutation UpdateGroup(
    $input: UpdateGroupInput!
    $condition: ModelGroupConditionInput
  ) {
    updateGroup(input: $input, condition: $condition) {
      id
      messages {
        items {
          id
          text
          groupID
          authorID
          createdAt
          updatedAt
        }
        nextToken
      }
      creator {
        id
        awsCognitoUsername
        isAccountConfirmed
        isTestAccount
        userTypeID
        userType {
          id
          name
          createdAt
          updatedAt
        }
        email
        providerProfileID
        providerProfile {
          id
          userID
          title
          firstName
          lastName
          postNominals
          birthDate
          email
          phoneNumber
          image
          identityId
          location
          pronouns
          responsiveAreas
          description
          languagePreferences
          website
          education
          degree
          acceptedInsurances
          isScreenerCompleted
          screenerAnswers
          specialties
          yearsPracticed
          averageFee
          isFeeFlexible
          faithOrientations
          alliedCommunities
          treatmentOrientations
          agePreferences
          paymentMethods
          modalityPreferences
          npi
          licenseNumber
          supervisorLicenseNumber
          licenseExpirationDate
          supervisorLicenseExpirationDate
          createdAt
          updatedAt
        }
        groups {
          nextToken
        }
        title
        firstName
        lastName
        postNominals
        image
        identityId
        createdAt
        updatedAt
      }
      creatorID
      members {
        items {
          id
          groupID
          memberUserID
          unreadCount
          createdAt
          updatedAt
        }
        nextToken
      }
      lastMessageID
      lastMessage {
        id
        text
        groupID
        authorID
        author {
          id
          awsCognitoUsername
          isAccountConfirmed
          isTestAccount
          userTypeID
          email
          providerProfileID
          title
          firstName
          lastName
          postNominals
          image
          identityId
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteGroup = /* GraphQL */ `
  mutation DeleteGroup(
    $input: DeleteGroupInput!
    $condition: ModelGroupConditionInput
  ) {
    deleteGroup(input: $input, condition: $condition) {
      id
      messages {
        items {
          id
          text
          groupID
          authorID
          createdAt
          updatedAt
        }
        nextToken
      }
      creator {
        id
        awsCognitoUsername
        isAccountConfirmed
        isTestAccount
        userTypeID
        userType {
          id
          name
          createdAt
          updatedAt
        }
        email
        providerProfileID
        providerProfile {
          id
          userID
          title
          firstName
          lastName
          postNominals
          birthDate
          email
          phoneNumber
          image
          identityId
          location
          pronouns
          responsiveAreas
          description
          languagePreferences
          website
          education
          degree
          acceptedInsurances
          isScreenerCompleted
          screenerAnswers
          specialties
          yearsPracticed
          averageFee
          isFeeFlexible
          faithOrientations
          alliedCommunities
          treatmentOrientations
          agePreferences
          paymentMethods
          modalityPreferences
          npi
          licenseNumber
          supervisorLicenseNumber
          licenseExpirationDate
          supervisorLicenseExpirationDate
          createdAt
          updatedAt
        }
        groups {
          nextToken
        }
        title
        firstName
        lastName
        postNominals
        image
        identityId
        createdAt
        updatedAt
      }
      creatorID
      members {
        items {
          id
          groupID
          memberUserID
          unreadCount
          createdAt
          updatedAt
        }
        nextToken
      }
      lastMessageID
      lastMessage {
        id
        text
        groupID
        authorID
        author {
          id
          awsCognitoUsername
          isAccountConfirmed
          isTestAccount
          userTypeID
          email
          providerProfileID
          title
          firstName
          lastName
          postNominals
          image
          identityId
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const createMessage = /* GraphQL */ `
  mutation CreateMessage(
    $input: CreateMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    createMessage(input: $input, condition: $condition) {
      id
      text
      groupID
      authorID
      author {
        id
        awsCognitoUsername
        isAccountConfirmed
        isTestAccount
        userTypeID
        userType {
          id
          name
          createdAt
          updatedAt
        }
        email
        providerProfileID
        providerProfile {
          id
          userID
          title
          firstName
          lastName
          postNominals
          birthDate
          email
          phoneNumber
          image
          identityId
          location
          pronouns
          responsiveAreas
          description
          languagePreferences
          website
          education
          degree
          acceptedInsurances
          isScreenerCompleted
          screenerAnswers
          specialties
          yearsPracticed
          averageFee
          isFeeFlexible
          faithOrientations
          alliedCommunities
          treatmentOrientations
          agePreferences
          paymentMethods
          modalityPreferences
          npi
          licenseNumber
          supervisorLicenseNumber
          licenseExpirationDate
          supervisorLicenseExpirationDate
          createdAt
          updatedAt
        }
        groups {
          nextToken
        }
        title
        firstName
        lastName
        postNominals
        image
        identityId
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateMessage = /* GraphQL */ `
  mutation UpdateMessage(
    $input: UpdateMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    updateMessage(input: $input, condition: $condition) {
      id
      text
      groupID
      authorID
      author {
        id
        awsCognitoUsername
        isAccountConfirmed
        isTestAccount
        userTypeID
        userType {
          id
          name
          createdAt
          updatedAt
        }
        email
        providerProfileID
        providerProfile {
          id
          userID
          title
          firstName
          lastName
          postNominals
          birthDate
          email
          phoneNumber
          image
          identityId
          location
          pronouns
          responsiveAreas
          description
          languagePreferences
          website
          education
          degree
          acceptedInsurances
          isScreenerCompleted
          screenerAnswers
          specialties
          yearsPracticed
          averageFee
          isFeeFlexible
          faithOrientations
          alliedCommunities
          treatmentOrientations
          agePreferences
          paymentMethods
          modalityPreferences
          npi
          licenseNumber
          supervisorLicenseNumber
          licenseExpirationDate
          supervisorLicenseExpirationDate
          createdAt
          updatedAt
        }
        groups {
          nextToken
        }
        title
        firstName
        lastName
        postNominals
        image
        identityId
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteMessage = /* GraphQL */ `
  mutation DeleteMessage(
    $input: DeleteMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    deleteMessage(input: $input, condition: $condition) {
      id
      text
      groupID
      authorID
      author {
        id
        awsCognitoUsername
        isAccountConfirmed
        isTestAccount
        userTypeID
        userType {
          id
          name
          createdAt
          updatedAt
        }
        email
        providerProfileID
        providerProfile {
          id
          userID
          title
          firstName
          lastName
          postNominals
          birthDate
          email
          phoneNumber
          image
          identityId
          location
          pronouns
          responsiveAreas
          description
          languagePreferences
          website
          education
          degree
          acceptedInsurances
          isScreenerCompleted
          screenerAnswers
          specialties
          yearsPracticed
          averageFee
          isFeeFlexible
          faithOrientations
          alliedCommunities
          treatmentOrientations
          agePreferences
          paymentMethods
          modalityPreferences
          npi
          licenseNumber
          supervisorLicenseNumber
          licenseExpirationDate
          supervisorLicenseExpirationDate
          createdAt
          updatedAt
        }
        groups {
          nextToken
        }
        title
        firstName
        lastName
        postNominals
        image
        identityId
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const createGroupMember = /* GraphQL */ `
  mutation CreateGroupMember(
    $input: CreateGroupMemberInput!
    $condition: ModelGroupMemberConditionInput
  ) {
    createGroupMember(input: $input, condition: $condition) {
      id
      groupID
      group {
        id
        messages {
          nextToken
        }
        creator {
          id
          awsCognitoUsername
          isAccountConfirmed
          isTestAccount
          userTypeID
          email
          providerProfileID
          title
          firstName
          lastName
          postNominals
          image
          identityId
          createdAt
          updatedAt
        }
        creatorID
        members {
          nextToken
        }
        lastMessageID
        lastMessage {
          id
          text
          groupID
          authorID
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      memberUserID
      member {
        id
        awsCognitoUsername
        isAccountConfirmed
        isTestAccount
        userTypeID
        userType {
          id
          name
          createdAt
          updatedAt
        }
        email
        providerProfileID
        providerProfile {
          id
          userID
          title
          firstName
          lastName
          postNominals
          birthDate
          email
          phoneNumber
          image
          identityId
          location
          pronouns
          responsiveAreas
          description
          languagePreferences
          website
          education
          degree
          acceptedInsurances
          isScreenerCompleted
          screenerAnswers
          specialties
          yearsPracticed
          averageFee
          isFeeFlexible
          faithOrientations
          alliedCommunities
          treatmentOrientations
          agePreferences
          paymentMethods
          modalityPreferences
          npi
          licenseNumber
          supervisorLicenseNumber
          licenseExpirationDate
          supervisorLicenseExpirationDate
          createdAt
          updatedAt
        }
        groups {
          nextToken
        }
        title
        firstName
        lastName
        postNominals
        image
        identityId
        createdAt
        updatedAt
      }
      unreadCount
      createdAt
      updatedAt
    }
  }
`;
export const updateGroupMember = /* GraphQL */ `
  mutation UpdateGroupMember(
    $input: UpdateGroupMemberInput!
    $condition: ModelGroupMemberConditionInput
  ) {
    updateGroupMember(input: $input, condition: $condition) {
      id
      groupID
      group {
        id
        messages {
          nextToken
        }
        creator {
          id
          awsCognitoUsername
          isAccountConfirmed
          isTestAccount
          userTypeID
          email
          providerProfileID
          title
          firstName
          lastName
          postNominals
          image
          identityId
          createdAt
          updatedAt
        }
        creatorID
        members {
          nextToken
        }
        lastMessageID
        lastMessage {
          id
          text
          groupID
          authorID
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      memberUserID
      member {
        id
        awsCognitoUsername
        isAccountConfirmed
        isTestAccount
        userTypeID
        userType {
          id
          name
          createdAt
          updatedAt
        }
        email
        providerProfileID
        providerProfile {
          id
          userID
          title
          firstName
          lastName
          postNominals
          birthDate
          email
          phoneNumber
          image
          identityId
          location
          pronouns
          responsiveAreas
          description
          languagePreferences
          website
          education
          degree
          acceptedInsurances
          isScreenerCompleted
          screenerAnswers
          specialties
          yearsPracticed
          averageFee
          isFeeFlexible
          faithOrientations
          alliedCommunities
          treatmentOrientations
          agePreferences
          paymentMethods
          modalityPreferences
          npi
          licenseNumber
          supervisorLicenseNumber
          licenseExpirationDate
          supervisorLicenseExpirationDate
          createdAt
          updatedAt
        }
        groups {
          nextToken
        }
        title
        firstName
        lastName
        postNominals
        image
        identityId
        createdAt
        updatedAt
      }
      unreadCount
      createdAt
      updatedAt
    }
  }
`;
export const deleteGroupMember = /* GraphQL */ `
  mutation DeleteGroupMember(
    $input: DeleteGroupMemberInput!
    $condition: ModelGroupMemberConditionInput
  ) {
    deleteGroupMember(input: $input, condition: $condition) {
      id
      groupID
      group {
        id
        messages {
          nextToken
        }
        creator {
          id
          awsCognitoUsername
          isAccountConfirmed
          isTestAccount
          userTypeID
          email
          providerProfileID
          title
          firstName
          lastName
          postNominals
          image
          identityId
          createdAt
          updatedAt
        }
        creatorID
        members {
          nextToken
        }
        lastMessageID
        lastMessage {
          id
          text
          groupID
          authorID
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      memberUserID
      member {
        id
        awsCognitoUsername
        isAccountConfirmed
        isTestAccount
        userTypeID
        userType {
          id
          name
          createdAt
          updatedAt
        }
        email
        providerProfileID
        providerProfile {
          id
          userID
          title
          firstName
          lastName
          postNominals
          birthDate
          email
          phoneNumber
          image
          identityId
          location
          pronouns
          responsiveAreas
          description
          languagePreferences
          website
          education
          degree
          acceptedInsurances
          isScreenerCompleted
          screenerAnswers
          specialties
          yearsPracticed
          averageFee
          isFeeFlexible
          faithOrientations
          alliedCommunities
          treatmentOrientations
          agePreferences
          paymentMethods
          modalityPreferences
          npi
          licenseNumber
          supervisorLicenseNumber
          licenseExpirationDate
          supervisorLicenseExpirationDate
          createdAt
          updatedAt
        }
        groups {
          nextToken
        }
        title
        firstName
        lastName
        postNominals
        image
        identityId
        createdAt
        updatedAt
      }
      unreadCount
      createdAt
      updatedAt
    }
  }
`;
export const createUserType = /* GraphQL */ `
  mutation CreateUserType(
    $input: CreateUserTypeInput!
    $condition: ModelUserTypeConditionInput
  ) {
    createUserType(input: $input, condition: $condition) {
      id
      name
      createdAt
      updatedAt
    }
  }
`;
export const updateUserType = /* GraphQL */ `
  mutation UpdateUserType(
    $input: UpdateUserTypeInput!
    $condition: ModelUserTypeConditionInput
  ) {
    updateUserType(input: $input, condition: $condition) {
      id
      name
      createdAt
      updatedAt
    }
  }
`;
export const deleteUserType = /* GraphQL */ `
  mutation DeleteUserType(
    $input: DeleteUserTypeInput!
    $condition: ModelUserTypeConditionInput
  ) {
    deleteUserType(input: $input, condition: $condition) {
      id
      name
      createdAt
      updatedAt
    }
  }
`;
export const createInsuranceType = /* GraphQL */ `
  mutation CreateInsuranceType(
    $input: CreateInsuranceTypeInput!
    $condition: ModelInsuranceTypeConditionInput
  ) {
    createInsuranceType(input: $input, condition: $condition) {
      id
      name
      createdAt
      updatedAt
    }
  }
`;
export const updateInsuranceType = /* GraphQL */ `
  mutation UpdateInsuranceType(
    $input: UpdateInsuranceTypeInput!
    $condition: ModelInsuranceTypeConditionInput
  ) {
    updateInsuranceType(input: $input, condition: $condition) {
      id
      name
      createdAt
      updatedAt
    }
  }
`;
export const deleteInsuranceType = /* GraphQL */ `
  mutation DeleteInsuranceType(
    $input: DeleteInsuranceTypeInput!
    $condition: ModelInsuranceTypeConditionInput
  ) {
    deleteInsuranceType(input: $input, condition: $condition) {
      id
      name
      createdAt
      updatedAt
    }
  }
`;
export const createResponsiveType = /* GraphQL */ `
  mutation CreateResponsiveType(
    $input: CreateResponsiveTypeInput!
    $condition: ModelResponsiveTypeConditionInput
  ) {
    createResponsiveType(input: $input, condition: $condition) {
      id
      name
      tagColor
      createdAt
      updatedAt
    }
  }
`;
export const updateResponsiveType = /* GraphQL */ `
  mutation UpdateResponsiveType(
    $input: UpdateResponsiveTypeInput!
    $condition: ModelResponsiveTypeConditionInput
  ) {
    updateResponsiveType(input: $input, condition: $condition) {
      id
      name
      tagColor
      createdAt
      updatedAt
    }
  }
`;
export const deleteResponsiveType = /* GraphQL */ `
  mutation DeleteResponsiveType(
    $input: DeleteResponsiveTypeInput!
    $condition: ModelResponsiveTypeConditionInput
  ) {
    deleteResponsiveType(input: $input, condition: $condition) {
      id
      name
      tagColor
      createdAt
      updatedAt
    }
  }
`;
export const createLanguageType = /* GraphQL */ `
  mutation CreateLanguageType(
    $input: CreateLanguageTypeInput!
    $condition: ModelLanguageTypeConditionInput
  ) {
    createLanguageType(input: $input, condition: $condition) {
      id
      name
      createdAt
      updatedAt
    }
  }
`;
export const updateLanguageType = /* GraphQL */ `
  mutation UpdateLanguageType(
    $input: UpdateLanguageTypeInput!
    $condition: ModelLanguageTypeConditionInput
  ) {
    updateLanguageType(input: $input, condition: $condition) {
      id
      name
      createdAt
      updatedAt
    }
  }
`;
export const deleteLanguageType = /* GraphQL */ `
  mutation DeleteLanguageType(
    $input: DeleteLanguageTypeInput!
    $condition: ModelLanguageTypeConditionInput
  ) {
    deleteLanguageType(input: $input, condition: $condition) {
      id
      name
      createdAt
      updatedAt
    }
  }
`;
export const createPronounType = /* GraphQL */ `
  mutation CreatePronounType(
    $input: CreatePronounTypeInput!
    $condition: ModelPronounTypeConditionInput
  ) {
    createPronounType(input: $input, condition: $condition) {
      id
      name
      createdAt
      updatedAt
    }
  }
`;
export const updatePronounType = /* GraphQL */ `
  mutation UpdatePronounType(
    $input: UpdatePronounTypeInput!
    $condition: ModelPronounTypeConditionInput
  ) {
    updatePronounType(input: $input, condition: $condition) {
      id
      name
      createdAt
      updatedAt
    }
  }
`;
export const deletePronounType = /* GraphQL */ `
  mutation DeletePronounType(
    $input: DeletePronounTypeInput!
    $condition: ModelPronounTypeConditionInput
  ) {
    deletePronounType(input: $input, condition: $condition) {
      id
      name
      createdAt
      updatedAt
    }
  }
`;
export const createSpecialtyType = /* GraphQL */ `
  mutation CreateSpecialtyType(
    $input: CreateSpecialtyTypeInput!
    $condition: ModelSpecialtyTypeConditionInput
  ) {
    createSpecialtyType(input: $input, condition: $condition) {
      id
      name
      createdAt
      updatedAt
    }
  }
`;
export const updateSpecialtyType = /* GraphQL */ `
  mutation UpdateSpecialtyType(
    $input: UpdateSpecialtyTypeInput!
    $condition: ModelSpecialtyTypeConditionInput
  ) {
    updateSpecialtyType(input: $input, condition: $condition) {
      id
      name
      createdAt
      updatedAt
    }
  }
`;
export const deleteSpecialtyType = /* GraphQL */ `
  mutation DeleteSpecialtyType(
    $input: DeleteSpecialtyTypeInput!
    $condition: ModelSpecialtyTypeConditionInput
  ) {
    deleteSpecialtyType(input: $input, condition: $condition) {
      id
      name
      createdAt
      updatedAt
    }
  }
`;
export const createFaithOrientationType = /* GraphQL */ `
  mutation CreateFaithOrientationType(
    $input: CreateFaithOrientationTypeInput!
    $condition: ModelFaithOrientationTypeConditionInput
  ) {
    createFaithOrientationType(input: $input, condition: $condition) {
      id
      name
      createdAt
      updatedAt
    }
  }
`;
export const updateFaithOrientationType = /* GraphQL */ `
  mutation UpdateFaithOrientationType(
    $input: UpdateFaithOrientationTypeInput!
    $condition: ModelFaithOrientationTypeConditionInput
  ) {
    updateFaithOrientationType(input: $input, condition: $condition) {
      id
      name
      createdAt
      updatedAt
    }
  }
`;
export const deleteFaithOrientationType = /* GraphQL */ `
  mutation DeleteFaithOrientationType(
    $input: DeleteFaithOrientationTypeInput!
    $condition: ModelFaithOrientationTypeConditionInput
  ) {
    deleteFaithOrientationType(input: $input, condition: $condition) {
      id
      name
      createdAt
      updatedAt
    }
  }
`;
export const createCommunityType = /* GraphQL */ `
  mutation CreateCommunityType(
    $input: CreateCommunityTypeInput!
    $condition: ModelCommunityTypeConditionInput
  ) {
    createCommunityType(input: $input, condition: $condition) {
      id
      name
      createdAt
      updatedAt
    }
  }
`;
export const updateCommunityType = /* GraphQL */ `
  mutation UpdateCommunityType(
    $input: UpdateCommunityTypeInput!
    $condition: ModelCommunityTypeConditionInput
  ) {
    updateCommunityType(input: $input, condition: $condition) {
      id
      name
      createdAt
      updatedAt
    }
  }
`;
export const deleteCommunityType = /* GraphQL */ `
  mutation DeleteCommunityType(
    $input: DeleteCommunityTypeInput!
    $condition: ModelCommunityTypeConditionInput
  ) {
    deleteCommunityType(input: $input, condition: $condition) {
      id
      name
      createdAt
      updatedAt
    }
  }
`;
export const createTreatmentType = /* GraphQL */ `
  mutation CreateTreatmentType(
    $input: CreateTreatmentTypeInput!
    $condition: ModelTreatmentTypeConditionInput
  ) {
    createTreatmentType(input: $input, condition: $condition) {
      id
      name
      createdAt
      updatedAt
    }
  }
`;
export const updateTreatmentType = /* GraphQL */ `
  mutation UpdateTreatmentType(
    $input: UpdateTreatmentTypeInput!
    $condition: ModelTreatmentTypeConditionInput
  ) {
    updateTreatmentType(input: $input, condition: $condition) {
      id
      name
      createdAt
      updatedAt
    }
  }
`;
export const deleteTreatmentType = /* GraphQL */ `
  mutation DeleteTreatmentType(
    $input: DeleteTreatmentTypeInput!
    $condition: ModelTreatmentTypeConditionInput
  ) {
    deleteTreatmentType(input: $input, condition: $condition) {
      id
      name
      createdAt
      updatedAt
    }
  }
`;
export const createAgeType = /* GraphQL */ `
  mutation CreateAgeType(
    $input: CreateAgeTypeInput!
    $condition: ModelAgeTypeConditionInput
  ) {
    createAgeType(input: $input, condition: $condition) {
      id
      name
      createdAt
      updatedAt
    }
  }
`;
export const updateAgeType = /* GraphQL */ `
  mutation UpdateAgeType(
    $input: UpdateAgeTypeInput!
    $condition: ModelAgeTypeConditionInput
  ) {
    updateAgeType(input: $input, condition: $condition) {
      id
      name
      createdAt
      updatedAt
    }
  }
`;
export const deleteAgeType = /* GraphQL */ `
  mutation DeleteAgeType(
    $input: DeleteAgeTypeInput!
    $condition: ModelAgeTypeConditionInput
  ) {
    deleteAgeType(input: $input, condition: $condition) {
      id
      name
      createdAt
      updatedAt
    }
  }
`;
export const createPaymentMethodType = /* GraphQL */ `
  mutation CreatePaymentMethodType(
    $input: CreatePaymentMethodTypeInput!
    $condition: ModelPaymentMethodTypeConditionInput
  ) {
    createPaymentMethodType(input: $input, condition: $condition) {
      id
      name
      createdAt
      updatedAt
    }
  }
`;
export const updatePaymentMethodType = /* GraphQL */ `
  mutation UpdatePaymentMethodType(
    $input: UpdatePaymentMethodTypeInput!
    $condition: ModelPaymentMethodTypeConditionInput
  ) {
    updatePaymentMethodType(input: $input, condition: $condition) {
      id
      name
      createdAt
      updatedAt
    }
  }
`;
export const deletePaymentMethodType = /* GraphQL */ `
  mutation DeletePaymentMethodType(
    $input: DeletePaymentMethodTypeInput!
    $condition: ModelPaymentMethodTypeConditionInput
  ) {
    deletePaymentMethodType(input: $input, condition: $condition) {
      id
      name
      createdAt
      updatedAt
    }
  }
`;
export const createModalityType = /* GraphQL */ `
  mutation CreateModalityType(
    $input: CreateModalityTypeInput!
    $condition: ModelModalityTypeConditionInput
  ) {
    createModalityType(input: $input, condition: $condition) {
      id
      name
      createdAt
      updatedAt
    }
  }
`;
export const updateModalityType = /* GraphQL */ `
  mutation UpdateModalityType(
    $input: UpdateModalityTypeInput!
    $condition: ModelModalityTypeConditionInput
  ) {
    updateModalityType(input: $input, condition: $condition) {
      id
      name
      createdAt
      updatedAt
    }
  }
`;
export const deleteModalityType = /* GraphQL */ `
  mutation DeleteModalityType(
    $input: DeleteModalityTypeInput!
    $condition: ModelModalityTypeConditionInput
  ) {
    deleteModalityType(input: $input, condition: $condition) {
      id
      name
      createdAt
      updatedAt
    }
  }
`;
export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      awsCognitoUsername
      isAccountConfirmed
      isTestAccount
      userTypeID
      userType {
        id
        name
        createdAt
        updatedAt
      }
      email
      providerProfileID
      providerProfile {
        id
        userID
        user {
          id
          awsCognitoUsername
          isAccountConfirmed
          isTestAccount
          userTypeID
          email
          providerProfileID
          title
          firstName
          lastName
          postNominals
          image
          identityId
          createdAt
          updatedAt
        }
        title
        firstName
        lastName
        postNominals
        birthDate
        email
        phoneNumber
        image
        identityId
        location
        pronouns
        responsiveAreas
        description
        languagePreferences
        website
        education
        degree
        acceptedInsurances
        isScreenerCompleted
        screenerAnswers
        specialties
        yearsPracticed
        averageFee
        isFeeFlexible
        faithOrientations
        alliedCommunities
        treatmentOrientations
        agePreferences
        paymentMethods
        modalityPreferences
        npi
        licenseNumber
        supervisorLicenseNumber
        licenseExpirationDate
        supervisorLicenseExpirationDate
        createdAt
        updatedAt
      }
      groups {
        items {
          id
          groupID
          memberUserID
          unreadCount
          createdAt
          updatedAt
        }
        nextToken
      }
      title
      firstName
      lastName
      postNominals
      image
      identityId
      createdAt
      updatedAt
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      awsCognitoUsername
      isAccountConfirmed
      isTestAccount
      userTypeID
      userType {
        id
        name
        createdAt
        updatedAt
      }
      email
      providerProfileID
      providerProfile {
        id
        userID
        user {
          id
          awsCognitoUsername
          isAccountConfirmed
          isTestAccount
          userTypeID
          email
          providerProfileID
          title
          firstName
          lastName
          postNominals
          image
          identityId
          createdAt
          updatedAt
        }
        title
        firstName
        lastName
        postNominals
        birthDate
        email
        phoneNumber
        image
        identityId
        location
        pronouns
        responsiveAreas
        description
        languagePreferences
        website
        education
        degree
        acceptedInsurances
        isScreenerCompleted
        screenerAnswers
        specialties
        yearsPracticed
        averageFee
        isFeeFlexible
        faithOrientations
        alliedCommunities
        treatmentOrientations
        agePreferences
        paymentMethods
        modalityPreferences
        npi
        licenseNumber
        supervisorLicenseNumber
        licenseExpirationDate
        supervisorLicenseExpirationDate
        createdAt
        updatedAt
      }
      groups {
        items {
          id
          groupID
          memberUserID
          unreadCount
          createdAt
          updatedAt
        }
        nextToken
      }
      title
      firstName
      lastName
      postNominals
      image
      identityId
      createdAt
      updatedAt
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      awsCognitoUsername
      isAccountConfirmed
      isTestAccount
      userTypeID
      userType {
        id
        name
        createdAt
        updatedAt
      }
      email
      providerProfileID
      providerProfile {
        id
        userID
        user {
          id
          awsCognitoUsername
          isAccountConfirmed
          isTestAccount
          userTypeID
          email
          providerProfileID
          title
          firstName
          lastName
          postNominals
          image
          identityId
          createdAt
          updatedAt
        }
        title
        firstName
        lastName
        postNominals
        birthDate
        email
        phoneNumber
        image
        identityId
        location
        pronouns
        responsiveAreas
        description
        languagePreferences
        website
        education
        degree
        acceptedInsurances
        isScreenerCompleted
        screenerAnswers
        specialties
        yearsPracticed
        averageFee
        isFeeFlexible
        faithOrientations
        alliedCommunities
        treatmentOrientations
        agePreferences
        paymentMethods
        modalityPreferences
        npi
        licenseNumber
        supervisorLicenseNumber
        licenseExpirationDate
        supervisorLicenseExpirationDate
        createdAt
        updatedAt
      }
      groups {
        items {
          id
          groupID
          memberUserID
          unreadCount
          createdAt
          updatedAt
        }
        nextToken
      }
      title
      firstName
      lastName
      postNominals
      image
      identityId
      createdAt
      updatedAt
    }
  }
`;
export const createProviderProfile = /* GraphQL */ `
  mutation CreateProviderProfile(
    $input: CreateProviderProfileInput!
    $condition: ModelProviderProfileConditionInput
  ) {
    createProviderProfile(input: $input, condition: $condition) {
      id
      userID
      user {
        id
        awsCognitoUsername
        isAccountConfirmed
        isTestAccount
        userTypeID
        userType {
          id
          name
          createdAt
          updatedAt
        }
        email
        providerProfileID
        providerProfile {
          id
          userID
          title
          firstName
          lastName
          postNominals
          birthDate
          email
          phoneNumber
          image
          identityId
          location
          pronouns
          responsiveAreas
          description
          languagePreferences
          website
          education
          degree
          acceptedInsurances
          isScreenerCompleted
          screenerAnswers
          specialties
          yearsPracticed
          averageFee
          isFeeFlexible
          faithOrientations
          alliedCommunities
          treatmentOrientations
          agePreferences
          paymentMethods
          modalityPreferences
          npi
          licenseNumber
          supervisorLicenseNumber
          licenseExpirationDate
          supervisorLicenseExpirationDate
          createdAt
          updatedAt
        }
        groups {
          nextToken
        }
        title
        firstName
        lastName
        postNominals
        image
        identityId
        createdAt
        updatedAt
      }
      title
      firstName
      lastName
      postNominals
      birthDate
      email
      phoneNumber
      image
      identityId
      location
      pronouns
      responsiveAreas
      description
      languagePreferences
      website
      education
      degree
      acceptedInsurances
      isScreenerCompleted
      screenerAnswers
      specialties
      yearsPracticed
      averageFee
      isFeeFlexible
      faithOrientations
      alliedCommunities
      treatmentOrientations
      agePreferences
      paymentMethods
      modalityPreferences
      npi
      licenseNumber
      supervisorLicenseNumber
      licenseExpirationDate
      supervisorLicenseExpirationDate
      createdAt
      updatedAt
    }
  }
`;
export const updateProviderProfile = /* GraphQL */ `
  mutation UpdateProviderProfile(
    $input: UpdateProviderProfileInput!
    $condition: ModelProviderProfileConditionInput
  ) {
    updateProviderProfile(input: $input, condition: $condition) {
      id
      userID
      user {
        id
        awsCognitoUsername
        isAccountConfirmed
        isTestAccount
        userTypeID
        userType {
          id
          name
          createdAt
          updatedAt
        }
        email
        providerProfileID
        providerProfile {
          id
          userID
          title
          firstName
          lastName
          postNominals
          birthDate
          email
          phoneNumber
          image
          identityId
          location
          pronouns
          responsiveAreas
          description
          languagePreferences
          website
          education
          degree
          acceptedInsurances
          isScreenerCompleted
          screenerAnswers
          specialties
          yearsPracticed
          averageFee
          isFeeFlexible
          faithOrientations
          alliedCommunities
          treatmentOrientations
          agePreferences
          paymentMethods
          modalityPreferences
          npi
          licenseNumber
          supervisorLicenseNumber
          licenseExpirationDate
          supervisorLicenseExpirationDate
          createdAt
          updatedAt
        }
        groups {
          nextToken
        }
        title
        firstName
        lastName
        postNominals
        image
        identityId
        createdAt
        updatedAt
      }
      title
      firstName
      lastName
      postNominals
      birthDate
      email
      phoneNumber
      image
      identityId
      location
      pronouns
      responsiveAreas
      description
      languagePreferences
      website
      education
      degree
      acceptedInsurances
      isScreenerCompleted
      screenerAnswers
      specialties
      yearsPracticed
      averageFee
      isFeeFlexible
      faithOrientations
      alliedCommunities
      treatmentOrientations
      agePreferences
      paymentMethods
      modalityPreferences
      npi
      licenseNumber
      supervisorLicenseNumber
      licenseExpirationDate
      supervisorLicenseExpirationDate
      createdAt
      updatedAt
    }
  }
`;
export const deleteProviderProfile = /* GraphQL */ `
  mutation DeleteProviderProfile(
    $input: DeleteProviderProfileInput!
    $condition: ModelProviderProfileConditionInput
  ) {
    deleteProviderProfile(input: $input, condition: $condition) {
      id
      userID
      user {
        id
        awsCognitoUsername
        isAccountConfirmed
        isTestAccount
        userTypeID
        userType {
          id
          name
          createdAt
          updatedAt
        }
        email
        providerProfileID
        providerProfile {
          id
          userID
          title
          firstName
          lastName
          postNominals
          birthDate
          email
          phoneNumber
          image
          identityId
          location
          pronouns
          responsiveAreas
          description
          languagePreferences
          website
          education
          degree
          acceptedInsurances
          isScreenerCompleted
          screenerAnswers
          specialties
          yearsPracticed
          averageFee
          isFeeFlexible
          faithOrientations
          alliedCommunities
          treatmentOrientations
          agePreferences
          paymentMethods
          modalityPreferences
          npi
          licenseNumber
          supervisorLicenseNumber
          licenseExpirationDate
          supervisorLicenseExpirationDate
          createdAt
          updatedAt
        }
        groups {
          nextToken
        }
        title
        firstName
        lastName
        postNominals
        image
        identityId
        createdAt
        updatedAt
      }
      title
      firstName
      lastName
      postNominals
      birthDate
      email
      phoneNumber
      image
      identityId
      location
      pronouns
      responsiveAreas
      description
      languagePreferences
      website
      education
      degree
      acceptedInsurances
      isScreenerCompleted
      screenerAnswers
      specialties
      yearsPracticed
      averageFee
      isFeeFlexible
      faithOrientations
      alliedCommunities
      treatmentOrientations
      agePreferences
      paymentMethods
      modalityPreferences
      npi
      licenseNumber
      supervisorLicenseNumber
      licenseExpirationDate
      supervisorLicenseExpirationDate
      createdAt
      updatedAt
    }
  }
`;
