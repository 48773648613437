import { useEffect, useState } from "react";
import { Row, Col, Space, Input, Button, PageHeader } from "antd";
import {
  addMessage,
  removeOnCreateMessageByGroupIdSubscriptionListener,
  listMessages,
  onCreateMessageByGroupId,
  updateGroupMemberUnreadCounts,
  clearUnreadCount,
} from "../../../services/messaging-service";
import UserAvatar from "../../Profile/UserAvatar/UserAvatar";
import UserImage from "../../Profile/UserImage";
import "./Messages.css";

const Messages = ({ userID, selectedGroup, onClickBack }) => {
  const [messages, setMessages] = useState([]);
  const [draftMessage, setDraftMessage] = useState();
  const [isSending, setIsSending] = useState(false);
  const [currentGroupMemberId] = useState(
    selectedGroup.members.items.find((member) => member.id === userID)
      ?.groupMemberID
  );

  useEffect(() => {
    async function init() {
      const messageList = await listMessages(selectedGroup.id);
      setMessages(messageList || []);
      scrollToBottom();
      clearUnreadCount(currentGroupMemberId);
      onCreateMessageByGroupId(selectedGroup.id, (messageData) => {
        setMessages((messagesState) => [...messagesState, messageData]);
        scrollToBottom();
        clearUnreadCount(currentGroupMemberId);
      });
    }
    init();
    return () => {
      removeOnCreateMessageByGroupIdSubscriptionListener();
      clearUnreadCount(currentGroupMemberId);
    };
  }, [userID, selectedGroup.id, currentGroupMemberId]);

  function scrollToBottom() {
    setTimeout(
      () =>
        window.scrollTo({
          top: document.body.scrollHeight,
          behavior: "smooth",
        }),
      1000
    );
  }

  function handleChangeEvent(event) {
    const target = event.target;
    const value = target.value;
    setDraftMessage(value);
  }

  async function handleSend() {
    try {
      setIsSending(true);
      const newMessage = {
        authorID: userID,
        text: draftMessage,
        groupID: selectedGroup.id,
      };
      await addMessage(newMessage);
      updateGroupMemberUnreadCounts(selectedGroup, currentGroupMemberId);
      setDraftMessage(null);
      setIsSending(false);
    } catch (e) {
      setIsSending(false);
      console.log(e);
    }
  }

  return (
    <div className="Messages messaging-container">
      <div className="group-messages-header">
        <PageHeader
          title={
            <div>
              {selectedGroup.members?.items.length > 0 && (
                <>
                  {selectedGroup.members.items
                    .filter((member) => userID !== member.id)
                    .map((member, memberIndex) => (
                      <span key={member.id}>
                        <span>
                          {member.firstName} {member.lastName}
                        </span>
                        {memberIndex <
                          selectedGroup.members.items.length - 2 && (
                          <span>, </span>
                        )}
                      </span>
                    ))}
                </>
              )}
            </div>
          }
          onBack={onClickBack}
        />
      </div>
      <div className="group-messages-container">
        {messages.map(({ authorID, author, text, createdAt }, index) => (
          <Row key={index}>
            <Col
              span={12}
              offset={authorID === userID ? 12 : 0}
              className="message-col"
            >
              <Row
                wrap={false}
                className={`message-container ${
                  authorID === userID ? "is-author" : "is-not-author"
                }`}
              >
                <Col flex="auto">
                  <div className="message-text">{text}</div>
                  <div>{convertTimeStampToDateComponent(createdAt)}</div>
                </Col>
                <Col
                  flex={authorID === userID ? "15%" : 0}
                  className={`message-author ${
                    authorID === userID ? "is-author" : "is-not-author"
                  }`}
                >
                  <div className="author-name">{author.firstName}</div>
                  <UserAvatar
                    imgUrl={
                      <UserImage
                        fileName={author.image}
                        identityId={author.identityId}
                      />
                    }
                    text={author.firstName ? author.firstName[0] : null}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        ))}
      </div>
      <Space direction="vertical" className="input-container">
        <Input.TextArea
          placeholder="Type a message..."
          onChange={handleChangeEvent}
          value={draftMessage}
        ></Input.TextArea>
        <Button
          type="primary"
          size="medium"
          block
          onClick={handleSend}
          disabled={!draftMessage}
          loading={isSending}
        >
          Send
        </Button>
      </Space>
    </div>
  );
};

function convertTimeStampToDateComponent(timeStamp) {
  const date = new Date(timeStamp);
  return (
    <div className="message-timestamp">
      <div className="timestamp__date">{date.toDateString()}</div>
      <div className="timestamp__time">{date.toLocaleTimeString()}</div>
    </div>
  );
}

export { Messages, convertTimeStampToDateComponent };
