/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getScreenerQuestion = /* GraphQL */ `
  query GetScreenerQuestion($id: ID!) {
    getScreenerQuestion(id: $id) {
      id
      element
      label
      items
      index
      isHidden
      createdAt
      updatedAt
    }
  }
`;
export const listScreenerQuestions = /* GraphQL */ `
  query ListScreenerQuestions(
    $filter: ModelScreenerQuestionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listScreenerQuestions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        element
        label
        items
        index
        isHidden
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getGroup = /* GraphQL */ `
  query GetGroup($id: ID!) {
    getGroup(id: $id) {
      id
      messages {
        items {
          id
          text
          groupID
          authorID
          createdAt
          updatedAt
        }
        nextToken
      }
      creator {
        id
        awsCognitoUsername
        isAccountConfirmed
        isTestAccount
        userTypeID
        userType {
          id
          name
          createdAt
          updatedAt
        }
        email
        providerProfileID
        providerProfile {
          id
          userID
          title
          firstName
          lastName
          postNominals
          birthDate
          email
          phoneNumber
          image
          identityId
          location
          pronouns
          responsiveAreas
          description
          languagePreferences
          website
          education
          degree
          acceptedInsurances
          isScreenerCompleted
          screenerAnswers
          specialties
          yearsPracticed
          averageFee
          isFeeFlexible
          faithOrientations
          alliedCommunities
          treatmentOrientations
          agePreferences
          paymentMethods
          modalityPreferences
          npi
          licenseNumber
          supervisorLicenseNumber
          licenseExpirationDate
          supervisorLicenseExpirationDate
          createdAt
          updatedAt
        }
        groups {
          nextToken
        }
        title
        firstName
        lastName
        postNominals
        image
        identityId
        createdAt
        updatedAt
      }
      creatorID
      members {
        items {
          id
          groupID
          memberUserID
          unreadCount
          createdAt
          updatedAt
        }
        nextToken
      }
      lastMessageID
      lastMessage {
        id
        text
        groupID
        authorID
        author {
          id
          awsCognitoUsername
          isAccountConfirmed
          isTestAccount
          userTypeID
          email
          providerProfileID
          title
          firstName
          lastName
          postNominals
          image
          identityId
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listGroups = /* GraphQL */ `
  query ListGroups(
    $filter: ModelGroupFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGroups(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        messages {
          nextToken
        }
        creator {
          id
          awsCognitoUsername
          isAccountConfirmed
          isTestAccount
          userTypeID
          email
          providerProfileID
          title
          firstName
          lastName
          postNominals
          image
          identityId
          createdAt
          updatedAt
        }
        creatorID
        members {
          nextToken
        }
        lastMessageID
        lastMessage {
          id
          text
          groupID
          authorID
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getMessage = /* GraphQL */ `
  query GetMessage($id: ID!) {
    getMessage(id: $id) {
      id
      text
      groupID
      authorID
      author {
        id
        awsCognitoUsername
        isAccountConfirmed
        isTestAccount
        userTypeID
        userType {
          id
          name
          createdAt
          updatedAt
        }
        email
        providerProfileID
        providerProfile {
          id
          userID
          title
          firstName
          lastName
          postNominals
          birthDate
          email
          phoneNumber
          image
          identityId
          location
          pronouns
          responsiveAreas
          description
          languagePreferences
          website
          education
          degree
          acceptedInsurances
          isScreenerCompleted
          screenerAnswers
          specialties
          yearsPracticed
          averageFee
          isFeeFlexible
          faithOrientations
          alliedCommunities
          treatmentOrientations
          agePreferences
          paymentMethods
          modalityPreferences
          npi
          licenseNumber
          supervisorLicenseNumber
          licenseExpirationDate
          supervisorLicenseExpirationDate
          createdAt
          updatedAt
        }
        groups {
          nextToken
        }
        title
        firstName
        lastName
        postNominals
        image
        identityId
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listMessages = /* GraphQL */ `
  query ListMessages(
    $filter: ModelMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMessages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        text
        groupID
        authorID
        author {
          id
          awsCognitoUsername
          isAccountConfirmed
          isTestAccount
          userTypeID
          email
          providerProfileID
          title
          firstName
          lastName
          postNominals
          image
          identityId
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getGroupMember = /* GraphQL */ `
  query GetGroupMember($id: ID!) {
    getGroupMember(id: $id) {
      id
      groupID
      group {
        id
        messages {
          nextToken
        }
        creator {
          id
          awsCognitoUsername
          isAccountConfirmed
          isTestAccount
          userTypeID
          email
          providerProfileID
          title
          firstName
          lastName
          postNominals
          image
          identityId
          createdAt
          updatedAt
        }
        creatorID
        members {
          nextToken
        }
        lastMessageID
        lastMessage {
          id
          text
          groupID
          authorID
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      memberUserID
      member {
        id
        awsCognitoUsername
        isAccountConfirmed
        isTestAccount
        userTypeID
        userType {
          id
          name
          createdAt
          updatedAt
        }
        email
        providerProfileID
        providerProfile {
          id
          userID
          title
          firstName
          lastName
          postNominals
          birthDate
          email
          phoneNumber
          image
          identityId
          location
          pronouns
          responsiveAreas
          description
          languagePreferences
          website
          education
          degree
          acceptedInsurances
          isScreenerCompleted
          screenerAnswers
          specialties
          yearsPracticed
          averageFee
          isFeeFlexible
          faithOrientations
          alliedCommunities
          treatmentOrientations
          agePreferences
          paymentMethods
          modalityPreferences
          npi
          licenseNumber
          supervisorLicenseNumber
          licenseExpirationDate
          supervisorLicenseExpirationDate
          createdAt
          updatedAt
        }
        groups {
          nextToken
        }
        title
        firstName
        lastName
        postNominals
        image
        identityId
        createdAt
        updatedAt
      }
      unreadCount
      createdAt
      updatedAt
    }
  }
`;
export const listGroupMembers = /* GraphQL */ `
  query ListGroupMembers(
    $filter: ModelGroupMemberFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGroupMembers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        groupID
        group {
          id
          creatorID
          lastMessageID
          createdAt
          updatedAt
        }
        memberUserID
        member {
          id
          awsCognitoUsername
          isAccountConfirmed
          isTestAccount
          userTypeID
          email
          providerProfileID
          title
          firstName
          lastName
          postNominals
          image
          identityId
          createdAt
          updatedAt
        }
        unreadCount
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getUserType = /* GraphQL */ `
  query GetUserType($id: ID!) {
    getUserType(id: $id) {
      id
      name
      createdAt
      updatedAt
    }
  }
`;
export const listUserTypes = /* GraphQL */ `
  query ListUserTypes(
    $filter: ModelUserTypeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserTypes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getInsuranceType = /* GraphQL */ `
  query GetInsuranceType($id: ID!) {
    getInsuranceType(id: $id) {
      id
      name
      createdAt
      updatedAt
    }
  }
`;
export const listInsuranceTypes = /* GraphQL */ `
  query ListInsuranceTypes(
    $filter: ModelInsuranceTypeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listInsuranceTypes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getResponsiveType = /* GraphQL */ `
  query GetResponsiveType($id: ID!) {
    getResponsiveType(id: $id) {
      id
      name
      tagColor
      createdAt
      updatedAt
    }
  }
`;
export const listResponsiveTypes = /* GraphQL */ `
  query ListResponsiveTypes(
    $filter: ModelResponsiveTypeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listResponsiveTypes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        tagColor
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getLanguageType = /* GraphQL */ `
  query GetLanguageType($id: ID!) {
    getLanguageType(id: $id) {
      id
      name
      createdAt
      updatedAt
    }
  }
`;
export const listLanguageTypes = /* GraphQL */ `
  query ListLanguageTypes(
    $filter: ModelLanguageTypeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLanguageTypes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getPronounType = /* GraphQL */ `
  query GetPronounType($id: ID!) {
    getPronounType(id: $id) {
      id
      name
      createdAt
      updatedAt
    }
  }
`;
export const listPronounTypes = /* GraphQL */ `
  query ListPronounTypes(
    $filter: ModelPronounTypeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPronounTypes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getSpecialtyType = /* GraphQL */ `
  query GetSpecialtyType($id: ID!) {
    getSpecialtyType(id: $id) {
      id
      name
      createdAt
      updatedAt
    }
  }
`;
export const listSpecialtyTypes = /* GraphQL */ `
  query ListSpecialtyTypes(
    $filter: ModelSpecialtyTypeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSpecialtyTypes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getFaithOrientationType = /* GraphQL */ `
  query GetFaithOrientationType($id: ID!) {
    getFaithOrientationType(id: $id) {
      id
      name
      createdAt
      updatedAt
    }
  }
`;
export const listFaithOrientationTypes = /* GraphQL */ `
  query ListFaithOrientationTypes(
    $filter: ModelFaithOrientationTypeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFaithOrientationTypes(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCommunityType = /* GraphQL */ `
  query GetCommunityType($id: ID!) {
    getCommunityType(id: $id) {
      id
      name
      createdAt
      updatedAt
    }
  }
`;
export const listCommunityTypes = /* GraphQL */ `
  query ListCommunityTypes(
    $filter: ModelCommunityTypeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCommunityTypes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getTreatmentType = /* GraphQL */ `
  query GetTreatmentType($id: ID!) {
    getTreatmentType(id: $id) {
      id
      name
      createdAt
      updatedAt
    }
  }
`;
export const listTreatmentTypes = /* GraphQL */ `
  query ListTreatmentTypes(
    $filter: ModelTreatmentTypeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTreatmentTypes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getAgeType = /* GraphQL */ `
  query GetAgeType($id: ID!) {
    getAgeType(id: $id) {
      id
      name
      createdAt
      updatedAt
    }
  }
`;
export const listAgeTypes = /* GraphQL */ `
  query ListAgeTypes(
    $filter: ModelAgeTypeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAgeTypes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getPaymentMethodType = /* GraphQL */ `
  query GetPaymentMethodType($id: ID!) {
    getPaymentMethodType(id: $id) {
      id
      name
      createdAt
      updatedAt
    }
  }
`;
export const listPaymentMethodTypes = /* GraphQL */ `
  query ListPaymentMethodTypes(
    $filter: ModelPaymentMethodTypeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPaymentMethodTypes(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getModalityType = /* GraphQL */ `
  query GetModalityType($id: ID!) {
    getModalityType(id: $id) {
      id
      name
      createdAt
      updatedAt
    }
  }
`;
export const listModalityTypes = /* GraphQL */ `
  query ListModalityTypes(
    $filter: ModelModalityTypeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listModalityTypes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      awsCognitoUsername
      isAccountConfirmed
      isTestAccount
      userTypeID
      userType {
        id
        name
        createdAt
        updatedAt
      }
      email
      providerProfileID
      providerProfile {
        id
        userID
        user {
          id
          awsCognitoUsername
          isAccountConfirmed
          isTestAccount
          userTypeID
          email
          providerProfileID
          title
          firstName
          lastName
          postNominals
          image
          identityId
          createdAt
          updatedAt
        }
        title
        firstName
        lastName
        postNominals
        birthDate
        email
        phoneNumber
        image
        identityId
        location
        pronouns
        responsiveAreas
        description
        languagePreferences
        website
        education
        degree
        acceptedInsurances
        isScreenerCompleted
        screenerAnswers
        specialties
        yearsPracticed
        averageFee
        isFeeFlexible
        faithOrientations
        alliedCommunities
        treatmentOrientations
        agePreferences
        paymentMethods
        modalityPreferences
        npi
        licenseNumber
        supervisorLicenseNumber
        licenseExpirationDate
        supervisorLicenseExpirationDate
        createdAt
        updatedAt
      }
      groups {
        items {
          id
          groupID
          memberUserID
          unreadCount
          createdAt
          updatedAt
        }
        nextToken
      }
      title
      firstName
      lastName
      postNominals
      image
      identityId
      createdAt
      updatedAt
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        awsCognitoUsername
        isAccountConfirmed
        isTestAccount
        userTypeID
        userType {
          id
          name
          createdAt
          updatedAt
        }
        email
        providerProfileID
        providerProfile {
          id
          userID
          title
          firstName
          lastName
          postNominals
          birthDate
          email
          phoneNumber
          image
          identityId
          location
          pronouns
          responsiveAreas
          description
          languagePreferences
          website
          education
          degree
          acceptedInsurances
          isScreenerCompleted
          screenerAnswers
          specialties
          yearsPracticed
          averageFee
          isFeeFlexible
          faithOrientations
          alliedCommunities
          treatmentOrientations
          agePreferences
          paymentMethods
          modalityPreferences
          npi
          licenseNumber
          supervisorLicenseNumber
          licenseExpirationDate
          supervisorLicenseExpirationDate
          createdAt
          updatedAt
        }
        groups {
          nextToken
        }
        title
        firstName
        lastName
        postNominals
        image
        identityId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getProviderProfile = /* GraphQL */ `
  query GetProviderProfile($id: ID!) {
    getProviderProfile(id: $id) {
      id
      userID
      user {
        id
        awsCognitoUsername
        isAccountConfirmed
        isTestAccount
        userTypeID
        userType {
          id
          name
          createdAt
          updatedAt
        }
        email
        providerProfileID
        providerProfile {
          id
          userID
          title
          firstName
          lastName
          postNominals
          birthDate
          email
          phoneNumber
          image
          identityId
          location
          pronouns
          responsiveAreas
          description
          languagePreferences
          website
          education
          degree
          acceptedInsurances
          isScreenerCompleted
          screenerAnswers
          specialties
          yearsPracticed
          averageFee
          isFeeFlexible
          faithOrientations
          alliedCommunities
          treatmentOrientations
          agePreferences
          paymentMethods
          modalityPreferences
          npi
          licenseNumber
          supervisorLicenseNumber
          licenseExpirationDate
          supervisorLicenseExpirationDate
          createdAt
          updatedAt
        }
        groups {
          nextToken
        }
        title
        firstName
        lastName
        postNominals
        image
        identityId
        createdAt
        updatedAt
      }
      title
      firstName
      lastName
      postNominals
      birthDate
      email
      phoneNumber
      image
      identityId
      location
      pronouns
      responsiveAreas
      description
      languagePreferences
      website
      education
      degree
      acceptedInsurances
      isScreenerCompleted
      screenerAnswers
      specialties
      yearsPracticed
      averageFee
      isFeeFlexible
      faithOrientations
      alliedCommunities
      treatmentOrientations
      agePreferences
      paymentMethods
      modalityPreferences
      npi
      licenseNumber
      supervisorLicenseNumber
      licenseExpirationDate
      supervisorLicenseExpirationDate
      createdAt
      updatedAt
    }
  }
`;
export const listProviderProfiles = /* GraphQL */ `
  query ListProviderProfiles(
    $filter: ModelProviderProfileFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProviderProfiles(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        user {
          id
          awsCognitoUsername
          isAccountConfirmed
          isTestAccount
          userTypeID
          email
          providerProfileID
          title
          firstName
          lastName
          postNominals
          image
          identityId
          createdAt
          updatedAt
        }
        title
        firstName
        lastName
        postNominals
        birthDate
        email
        phoneNumber
        image
        identityId
        location
        pronouns
        responsiveAreas
        description
        languagePreferences
        website
        education
        degree
        acceptedInsurances
        isScreenerCompleted
        screenerAnswers
        specialties
        yearsPracticed
        averageFee
        isFeeFlexible
        faithOrientations
        alliedCommunities
        treatmentOrientations
        agePreferences
        paymentMethods
        modalityPreferences
        npi
        licenseNumber
        supervisorLicenseNumber
        licenseExpirationDate
        supervisorLicenseExpirationDate
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const messagesByGroupID = /* GraphQL */ `
  query MessagesByGroupID(
    $groupID: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    messagesByGroupID(
      groupID: $groupID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        text
        groupID
        authorID
        author {
          id
          awsCognitoUsername
          isAccountConfirmed
          isTestAccount
          userTypeID
          email
          providerProfileID
          title
          firstName
          lastName
          postNominals
          image
          identityId
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const userTypeByName = /* GraphQL */ `
  query UserTypeByName(
    $name: String!
    $id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserTypeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userTypeByName(
      name: $name
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const userByAWSCognitoUsername = /* GraphQL */ `
  query UserByAWSCognitoUsername(
    $awsCognitoUsername: String!
    $id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userByAWSCognitoUsername(
      awsCognitoUsername: $awsCognitoUsername
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        awsCognitoUsername
        isAccountConfirmed
        isTestAccount
        userTypeID
        userType {
          id
          name
          createdAt
          updatedAt
        }
        email
        providerProfileID
        providerProfile {
          id
          userID
          title
          firstName
          lastName
          postNominals
          birthDate
          email
          phoneNumber
          image
          identityId
          location
          pronouns
          responsiveAreas
          description
          languagePreferences
          website
          education
          degree
          acceptedInsurances
          isScreenerCompleted
          screenerAnswers
          specialties
          yearsPracticed
          averageFee
          isFeeFlexible
          faithOrientations
          alliedCommunities
          treatmentOrientations
          agePreferences
          paymentMethods
          modalityPreferences
          npi
          licenseNumber
          supervisorLicenseNumber
          licenseExpirationDate
          supervisorLicenseExpirationDate
          createdAt
          updatedAt
        }
        groups {
          nextToken
        }
        title
        firstName
        lastName
        postNominals
        image
        identityId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const userByEmail = /* GraphQL */ `
  query UserByEmail(
    $email: AWSEmail!
    $id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userByEmail(
      email: $email
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        awsCognitoUsername
        isAccountConfirmed
        isTestAccount
        userTypeID
        userType {
          id
          name
          createdAt
          updatedAt
        }
        email
        providerProfileID
        providerProfile {
          id
          userID
          title
          firstName
          lastName
          postNominals
          birthDate
          email
          phoneNumber
          image
          identityId
          location
          pronouns
          responsiveAreas
          description
          languagePreferences
          website
          education
          degree
          acceptedInsurances
          isScreenerCompleted
          screenerAnswers
          specialties
          yearsPracticed
          averageFee
          isFeeFlexible
          faithOrientations
          alliedCommunities
          treatmentOrientations
          agePreferences
          paymentMethods
          modalityPreferences
          npi
          licenseNumber
          supervisorLicenseNumber
          licenseExpirationDate
          supervisorLicenseExpirationDate
          createdAt
          updatedAt
        }
        groups {
          nextToken
        }
        title
        firstName
        lastName
        postNominals
        image
        identityId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const providerProfileByUserID = /* GraphQL */ `
  query ProviderProfileByUserID(
    $userID: ID!
    $id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelProviderProfileFilterInput
    $limit: Int
    $nextToken: String
  ) {
    providerProfileByUserID(
      userID: $userID
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        user {
          id
          awsCognitoUsername
          isAccountConfirmed
          isTestAccount
          userTypeID
          email
          providerProfileID
          title
          firstName
          lastName
          postNominals
          image
          identityId
          createdAt
          updatedAt
        }
        title
        firstName
        lastName
        postNominals
        birthDate
        email
        phoneNumber
        image
        identityId
        location
        pronouns
        responsiveAreas
        description
        languagePreferences
        website
        education
        degree
        acceptedInsurances
        isScreenerCompleted
        screenerAnswers
        specialties
        yearsPracticed
        averageFee
        isFeeFlexible
        faithOrientations
        alliedCommunities
        treatmentOrientations
        agePreferences
        paymentMethods
        modalityPreferences
        npi
        licenseNumber
        supervisorLicenseNumber
        licenseExpirationDate
        supervisorLicenseExpirationDate
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
