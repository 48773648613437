/* External Libraries */
import { useState, useEffect, useContext } from "react";
import { useHistory, useParams } from "react-router-dom";
import {
  message,
  Button,
  Card,
  Steps,
  Drawer,
  Space,
  notification,
  Typography,
  message as antdMessage,
} from "antd";
import {
  DoubleRightOutlined,
  LoadingOutlined,
  InfoCircleFilled,
} from "@ant-design/icons";
import { Storage } from "aws-amplify";
import { isEmpty } from "lodash";

/* Internal */
import "./Profile.css";
import { UserContext } from "../../contexts/UserContext";
import {
  internalInputs,
  externalMandatoryInputs,
  externalAdditionalInputs,
  professionalSpecialtiesName,
  mentalHealthSpecialityLabel,
  schoolPsyhcologistSpecialtyLabel,
  modalityFormInput,
  psyhcologistSpecialtyLabel,
  neuropsychologistSpecialtyLabel,
  urlProfileDataKeys,
} from "./profile.data";
import FormElements from "../FormElements/FormElements";
import Preview from "./Preview";
import { updateProfileData } from "../../services/user-service";
import { getScreenerData } from "../../services/inputs-service";

const mobileBreakpointWidth = 768;

const initialProfileSteps = [
  { title: "Describe Yourself", description: "", formItems: internalInputs },
  {
    title: "Your Specialties",
    description: "",
    formItems: externalMandatoryInputs,
    isSpecialitiesForm: true,
  },
  {
    title: "Business Information",
    description: "",
    formItems: externalAdditionalInputs,
  },
  {
    title: "Cultural Responsiveness",
    description: "",
    formItems: null,
    isScreener: true,
  },
];

const specialitiesIndex = initialProfileSteps.findIndex(
  (profileStep) => profileStep.isSpecialitiesForm
);

const profileImageS3Path = "profile/";

/**
TO-DOS
- Move options from profile.data.js to database
- + Add a way to show inputs conditionally based off of other inputs (i.e. modality)
- + Add a way to make conditional input at any index, not just as last input
*/

function Profile() {
  const { profile, setProfile } = useContext(UserContext);
  const history = useHistory();
  const { paramId = "0" } = useParams();
  const [inputValues, setInputValues] = useState({});
  const [drawer, setDrawer] = useState({ isVisible: false, data: {} });
  const [inputStatus, setInputStatus] = useState({});
  const [currentStep, setCurrentStep] = useState(Number.parseInt(paramId));
  const [profileSteps, setProfileSteps] = useState(initialProfileSteps);

  useEffect(() => {
    async function initProfile() {
      setInputValues((currentInputValues) => ({
        ...currentInputValues,
        ...profile,
      }));
    }
    initProfile();
  }, [profile]);

  useEffect(() => {
    //Shows/Hides Modality Input if user selects "Therapy / Counseling" as specialty
    setProfileSteps((profileStepsState) => {
      const newProfileStepsState = [...profileStepsState];
      if (
        currentStep === specialitiesIndex &&
        inputValues[professionalSpecialtiesName]?.length > 0
      ) {
        const mentalHealthSpecialtyList = inputValues[
          professionalSpecialtiesName
        ].filter(
          (specialty) =>
            specialty.includes(mentalHealthSpecialityLabel) ||
            specialty.includes(schoolPsyhcologistSpecialtyLabel) ||
            specialty.includes(psyhcologistSpecialtyLabel) ||
            specialty.includes(neuropsychologistSpecialtyLabel)
        );
        if (mentalHealthSpecialtyList.length > 0) {
          newProfileStepsState[specialitiesIndex].formItems = [
            ...externalMandatoryInputs,
            modalityFormInput,
          ];
        } else {
          newProfileStepsState[specialitiesIndex].formItems = [
            ...externalMandatoryInputs,
          ];
        }
        return newProfileStepsState;
      }
      return newProfileStepsState;
    });
  }, [currentStep, inputValues]);

  function handleChangeEvent(event) {
    const target = event.target;
    let value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    setInputValues((inputValuesState) => {
      if (
        urlProfileDataKeys.includes(name) &&
        value &&
        !value.includes("http")
      ) {
        value = `https://${value}`;
      }
      return {
        ...inputValuesState,
        [name]: value,
      };
    });
  }

  async function handleChangeData({ name, value }) {
    //To-do: for datepicker, update antd to use dayjs instead of momentjs, then check if date
    setInputValues({
      ...inputValues,
      [name]: value,
    });
  }

  async function handleFileUpload(event, name, accept = "") {
    let file;
    // Supports uploading via dragging file into dragger area
    if (event?.dataTransfer?.files?.length) {
      file = event.dataTransfer.files[0];
    }
    //Supports uploading via clicking file upload area
    else if (event.target.files) {
      file = event.target.files[0];
    }

    if (file && accept.includes(file.type)) {
      try {
        setInputStatus({
          ...inputStatus,
          [name]: { type: "progress" },
        });
        const fileName = `${profileImageS3Path}${file.name.replaceAll(
          " ",
          ""
        )}`;
        const result = await Storage.put(fileName, file, {
          level: "protected",
          contentType: file.type,
          resumable: true,
          progressCallback: async (progress) => {
            console.log((progress.loaded / progress.total) * 100);
            if (progress.loaded / progress.total === 1) {
              const downloadURL = await Storage.get(fileName, {
                level: "protected",
              });
              const parts = downloadURL
                .substring(0, downloadURL.indexOf(`/${profileImageS3Path}`))
                .split("protected/");
              console.log(downloadURL);
              const newData = {
                [name]: fileName,
                identityId: decodeURIComponent(parts.pop()),
              };
              setInputValues({
                ...inputValues,
                ...newData,
              });
              setInputStatus({
                ...inputStatus,
                [name]: { type: "done", message: "success" },
              });
              handleSave({ newData });
            }
          },
          errorCallback: (error) => {
            setInputStatus({
              ...inputStatus,
              [name]: { type: "error", message: error },
            });
            message.error(`${error}`, 10);
          },
        });
        console.log("Storage", result);
      } catch (error) {
        console.log("Error uploading file: ", error);
      }
    } else {
      const errorMessage = file
        ? ` Uploaded file: ${file.name} did not matched file type criteria of ${accept}`
        : `No file found that matched file type criteria of ${accept}`;
      setInputStatus({
        ...inputStatus,
        [name]: { type: "error", message: errorMessage },
      });
      message.error(errorMessage, 10);
    }
  }

  async function handleFileDelete(event, name) {
    try {
      Storage.remove(inputValues[name], { level: "protected" });
      const newData = { [name]: null };
      setInputValues({
        ...inputValues,
        [name]: null,
      });
      setInputStatus({
        ...inputStatus,
        [name]: { type: "info", message: "Image was deleted." },
      });
      handleSave({ newData });
    } catch (e) {
      console.log("Error deleting file: ", e);
    }
  }

  async function handleSave(saveData = {}) {
    const { newData = {}, callback = () => {} } = saveData;
    try {
      const updatedUser = {
        ...inputValues,
        ...newData,
        id: profile.id,
      };
      setInputValues({ ...inputValues, ...newData });
      const emptyRequiredFields = getEmptyRequiredFields(updatedUser);
      if (emptyRequiredFields.length === 0) {
        antdMessage.destroy();
        const updatedUserResponse = await updateProfileData(updatedUser);
        setProfile(updatedUserResponse);
        notification.success({
          message: `Data Saved Successfully`,
          placement: "bottomLeft",
        });
        callback();
      } else {
        window.scrollTo(0, 0);
        antdMessage.error(
          `The following fields are empty but are required: ${emptyRequiredFields
            .map((field) => field.placeholder)
            .join(", ")}`,
          10
        );
      }
    } catch (e) {
      console.log("did not save data successfully", e);
    }
  }

  function getEmptyRequiredFields(updatedUser) {
    let requiredFields = [];
    profileSteps[currentStep].formItems?.forEach((formItem) => {
      if (formItem.items) {
        const requiredItems = formItem.items.filter((item) => {
          setInputStatus((inputStatusState) => {
            return {
              ...inputStatusState,
              [item.name]: {
                type:
                  item.required && isEmpty(updatedUser[item.name])
                    ? "error"
                    : null,
              },
            };
          });
          return item.required;
        });
        requiredFields = [...requiredFields, ...requiredItems];
      } else if (formItem.required) {
        setInputStatus((inputStatusState) => {
          return {
            ...inputStatusState,
            [formItem.name]: {
              type:
                formItem.required && isEmpty(updatedUser[formItem.name])
                  ? "error"
                  : null,
            },
          };
        });
        requiredFields.push(formItem);
      }
    });

    return requiredFields.filter((requiredField) =>
      isEmpty(updatedUser[requiredField.name])
    );
  }

  function handleChangeStep(chosenStep = currentStep) {
    const nextStep = Number.parseInt(chosenStep);
    setCurrentStep((currentStepState) => (nextStep ? nextStep : chosenStep));
    if (initialProfileSteps[nextStep]) {
      history.push(`/profile/${nextStep}`);
    } else {
      handleSubmit();
    }
  }

  async function handlePreview(e) {
    e.preventDefault();
    setDrawer({ ...drawer, data: { ...profile }, isVisible: true });
  }

  function handleSubmit(e = {}) {
    e.preventDefault();
    history.push(`/search`);
  }

  const StepsSections = (props) => (
    <div className="steps-container">
      <Steps
        size="small"
        current={currentStep}
        onChange={(clickedStep) => {
          handleSave({
            callback: () => {
              handleChangeStep(clickedStep);
            },
          });
        }}
        responsive={true}
        {...props}
      >
        {profileSteps.map((profileStep) => (
          <Steps.Step
            key={profileStep.title}
            title={profileStep.title}
            description={profileStep.description}
          />
        ))}
      </Steps>
    </div>
  );

  const FooterSection = (props) => (
    <Footer
      currentStep={currentStep}
      profileSteps={profileSteps}
      handleChangeStep={handleChangeStep}
      handlePreview={handlePreview}
      handleSubmit={handleSubmit}
      handleSave={handleSave}
      {...props}
    />
  );

  return (
    <div className="Profile">
      <Drawer
        title="Profile Preview"
        className="profile-preview-drawer"
        placement="right"
        closable="true"
        visible={drawer.isVisible}
        onClose={() => {
          setDrawer({ ...drawer, isVisible: false });
        }}
        width={
          global.window.innerWidth > mobileBreakpointWidth ? "50%" : "100%"
        }
      >
        <Preview {...drawer.data} />
      </Drawer>

      <div className={`Profile-${paramId}`}>
        {profileSteps[currentStep].isScreener ? (
          <ProfileScreenerSection
            StepsSections={StepsSections}
            profileSteps={profileSteps}
            profile={inputValues}
            currentStep={currentStep}
            inputValues={inputValues}
            handleSave={handleSave}
            handleChangeStep={handleChangeStep}
            FooterSection={FooterSection}
          />
        ) : (
          <ProfileSection
            StepsSections={StepsSections}
            profileSteps={profileSteps}
            currentStep={currentStep}
            inputValues={inputValues}
            inputStatus={inputStatus}
            handleChangeEvent={handleChangeEvent}
            handleChangeData={handleChangeData}
            handleFileUpload={handleFileUpload}
            handleFileDelete={handleFileDelete}
            FooterSection={FooterSection}
          />
        )}
      </div>
    </div>
  );
}

const ProfileSection = ({
  StepsSections,
  profileSteps,
  currentStep,
  inputValues,
  inputStatus,
  handleChangeEvent,
  handleChangeData,
  handleFileUpload,
  handleFileDelete,
  FooterSection,
}) => {
  return (
    <>
      <StepsSections />
      <Card className="form-container">
        <h1>{profileSteps[currentStep].title}</h1>
        {profileSteps[currentStep].formItems ? (
          <FormElements
            formItems={profileSteps[currentStep].formItems}
            handleChangeEvent={handleChangeEvent}
            handleChangeData={handleChangeData}
            handleFileUpload={handleFileUpload}
            handleFileDelete={handleFileDelete}
            inputValues={inputValues}
            inputStatus={inputStatus}
          />
        ) : (
          <div className="loading-indicator">
            <LoadingOutlined spin />
            <div className="loading-text">Loading...</div>
          </div>
        )}
        <FooterSection />
      </Card>
    </>
  );
};

const ProfileScreenerSection = ({
  StepsSections,
  profileSteps,
  profile,
  currentStep,
  handleSave,
  handleChangeStep,
  FooterSection,
}) => {
  const [screenerInputValues, setScreenerInputValues] = useState({});
  const [screenerQuestions, setScreenerQuestions] = useState([]);

  useEffect(() => {
    async function initScreener() {
      setScreenerInputValues(() =>
        profile?.screenerAnswers ? JSON.parse(profile.screenerAnswers) : {}
      );
      const screenerData = await getScreenerData();
      setScreenerQuestions(screenerData);
    }
    initScreener();
  }, [profile]);

  function handleChangeEvent(event) {
    const target = event.target;
    let value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    setScreenerInputValues((screenInputValuesState) => ({
      ...screenInputValuesState,
      [name]: value,
    }));
  }

  function handleScreenerSave() {
    const newData = {
      screenerAnswers: JSON.stringify(screenerInputValues),
      isScreenerCompleted: getIsScreenerCompleted(
        screenerQuestions,
        screenerInputValues
      ),
    };
    handleSave({ newData });
  }

  function getIsScreenerCompleted(questions, answers) {
    const questionIds = questions.map(({ id }) => id);
    return questionIds.every((questionId) => answers[questionId]);
  }

  return (
    <>
      <StepsSections
        onChange={(clickedStep) => {
          handleScreenerSave();
          handleChangeStep(clickedStep);
        }}
      />
      <Card className="form-container">
        <h1 className="screener-title">{profileSteps[currentStep].title}</h1>
        <ScreenerInstructions />
        {screenerQuestions.length > 0 ? (
          <FormElements
            formItems={screenerQuestions}
            handleChangeEvent={handleChangeEvent}
            inputValues={screenerInputValues}
            isShowingFormItemIndex={true}
          />
        ) : (
          <div className="loading-indicator">
            <LoadingOutlined spin />
            <div className="loading-text">Loading...</div>
          </div>
        )}
        <FooterSection handleSave={handleScreenerSave} />
      </Card>
    </>
  );
};

const Footer = ({
  currentStep,
  profileSteps,
  handleSave,
  handleChangeStep,
  handlePreview,
  handleSubmit,
}) => {
  return (
    <>
      {currentStep < profileSteps.length - 1 ? (
        <div className="footer-container">
          <Space>
            <Button
              className="save-button"
              name="saveButton"
              onClick={() => handleSave()}
            >
              Save
            </Button>
            <Button
              className="next-button"
              type="primary"
              name="nextButton"
              onClick={() => {
                handleSave({
                  callback: () => handleChangeStep(currentStep + 1),
                });
              }}
            >
              Save & Continue <DoubleRightOutlined />
            </Button>
          </Space>
        </div>
      ) : (
        <div className="footer-container">
          <Button
            className="preview-button"
            onClick={(e) => {
              handleSave({ callback: () => handlePreview() });
            }}
          >
            Save & Preview Profile
          </Button>
          <Button
            type="primary"
            className="search-button"
            onClick={(e) => {
              handleSave({ callback: () => handleSubmit(e) });
            }}
          >
            Submit & View Directory
          </Button>
        </div>
      )}
    </>
  );
};

const ScreenerInstructions = () => {
  return (
    <div className="instructions">
      <Typography.Paragraph>
        <blockquote>
          <p>
            {" "}
            <InfoCircleFilled /> Culturally responsive care significantly
            benefits healthcare organizations, providers, and patients alike.{" "}
          </p>
          <p>
            It results in more patient participation and engagement, fostering
            respect and improved understanding.
          </p>
        </blockquote>
      </Typography.Paragraph>
      <p className="initial-question">
        How often do you feel this way when you work with ethnic minority and
        LGBTQIA+ clients?
      </p>
      <p>
        Every statement should be answered by selecting one number ranging from
        1 (never) to 2 (seldom), 3 (occasionally), 4 (frequently), and 5
        (always).
      </p>
    </div>
  );
};

export default Profile;
