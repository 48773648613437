import { Avatar } from "antd";
import { UserOutlined } from "@ant-design/icons";
import "./UserAvatar.css";

export default function UserAvatar({ imgUrl, text }) {
  if (imgUrl) {
    return <Avatar src={imgUrl} />;
  } else if (text) {
    return (
      <Avatar
        style={{
          backgroundColor: "#55A9A2",
        }}
      >
        {text}
      </Avatar>
    );
  }
  return (
    <Avatar
      style={{
        backgroundColor: "#55A9A2",
      }}
      icon={<UserOutlined />}
    />
  );
}
